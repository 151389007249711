import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RegisteredAddress = ({
	company_registered_address_line_1= "",
	company_registered_premises= "",
	company_registered_locality= "",
	company_registered_country= "",
	registered_address_postcode= "",
	registeredAddressSameAsTrading= false,

	setCompanyRegisteredAddressLine1 = () => {},
	setCompanyRegisteredPremises = () => {},
	setCompanyRegisteredLocality = () => {},
	setCompanyRegisteredCountry = () => {},
	setRegisteredAddressPostcode = () => {},
}): JSX.Element => (
	<Box
		component={"div"}
	>
		<FormControl
			className={"signup-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setCompanyRegisteredAddressLine1(event.target.value)
				}
				label={"Address line 1*"}
				variant={"outlined"}
				autoFocus
				name={"company_registered_address_line_1"}
				id={"company_registered_address_line_1"}
				value={company_registered_address_line_1 ?? ""}
				disabled={registeredAddressSameAsTrading}
			/>
		</FormControl>
		<FormControl
			className={"signup-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setCompanyRegisteredPremises(event.target.value)
				}
				label={"Address line 2"}
				variant={"outlined"}
				autoFocus
				name={"company_registered_premises"}
				id={"company_registered_premises"}
				value={company_registered_premises ?? ""}
				disabled={registeredAddressSameAsTrading}
			/>
		</FormControl>
		<FormControl
			className={"signup-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setCompanyRegisteredLocality(event.target.value)
				}
				label={"City*"}
				variant={"outlined"}
				autoFocus
				name={"company_registered_locality"}
				id={"company_registered_locality"}
				value={company_registered_locality ?? ""}
				disabled={registeredAddressSameAsTrading}
			/>
		</FormControl>

		<FormControl
			className={"signup-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setCompanyRegisteredCountry(event.target.value)
				}
				label={"Country*"}
				variant={"outlined"}
				autoFocus
				name={"company_registered_country"}
				id={"company_registered_country"}
				value={company_registered_country ?? ""}
				disabled={registeredAddressSameAsTrading}
			/>
		</FormControl>
		<FormControl
			className={"login-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setRegisteredAddressPostcode(event.target.value)
				}
				label={"Registered address postcode*"}
				variant={"outlined"}
				name={"registered_address_postcode"}
				id={"registered_address_postcode"}
				value={registered_address_postcode ?? ""}
				disabled={registeredAddressSameAsTrading}
			/>
		</FormControl>
	</Box>
);

export default RegisteredAddress;