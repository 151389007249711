import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdateAddress = (data:any[] = false): Promise<AxiosResponse<any>> =>
	Request.put(`/advisors/update-address`, {
		advisors: {
			address_1: data?.address_1,
			address_2: data?.address_2,
			address_3: data?.address_3,
			address_4: data?.address_4,
			postal_code: data?.postal_code,
			country: data?.country
		}
	});

export default UpdateAddress;