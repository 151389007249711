import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import {ModalContents} from "../../utils/ModalContents";
import Wrapper from "../../layout/Logged/Wrapper";
import React, {useEffect} from "react";
import Grid from "@mui/material/Grid";
import {connect} from "react-redux";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {getKeyStats} from "../../actions/IFA/IFA";
import {me} from "../../actions/Profile";
import {
	Bar,
	BarChart,
	LineChart,
	CartesianGrid,
	Legend,
	Line,
	Tooltip,
	XAxis,
	YAxis,
	ResponsiveContainer,
	LabelList,
	Text
} from 'recharts';

/**
 * @param profile_data
 * @param history
 * @param getKeyStats
 * @param stats
 * @param me
 * @returns {JSX.Element}
 * @constructor
 */
const IfaKeyStats = (
	{
		profile_data = [],
		history = {},
		getKeyStats = () => {
		},
		stats = [],
		me = () => {
		},
	}
): JSX.Element => {
	useEffect(() => {
		me();
		if (profile_data.id !== undefined) {
			getKeyStats({
				ifa_advisor: {
					advisor_id: profile_data.id,
				}
			});
		}
	}, [getKeyStats, profile_data.id, me])

	const CustomizedAxisTick = ({
		                            x = false,
		                            y = false,
		                            payload = {}
	                            }) => {
		return (<Text x={x} y={y} width={200} textAnchor={"middle"} verticalAnchor={"start"}>{payload.value}</Text>)
	};

	stats[3]?.forEach(fund => {
		if (fund.name.length > 45) {
			fund.name = `CSTDF ${fund.start_date}-${fund.end_date}`
		} else {
			fund.name = `CTDF ${fund.start_date}-${fund.end_date}`
		}
	})

	const renderGraphRevenue = () => {
		return (
			<ResponsiveContainer width={'100%'} height={300}>
				<LineChart data={stats[1] ?? []}>
					<CartesianGrid strokeDasharray={"3 3"}/>
					<XAxis dataKey={"month"}/>
					<YAxis/>
					<Tooltip/>
					<Legend/>
					<Line fill={"#3c69e4"} dataKey={"revenue"} name={'Revenue evolution per month'}/>
				</LineChart>
			</ResponsiveContainer>
		)
	};

	const renderGraphAge = () => {
		return (
			<ResponsiveContainer width={'100%'} height={300}>
				<BarChart data={stats[2] ?? []}>
					<CartesianGrid vertical={false} horizontal={false}/>
					<XAxis dataKey={"ageRange"}/>
					<YAxis type={"number"} domain={[0, dataMax => (dataMax * 2)]} allowDecimals={false}/>
					<Tooltip/>
					<Legend/>
					<Bar fill={"#3c69e4"} dataKey={"value"} name={'Number of individuals per age'}>
						<LabelList dataKey={"value"} position={"top"}/>
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		)
	};

	const renderFundDistribution = () => {
		return (
			<ResponsiveContainer width={'100%'} height={300}>
				<BarChart data={stats[3] ?? []}>
					<CartesianGrid vertical={false} horizontal={false}/>
					<XAxis dataKey={"name"} interval={0} tick={<CustomizedAxisTick/>}/>
					<YAxis type={"number"} domain={[0, dataMax => (dataMax * 2)]} allowDecimals={false}/>
					<Tooltip/>
					<Legend/>
					<Bar fill={"#3c69e4"} dataKey={"count"} name={'Number of individuals per each fund'}
					     maxBarSize={60}>
						<LabelList dataKey={"count"} position={"top"}/>
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		)
	};

	const renderGraphClients = () => {
		return (
			<ResponsiveContainer width={'100%'} height={300}>
				<BarChart data={stats[0] ?? []}>
					<CartesianGrid vertical={false} horizontal={false}/>
					<XAxis dataKey={"month"}/>
					<YAxis type={"number"} domain={[0, dataMax => (dataMax * 2)]}/>
					<Tooltip/>
					<Legend/>
					<Bar fill={"#3c69e4"} dataKey={"totalclients"} name={'Total Clients per month'} maxBarSize={60}>
						<LabelList dataKey={"totalclients"} position={"top"}/>
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		)
	};

	//Future use, when date function is implemented
	// let date = {
	// 	from: moment(fromDate).format("YYYY-MM-DD"),
	// 	to: moment(toDate).format("YYYY-MM-DD"),
	// };
	// const [toDate, setToDate] = useState(new Date());
	//
	// const [fromDate, setFromDate] = useState(new Date("2021-06-01"));

	//This function will be used soon, but not at the first moment
	// const renderBasicDatePicker = () => {
	// 	return (
	// 		<Grid
	// 			container
	// 			direction={'row'}
	// 			marginBottom={2}
	// 		>
	// 			<Grid
	// 				item
	// 			>
	// 				<LocalizationProvider
	// 					dateAdapter={AdapterDateFns}
	// 				>
	// 					<DatePicker
	// 						label="From"
	// 						value={fromDate}
	// 						dateFormat="DD MM YYYY"
	// 						autoOk={true}
	// 						keyboardDate={""}
	// 						disableFuture={true}
	// 						closeOnSelect={true}
	// 						onChange={(newValue) => {
	// 							setFromDate(new Date(newValue));
	// 						}}
	// 						renderInput={(params) => <TextField {...params} />}
	// 					/>
	// 				</LocalizationProvider>
	// 			</Grid>
	// 			<Grid
	// 				item
	// 				marginLeft={2}
	// 			>
	// 				<LocalizationProvider
	// 					dateAdapter={AdapterDateFns}
	// 				>
	// 					<DatePicker
	// 						label="To"
	// 						value={toDate}
	// 						keyboardDate={""}
	// 						dateFormat="DD MM YYYY"
	// 						autoOk={true}
	// 						disableFuture={true}
	// 						closeOnSelect={true}
	// 						onChange={(newValue) => {
	// 							setToDate(new Date(newValue));
	// 						}}
	// 						renderInput={(params) => <TextField {...params} />}
	// 					/>
	// 				</LocalizationProvider>
	// 			</Grid>
	// 			<Grid
	// 				item
	// 				marginLeft={2}
	// 				alignContent={'center'}
	// 			>
	// 				<Button
	// 					onClick={() => getKeyStats({
	// 						ifa_advisor: {
	// 							date,
	// 							advisor_id: profile_data.id
	// 						}
	// 					})}
	// 				>
	// 					Search
	// 				</Button>
	// 			</Grid>
	// 		</Grid>
	//
	// 	);
	// }

	return (
		<Wrapper
			history={history}
			profile_data={profile_data}
			isIFA={true}
		>
			<PrimaryHeader
				backIcon
				helpIcon
				helpModal={ModalContents.home_help}
			>
				IFA Dashboard - Key Stats
			</PrimaryHeader>
			<div>
				<div className={"main-page-container-wrapper"}>
					<Grid
						xs={12}
						md={12}
						lg={12}
						spacing={2}
						direction={"row"}
						marginTop={2}
						container
						item
					>
						<Grid
							lg={3}
							md={3}
							item
						>
							<Card className={"card-background"}>
								<CardContent>
									<Grid
										direction={"column"}
										alignItems={"center"}
										container
										minHeight={'90px'}
									>
										<Typography variant={"h4"} component={"h2"} className={"general-information"}>
											AUM
										</Typography>
										<Typography variant={"h5"} component={"h2"} className={"color-general"}>
											{"£ " + parseFloat(stats?.total_aum)?.toLocaleString("en") ?? 0}
										</Typography>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid
							lg={3}
							md={3}
							item
						>
							<Card className={"card-background"}>
								<CardContent>
									<Grid
										direction={"column"}
										alignItems={"center"}
										container
										minHeight={'90px'}
									>
										<Typography variant={"h4"} component={"h2"} className={"general-information"}>
											Revenue
										</Typography>
										<Typography variant={"h5"} component={"h2"} className={"color-general"}>
											{"£ " + stats?.total_revenue?.toLocaleString("en")}
										</Typography>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid
							lg={3}
							md={3}
							item
						>
							<Card className={"card-background"}>
								<CardContent>
									<Grid
										direction={"column"}
										alignItems={"center"}
										container
										minHeight={'90px'}
									>
										<Typography variant={"h4"} component={"h2"} className={"general-information"}>
											Total Individuals
										</Typography>
										<Typography variant={"h5"} component={"h2"} className={"color-general"}>
											{stats?.total_indiv?.toLocaleString("en")}
										</Typography>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
						<Grid
							lg={3}
							md={3}
							item
						>
							<Card className={"card-background"}>
								<CardContent>
									<Grid
										direction={"column"}
										alignItems={"center"}
										container
										minHeight={'90px'}
									>
										<Typography variant={"h4"} component={"h2"} className={"general-information"}>
											Total Employers
										</Typography>
										<Typography variant={"h5"} component={"h2"} className={"color-general"}>
											{stats?.total_companies?.toLocaleString("en")}
										</Typography>
									</Grid>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
					<Grid
						xs={12}
						md={12}
						lg={12}
						marginTop={4}
						direction={"row"}
						container
						item
					>
						<Grid
							lg={6}
							md={6}
							item
						>
							{renderGraphAge()}
						</Grid>
						<Grid
							lg={6}
							md={6}
							item
						>
							{renderFundDistribution()}
						</Grid>
						<Grid
							lg={6}
							md={6}
							item
						>
							{renderGraphClients()}
						</Grid>
						<Grid
							lg={6}
							md={6}
							item
						>
							{renderGraphRevenue()}
						</Grid>
					</Grid>
				</div>
			</div>
		</Wrapper>
	);
};

export default connect(
	(state) => ({
		...state.Profile,
		...state.IFA,
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
		getKeyStats: (data = {}) => dispatch(getKeyStats(data)),
	})
)(IfaKeyStats);