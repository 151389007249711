import Request from "../../utils/Request";
import { AxiosResponse } from "axios";

/**
 * @returns {Promise<AxiosResponse<T>>}
 * @param id
 */
const DeletePastUpload = (id: number): Promise<AxiosResponse<*>> =>
{
	return Request.delete(`/advisors/delete-past-upload/${id}`);
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DeletePastUpload;
