import React from 'react';
import PropTypes from 'prop-types';
import SectionGrid from '../layout/SectionGrid';


/**
 * @param props
 * @returns {*}
 * @constructor
 */
function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role={"tabpanel"}
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <SectionGrid {...other}>
                    {children}
                </SectionGrid>
            )}
        </div>
    );
}

/**
 * @type {{children: Requireable<ReactNodeLike>, index: Validator<NonNullable<any>>, value: Validator<NonNullable<any>>}}
 */
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default TabPanel;