// importing the default redux store creators.
import {configureStore, combineReducers, applyMiddleware} from '@reduxjs/toolkit';
import {thunk} from 'redux-thunk';

// importing the application store.
import Menu from './Layout/Menu';
import Profile from './Profile';
import SignUp from "./SignUp";
import SnackBar from "./SnackBar";
import Authorization from "./Authorization";
import Employer from "./Employer";
import Users from "./Users";
import PAPDIS from "./PAPDIS";
import Popup from "./Popup";
import OptOut from "./OptOut";
import Notifications from "./Notifications";
import Softwares from "./Softwares";
import EmployerNotificationAlert from "./EmployerNotificationAlert"
import Banks from "./Banks";
import DirectDebit from "./DirectDebit";
import ReferralStore from "./Referral";
import EmployersIFA from "./IFA/EmployersIFA";
import IndividualsIFA from "./IFA/IndividualsIFA";
import IFA from "./IFA/IFA";
import Forbidden from "./Forbbiden";
import EmailPreferenceType from "./EmailPreferenceType";

const reducers = combineReducers({
	Menu,
	Profile,
	SignUp,
	SnackBar,
	Authorization,
	Employer,
	Users,
	PAPDIS,
	Popup,
	OptOut,
	Notifications,
	Softwares,
	EmployerNotificationAlert,
	Banks,
	DirectDebit,
	ReferralStore,
	IndividualsIFA,
	EmployersIFA,
	IFA,
	Forbidden,
	EmailPreferenceType,
});

/**
 * @return {store}
 */
export default configureStore({
		reducer: reducers,
	},
	applyMiddleware(thunk),
);
