import React from "react";

import ContentGrid from "../../layout/ContentGrid";
import SectionGrid from "../../layout/SectionGrid";
import PanelHeader from "../Text/PanelHeader"

import Card from "@mui/material/Card";



/**
 * @param header
 * @param children
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AccountDataEditionPanel = ({ header, children, ...props }): X.Element => {
	return (
		<SectionGrid >
			<ContentGrid >
				<PanelHeader variant="h4">{header}</PanelHeader>
				<Card className={"my-account-change-info-cards"} elevation={6}>
					{children}
				</Card>
			</ContentGrid>
		</SectionGrid>
	);
}

export default AccountDataEditionPanel;