import AbstractServiceRequestsClass from "../../../classes/AbstractServiceRequestsClass";

import Request from "../../../request";
import {AxiosResponse} from "axios";

export default class IndividualIFAService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.IndividualIFA);
	}

	/**
	 * @param data
	 * @param queryString
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	getIfaIndividuals(data: any[] = false, queryString = {}): Promise<AxiosResponse<*>> | null {
		if (!this.request && !data) {
			return Promise?.reject("Cannot complete the request");
		}

		return this.request.GetIfaIndividuals(data, queryString);
	}

	/**
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 * @param individual_id
	 */
	viewIndividual(individual_id: any = null): Promise<never> | Promise<AxiosResponse<*>> {
		if (this.request && individual_id) {
			return this.request.ViewIndividual(individual_id ?? null);
		}

		return Promise.reject("No individual specified.");
	}

	/**
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 * @param data
	 */
	changeFunds(data: any[] = false): Promise<never> | Promise<AxiosResponse<*>> {
		if (this.request && data) {
			return this.request.ChangeFunds(data ?? null);
		}

		return Promise.reject("No individual specified.");
	}

	/**
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 * @param data
	 */
	requestAccess(data: any[] = false): Promise<never> | Promise<AxiosResponse<*>> {
		if (this.request && data) {
			return this.request.RequestAccess(data ?? null);
		}

		return Promise.reject("No individual specified.");
	}

	/**
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 */
	getFunds(): Promise<never> | Promise<AxiosResponse<*>> {
		return this.request.GetFunds();
	}

}