import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from "@mui/material/OutlinedInput";
import {useEffect} from "react";

/**
 * @param employers
 * @param checked
 * @param searchText
 * @param setSearchText
 * @param handleToggle
 * @param managedEmployers
 * @param setChecked
 * @returns {JSX.Element}
 * @constructor
 */
export default function EmployersListView(
	{
		employers = [],
		checked = [],
		searchText = "",
		setSearchText = () => false,
		handleToggle = () => false,
		managedEmployers = [],
		setChecked = () => {},
	}
): JSX.Element {
	const listItems = [];
	useEffect(() => {
		if (managedEmployers.length > 0) {
			for (let k = 0; k < managedEmployers.length; k ++) {
				setChecked(prevChecked => [...prevChecked, managedEmployers[k]?.employer_id]);
			}
		}
	}, [managedEmployers, setChecked]);

	listItems.push(
		<ListItem key={'searchBox'}>
			<OutlinedInput
				id={'searchBox'}
				name={'searchBox'}
				value={searchText}
				onChange={event => setSearchText(event.target.value)}
				fullWidth
			/>
		</ListItem>
	);

	listItems.push(employers.map((value = {
		employer_id: null,
		employer: {
			title: "",
		}
	}) => {
		const labelId = `checkbox-list-label-${value.id}`;

		return (
			<ListItem
				key={value.id}
				disablePadding
			>
				<ListItemButton
					role={undefined}
					onClick={handleToggle(value.employer_id)}
					dense
				>
					<ListItemIcon>
						<Checkbox
							edge="start"
							checked={
								checked.indexOf(value.employer_id) !== -1
								// ||
								// returnCheckedEmployer(
								// 	value?.employer_id,
								// 	managedEmployers,
								// 	checked.indexOf(value.employer_id) !== -1
								// )
							}
							tabIndex={-1}
							disableRipple
							inputProps={{ 'aria-labelledby': labelId }}
						/>
					</ListItemIcon>
					<ListItemText id={labelId} primary={`${value.employer.title}`} />
				</ListItemButton>
			</ListItem>
		);
	}));
	return (
		<List
			sx={{ width: '100%', maxWidth: "100%", bgcolor: 'background.paper' }}
		>
			{listItems}
		</List>
	);
}
