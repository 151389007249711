import Request from "../../utils/Request";
import { AxiosResponse } from "axios";


const EditUser = ( data: any[] = false): Promise<AxiosResponse<T>> => Request

	.put("/advisors/my-user/update/", {
		advisors: {
			...data,
		}
	});


export default EditUser;