import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdateAdvisor = (data:any[] = {}): Promise<AxiosResponse<any>> => {
	return Request.put(`/advisors/update-advisors`, {
		advisors: {
			id_advisors_users: data.id_advisors_users,
			advisors_id: data.advisors_id,
			title_advisor: data.title_advisor,
			business_type: data.business_type,
			contribution_date: data?.contribution_date,
		}
	});
};

export default UpdateAdvisor;