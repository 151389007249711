import React from "react";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import 'react-phone-input-2/lib/material.css';
import Autocomplete from "@mui/material/Autocomplete";
import {geocode, RequestType, setDefaults} from "react-geocode";
import {CountryRegionData} from "react-country-region-selector";
import MenuItem from "@mui/material/MenuItem";

/**
 * @private
 * @param event
 * @param addressComponents
 * @param setAddressComponents
 */
const _postCodeChange = (event, addressComponents,setAddressComponents) => {
	if (event && event.target && event.target.value) {
		setDefaults({
			key: process.env.REACT_APP_GEOCODE_API_KEY,
			language: "en",
		});
		if (geocode) {
			geocode(RequestType.ADDRESS, event.target.value)
				.then((response) => {
						const {
							status = "OK"
						} = response;
						if (status === "OK") {
							setAddressComponents(oldArray => [...oldArray, {
								address: response?.results[0]?.formatted_address,
								components: response?.results[0]?.address_components,
							}])
						}
					},
					error => console.error(error)
				)
				.catch(error => console.error(error));
		}
	}
};

/**
 * @param value
 * @param props
 * @private
 */
const _optionSelected = (value, props) => {

	const postal_code = value?.components?.map(v => v.types.filter(t => t === "postal_code").length > 0 ? v?.long_name : false).filter(v => v)[0],
		country = value?.components?.map(v => v.types.filter(t => t === "country").length > 0 ? v?.short_name : false).filter(v => v)[0];

	let address1 = value?.components?.map(v => v.types.filter(t => t === "route").length > 0 ? v?.long_name : false).filter(v => v)[0];
	let	address2 = value?.components?.map(v => v.types.filter(t => t === "postal_town").length > 0 ? v?.long_name : false).filter(v => v)[0];

	value?.components?.forEach((v, index) => {
		if (v) {
			if (v.types.filter(t => t === "country").length > 0) {
				delete value.components[index];
			}
			if (v.types.filter(t => t === "postal_town").length > 0) {
				delete value.components[index];
			}
		}
	});

	props.setFormValues({...props.formValues,
		address_1: address1 ? address1 : "",
		address_2: address2 ? address2 : "",
		postal_code: postal_code ? postal_code : "",
		country: country ? country : "",
	})

};

/**
 * @param event
 * @param props
 * @private
 */

const _onChange = (event, props) => {
	if (event) {
		if (event.target.value === "") {
			props.setFormValues({...props.formValues,
				address_1: "",
				address_2: "",
				address_3: "",
				address_4: "",
				postal_code: "",
				country: "",
			})
			props.setAddressComponents([]);
		}
		_postCodeChange(event, props.addressComponents, props.setAddressComponents);
	}
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FindAddressByApi = (props): JSX.Element => {

	if(props.country === "England") {
		props.setFormValues({...props.formValues,
			country: "United Kingdom",
		})
	}

	return (
		<Grid>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<Autocomplete
					id={"addressComponents"}
					getOptionLabel={addressComponents => addressComponents.address ?? ""}
					options={props.addressComponents}
					onChange={(event, value) => _optionSelected(value,props)}
					renderInput={(params) => (
						<TextField
							{...params}
							label={"Type your postal code"}
							margin={"normal"}
							variant={"outlined"}
							onChange={event => _onChange(event, props)}
						/>
					)}
					freeSolo
					disableClearable
				/>
			</FormControl>
			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
					}
					label={"Postal code *"}
					variant={"outlined"}
					name={"postal_code"}
					id={"postal_code"}
					value={props.postal_code}
					disabled
				/>
			</FormControl>

			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
					}
					label={"Building / Office number"}
					variant={"outlined"}
					name={"address_4"}
					id={"address_4"}
					value={props.address_4}
				/>
			</FormControl>

			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
					}
					label={"Address line 1 *"}
					variant={"outlined"}
					name={"address_1"}
					id={"address_1"}
					value={props.address_1}
				/>
			</FormControl>

			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
					}
					label={"Address line 2"}
					variant={"outlined"}
					name={"address_3"}
					id={"address_3"}
					value={props.address_3}
				/>
			</FormControl>



			<FormControl
				className={"signup-form-control"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
					}
					label={"Town / City *"}
					variant={"outlined"}
					name={"address_2"}
					id={"address_2"}
					error={!props.address_2}
					value={props.address_2}
				/>
			</FormControl>

			<FormControl
				className={"signup-form-control form-control-white"}
				fullWidth
			>
				<TextField
					type={"text"}
					onChange={(event) =>
						props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
					}
					label={"Country *"}
					variant={"outlined"}
					name={"country"}
					id={"country"}
					value={props.country}
					fullWidth
					select
					disabled
				>
					{
						CountryRegionData.map((option, index) => (
							<MenuItem key={option[1]} value={option[1]}>
								{option[0]}
							</MenuItem>
						))
					}
				</TextField>
			</FormControl>
		</Grid>
	);
}

export default FindAddressByApi;