import React from 'react';
import './styles/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {CssBaseline} from "@mui/material";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

// added css normalize for all webpages.
import SnackBarPopup from "./components/SnackBar";
import EmployerNotificationAlert from "./components/EmployerNotificationAlert";


// importing the provider.
import {Provider} from 'react-redux';

// importing the store.
import store from './store';
import {createRoot} from "react-dom/client";
// import Snackbar from "./components/SnackBar";


const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


if (process.env.REACT_APP_ENV === "production") {
	Sentry.init({
		dsn: "https://c7547d7b1d144a04b9432351b0404f2a@o1171073.ingest.sentry.io/4504565628600320",
		integrations: [
			new BrowserTracing(),
			new Sentry.Replay(),
		],
		beforeSend(event) {
			// Check if it is an exception, if so, show the report dialog
			// Note that this only will work in the renderer process, it's a noop on the main process
			if (event.exception) {
				Sentry.showReportDialog();
			}
			return event;
		},
		tracesSampleRate: 1.0,
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

root.render(
	<Provider store={store}>
		<CssBaseline/>
		<SnackBarPopup/>
		<EmployerNotificationAlert/>
		<App/>
	</Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
