import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import {onChange} from "../../../utils/Helpers";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import {Check, Close} from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import PhoneInput from "react-phone-input-2";
import React from "react";
import {isValidPhoneNumber} from "libphonenumber-js";
/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ManualDirectorsInsertion = ({
	companyExist = false,
    textFirstName = "",
	setTextFirstName = () => {},
	textSurName = "",
	setTextSurName = () => {},
	textEmail = "",
	setTextEmail = () => {},
	textConfirmEmail = "",
	setTextConfirmEmail = () => {},
	verifyEmail = () => {},
	emailVerified = false,
	telephone = "",
	setTelephone = () => {},
}): JSX.Element => (
	<Grid
		display={companyExist ? "none" : "block"}
	>
		<FormControl
			className={"signup-form-control-2 confirm-title"}
			fullWidth
		>
			<Typography
				align={"left"}
				fontSize={16}
			>
				Director / Authorised signatory details
			</Typography>
		</FormControl>
		<FormControl
			className={"login-form-control"}
			fullWidth
		>
			<TextField
				className={"mb-15"}
				type={"text"}
				value={textFirstName}
				onChange={event => onChange(setTextFirstName(event.target.value))}
				label={"First name *"}
				variant={"outlined"}
				name={"name"}
				id={"name"}
			/>

			<TextField
				className={"mb-15"}
				type={"text"}
				value={textSurName}
				onChange={event => onChange(setTextSurName(event.target.value))}
				label={"Surname *"}
				variant={"outlined"}
				name={"surname"}
				id={"surname"}
			/>
		</FormControl>
		<FormControl
			className={"login-form-control"}
			fullWidth
		>
			<TextField
				className={"mb-15"}
				type={"email"}
				value={textEmail}
				onChange={event => onChange(setTextEmail(event.target.value))}
				label={"Email *"}
				variant={"outlined"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							{
								textEmail ?
									!emailVerified ?
										<CircularProgress size={'25px'} />
										:
											emailVerified && verifyEmail.length === 0 ?
												<Check color='success'/>
											:
												<Close onClick={()=>setTextEmail('')} color="error"/>

									:
									""
							}

						</InputAdornment>
					)
				}}
				name={"email"}
				id={"email"}
			/>
			{
				<small
					className={"red-text"}
				>
					{
						textEmail ?
							verifyEmail ?
								verifyEmail?.primary_email
								:
								null
							:
							""
					}
				</small>
			}

		</FormControl>

		<FormControl
			className={"login-form-control"}
			fullWidth
		>
			<TextField
				type={"email"}
				value={textConfirmEmail}
				onChange={event => onChange(setTextConfirmEmail(event.target.value))}
				label={"Confirm email *"}
				variant={"outlined"}
				name={"confirmEmail"}
				id={"confirmEmail"}
				error={textEmail !== textConfirmEmail && textConfirmEmail !== ""}
			/>

			{
				textEmail !== textConfirmEmail && textConfirmEmail !== "" &&

				<small
					className={"red-text"}
				>
					Emails do not match
				</small>
			}
			<FormControl className={"login-form-control"} fullWidth>
				<PhoneInput
					className={"phone-input"}
					country={"gb"}
					inputStyle={{
						width: "100%",
					}}
					inputProps={{
						name: 'telephone',
						id: "telephone",
						required: true,
					}}
					searchPlaceholder={"Country name"}
					searchNotFound={"No country found, please search again."}
					specialLabel={"Mobile | Phone*"}
					enableSearch
					countryCodeEditable={false}
					value={telephone}
					onChange={e => setTelephone(e)}
				/>
			</FormControl>
			{
				telephone.length > 0 && !isValidPhoneNumber("+" + telephone) &&
				<small className={"red-text"}>
					* Invalid phone number.
				</small>
			}

		</FormControl>
	</Grid>
);

ManualDirectorsInsertion.propTypes = {
	textFirstName: PropTypes.string.isRequired,
	textSurName: PropTypes.string.isRequired,
	textEmail: PropTypes.string.isRequired,
	textConfirmEmail: PropTypes.string.isRequired,
	setTextFirstName: PropTypes.func.isRequired,
	setTextSurName: PropTypes.func.isRequired,
	setTextEmail: PropTypes.func.isRequired,
	setTextConfirmEmail: PropTypes.func.isRequired,
	companyExist: PropTypes.any,
};

export default ManualDirectorsInsertion;