import {Reducer} from 'react'
import * as SnackBarActions from "../../actions/SnackBar/types";

const initial_state: {
	severity: string,
	message: string,
	open: boolean
} = {
	severity: "info",
	message: "",
	open: false
}

const SnackBarReducer: Reducer = (state: initial_state = initial_state, action: any[] = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case SnackBarActions.HIDE_SNACK_BAR:
			return {...state, open: false}
		case SnackBarActions.TRIGGER_SNACK_BAR:
			return {
				...state,
				open: true,
				message: action.message,
				severity: action.severity ?? state.severity
			}

		default:
			return state;
	}
}

export default SnackBarReducer