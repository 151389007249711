import React, {useState} from "react";

// default material ui imports
import {Container, useTheme, Grid} from '@mui/material';
import {ThemeProvider} from "@mui/material";

// custom components import.
import Header from './Header';
import RightNavigationMenu from "../Header/RightNavigationMenu";
import Footer from "./Footer";
import NotificationMenu from "./NotificationMenu";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

/**
 * @param children
 * @param history
 * @param profile_data
 * @param showSandwich
 * @param notifications
 * @param isIFA
 * @returns {JSX.Element}
 * @constructor
 */
const Wrapper = (
	{
		children = null,
		profile_data = {
			is_ifa_advisor: false,
		},
		showSandwich = true,
		notifications = [],
		isIFA = null,
	}
): JSX.Element => {
	const theme = useTheme({
		breakpoints: {
			md: '800px',
		}
	});
	const [open, setOpen] = useState(false);
	const [notificationAnchor, setNotificationAnchor] = useState(null);
	const history = useNavigate();
	const handleDrawer = () => {
		setOpen(!open);
	};

	useEffect(() => {
		if (profile_data?.is_ifa_advisor !== undefined && !profile_data?.is_ifa_advisor && isIFA === true) {
			window.location.href = "/main-page";
		}
	}, [profile_data, profile_data?.is_ifa_advisor, isIFA]);

	return (
		<ThemeProvider theme={theme}>
			<Container
				className={"no-padding logged-background-color collegia-container-wrapper"}
				fixed
			>
				<Grid
					spacing={0}
					direction="column"
					alignItems="center"
					alignContent={"center"}
					className={"main-page-container-wrapper"}
					id={"menu-logged-wrapper"}
					container
					item
				>
					<Header
						profile_data={profile_data}
						showSandwich={showSandwich}
						handleDrawer={handleDrawer}
						notifications={notifications}
						handleNotificationsOpen={setNotificationAnchor}
						isIFA={isIFA}
					/>
				</Grid>
				{children}
				<div className={"dark-bg-container"}>
					<Grid
						spacing={0}
						direction="column"
						alignItems="center"
						alignContent={"center"}
						className={"main-page-container-wrapper"}
						container
						item
					>
						<Footer/>
					</Grid>
				</div>
				<NotificationMenu
					notificationAnchor={notificationAnchor}
					setNotificationAnchor={setNotificationAnchor}
					profile_data={profile_data}
				/>
				<RightNavigationMenu
					theme={theme}
					open={open}
					handleDrawer={handleDrawer}
					history={history}
					profile_data={profile_data}
				/>
			</Container>
		</ThemeProvider>
	);
}

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default Wrapper;