import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyAddress = (props): JSX.Element => (
	<div>
		<FormControl
			className={"signup-form-control-2 confirm-title"}
			fullWidth
		>
			<Typography
				align={"left"}
				style={{fontWeight: "bold", fontSize: 16}}
			>
				Registered Address
			</Typography>
		</FormControl>
		<FormControl
			className={"signup-form-control-2 confirm-description"}
			fullWidth
		>
			<Typography align={"left"} style={{marginBottom: 10}}>
				{" " + JSON.parse(sessionStorage.getItem("EmployerObject")).address_snippet ?
					JSON.parse(sessionStorage.getItem("EmployerObject")).address_snippet :
					JSON.parse(sessionStorage.getItem("EmployerObject")).address
				}
			</Typography>
		</FormControl>
	</div>
);

CompanyAddress.propTypes = {
	history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape({
                employerAttributes: PropTypes.shape({
                    address: PropTypes.object,
                    address_snippet: PropTypes.string
                })
            })
        })
    }).isRequired
};

export default CompanyAddress;