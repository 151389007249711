import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import {validateEmail, validStagingDate} from "../../../utils/Helpers";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {isValidPhoneNumber} from "libphonenumber-js";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ProceedAddition = (props = {
	canProceed: false,
}): JSX.Element => (
	<Grid id={"forgot-password-requested-wrapper"}>
		<FormControl
			className={"signup-form-control"}
			fullWidth
		>
			{
				props?.companyExist ?
					<Button
						variant={"contained"}
						color={"primary"}
						onClick={() => {
							props?.companyExist ?
								props?.nextSuccessPageExistCompany()
								:
								props?.nextSuccessPageNoExistCompany1()
						}}
						disabled={
							props?.companyExist ?
								props?.checkBox
								:
								(props?.checkBox ||
									!props?.textFirstName ||
									!props?.textSurName ||
									props?.textEmail !== props?.textConfirmEmail ||
									!validateEmail(props?.textEmail) ||
									!validStagingDate(props?.stagingDate ?? "")
								) || !props?.canProceed
						}
					>
						CONTINUE
					</Button>
				:
					<FormControl fullWidth>
						<Button
							variant={"contained"}
							className={"box-shadow-button"}
							onClick={() => {
								props?.companyExist ?
									props?.nextSuccessPageExistCompany()
									:
									props?.nextSuccessPageNoExistCompany()
							}}
							disabled={
								props?.companyExist ?
									props?.checkBox
									:
									(props?.checkBox ||
										!props?.textFirstName ||
										!props?.terms_accepted ||
										!props?.textSurName ||
										props?.textEmail !== props?.textConfirmEmail ||
										!validateEmail(props?.textEmail) ||
										!validStagingDate(props?.stagingDate ?? "") ||
										props?.telephone?.length === 0 ||
										!isValidPhoneNumber("+" + props?.telephone)
									) || !props?.canProceed
							}
						>
							Send instructions to employer to complete sign up
						</Button>
						<Button
							className={"box-shadow-button"}
							color={'primary'}
							variant={"outlined"}
							onClick={() => {
								props?.companyExist ?
									props?.nextSuccessPageExistCompany()
									:
									props?.nextSuccessPageNoExistCompany1()
							}}
							disabled={
								props?.companyExist ?
									props?.checkBox
									:
									(props?.checkBox ||
										!props?.textFirstName ||
										!props?.terms_accepted ||
										!props?.textSurName ||
										props?.textEmail !== props?.textConfirmEmail ||
										!validateEmail(props?.textEmail) ||
										!validStagingDate(props?.stagingDate ?? "")||
										props?.telephone?.length === 0 ||
										!isValidPhoneNumber("+" + props?.telephone)
									) || !props?.canProceed
							}
						>
							Complete sign up on behalf of the employer
						</Button>
					</FormControl>
			}
		</FormControl>
	</Grid>
);

ProceedAddition.propTypes = {
    checkBox: PropTypes.bool.isRequired,
	terms_accepted: PropTypes.bool.isRequired,
    textFirstName: PropTypes.string.isRequired,
    textSurName: PropTypes.string.isRequired,
    textEmail: PropTypes.string.isRequired,
	textConfirmEmail: PropTypes.string.isRequired,
    stagingDate: PropTypes.string.isRequired,
    nextSuccessPageExistCompany: PropTypes.func.isRequired,
    nextSuccessPageNoExistCompany: PropTypes.func.isRequired,
	companyExist: PropTypes.any,
};

export default ProceedAddition;