import React from "react";
import Grid from "@mui/material/Grid";

/**
 * @param individuals_count
 * @returns {JSX.Element}
 * @constructor
 */
export const CountIndividuals = (
	{
		individuals_count = {
			total: 0,
			active: 0,
			pending: 0,
		}
	}
): JSX.Element => (
	<Grid
		justifyContent={"space-between"}
		alignItems={"center"}
		className={"collegia-border count-employers"}
		container
	>
		<Grid
			sm={12}
			md={4}
			justifyContent={"center"}
			item
			container
		>
				<span className={"count-employers-title"}>
					Total Individuals:&nbsp;
				</span>
			<span className={"count-employers-number"}>
					{individuals_count?.total ?? 0}
				</span>
		</Grid>

		<Grid
			sm={12}
			md={4}
			justifyContent={"center"}
			item
			container
		>
				<span className={"count-employers-title"}>
					Active:&nbsp;
				</span>
			<span className={"count-employers-number"}>
					{individuals_count?.active ?? 0}
				</span>
		</Grid>

		<Grid
			sm={12}
			md={4}
			justifyContent={"center"}
			item
			container
		>
				<span className={"count-employers-title"}>
					Pending:&nbsp;
				</span>
			<span className={"count-employers-number"}>
					{individuals_count?.pending ?? 0}
				</span>
		</Grid>
	</Grid>
);
