import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

/**
 * @param selectedOption
 * @returns {string}
 * @private
 */
const _renderCompanyNumberLabel = (selectedOption = ""): string => {
	let label = "Company / Business number";
	if (selectedOption === "Limited Company" || selectedOption === "Limited Liability Partnership") {
		label += "*";
	}

	return label;
}

/**
 * @param selectedOption
 * @returns {string}
 * @private
 */
const _renderSmallInformation = (selectedOption = ""): string => {
	if (selectedOption !== "") {
		if (selectedOption === "Limited Company" || selectedOption === "Limited Liability Partnership") {
			return `For companies ${selectedOption} you need to provide a valid company number.`;
		}

		return `Collegia will assign a custom company number to employers without one.`;
	}

	return "";
}

/**
 * @param selectedOption
 * @returns {boolean}
 * @private
 */
const _isCompanyNumberDisabled = (selectedOption = ""): boolean => {
	if (selectedOption !== "Limited Company")
		if (selectedOption !== "Limited Liability Partnership")
			return true;

	return false;
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyDefaultInformation = (props = {
	handleSelectedOption: () => {
	},
	setCompanyBusinessName: () => {
	},
	setCompanyTradingCountry: () => {
	},
	setEnterCompany: () => {
	},

	selectedOption: "",
	company_business_name: "",
	legalStructure: [],
}): JSX.Element => (
	<Box
		component={"div"}
	>
		<Grid justifyContent={"flex-end"} container style={{width: "100%"}}>
			<IconButton
				aria-label={"close"}
				onClick={() => props?.setEnterCompany("none")}
			>
				<Close/>
			</IconButton>
		</Grid>
		<Grid
			justifyContent={"center"}
			direction={"column"}
			container
			style={{marginBottom: 30}}
		>
			<Typography
				variant={"subtitle1"}
				align={"center"}
				style={{fontSize: 30}}
			>
				Company details
			</Typography>
			<Typography component={"p"} variant={"caption"} align={"center"}>
				Enter your company details manually
			</Typography>
		</Grid>
		<Typography marginBottom={2} color={'error'}>All fields with * are required.</Typography>
		<FormControl className={"login-form-control"} fullWidth>
			<TextField
				style={{marginBottom: 30}}
				type={"text"}
				onChange={(event) =>
					props.setCompanyBusinessName(event.target.value)}
				label={"Company / Business name*"}
				variant={"outlined"}
				name={"company_business_name"}
				id={"company_business_name"}
				value={props.company_business_name}
			/>
		</FormControl>
		<FormControl fullWidth variant="outlined" style={{marginBottom: 30}}>
			<TextField
				onChange={event => {
					props?.handleSelectedOption(event);
					if (_renderCompanyNumberLabel(event ?? "")) {
						props?.generateCompanyNumber();
					}
				}}
				id={"legal_structure"}
				name={"legal_structure"}
				label={"Legal structure*"}
				variant={"outlined"}
				value={props?.selectedOption ?? ""}
				fullWidth
				select
			>
				{props.legalStructure.map((option, index) => (
					<MenuItem key={index} value={option}>
						{option}
					</MenuItem>
				))}
			</TextField>
		</FormControl>
		<FormControl
			className={"login-form-control"}
			fullWidth
		>
			<TextField
				style={{marginBottom: 30}}
				type={"text"}
				onChange={(event) =>
					props?.setCompanyNumber(event.target.value)
				}
				label={_renderCompanyNumberLabel(props?.selectedOption ?? "")}
				variant={"outlined"}
				name={"company_number"}
				id={"company_number"}
				value={props?.company_number}
				disabled={_isCompanyNumberDisabled(props?.selectedOption ?? "")}
			/>
			<small>
				<strong>
					{_renderSmallInformation(props?.selectedOption ?? "")}
				</strong>
			</small>
		</FormControl>
	</Box>
);

export default CompanyDefaultInformation;
