import React from "react";
import MUIDataTable from "mui-datatables";
import Loading from "../Loading";
import Grid from "@mui/material/Grid";
import Download from "@mui/icons-material/Download";


/**
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentCollectionTable = ({
	profile_data = [],
	profile_loading = false,
	downloadPaymentReport = () => {},
}): JSX.Element  => {

	const columns = [
		{
			name: 'id',
			label: "ID",
			options: {
				display: false,
			},
		},
		{
			name: 'payment_date',
			label: "DATE",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"})
			},
		},
		{
			name: 'payment_value',
			label: "AMOUNT",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"})
			},
		},
		{
			name: 'payment_status',
			label: "STATUS",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"})
			},
		},
		{
			name: 'id',
			label: 'Actions',
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
				customBodyRender: (value) =>
					<Grid >
						<Download
							className={"info-icon"}
							onClick={() => downloadPaymentReport(value) }
						/>
					</Grid>
			}
		}
	];

	return (
		<Grid>
			{
				profile_loading ? (
					<Loading/>
				) : (
					<Grid>
						<MUIDataTable
							className={"data-table payments-table"}
							columns={columns}
							data={profile_data?.payments ?? []}
							options={{
								filter: false,
								selectableRowsHeader: false,
								selectableRows: "none",
								responsive: "simple",
								viewColumns: false,
							}}
							pagination
						/>
					</Grid>
			)}
		</Grid>
	);
}

export default PaymentCollectionTable;