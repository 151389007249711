import * as types from './types'
import UsersService from "../../services/Users";
import * as SnackBar from "../SnackBar";
import {handleRequestError} from "../../utils/Actions";
import {me} from "../Profile";


export const createUser = (userdata: any) => dispatch => {
	dispatch({type: types.CREATE_USER_STARTED});

	new UsersService()
		.createUser(userdata)
		.then(({data, status}) => {
			switch (status) {
				case 200:
				case 201:
					dispatch({type: types.CREATE_USER_SUCCESS, data})
					dispatch(SnackBar.success("User created successfully"))
					dispatch(me())
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.CREATE_USER_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.CREATE_USER_FAILURE, data)));
}

export const deleteUser = (userdata: any) => dispatch => {
	dispatch({type: types.DELETE_USER_STARTED});

	new UsersService()
		.deleteUser(userdata)
		.then(({data, status}) => {
			switch (status) {
				case 200:
					dispatch({type: types.DELETE_USER_SUCCESS, data})
					dispatch(SnackBar.success(`The user has been deleted!`))
					dispatch(me())
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.DELETE_USER_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.DELETE_USER_FAILURE, data)));
}

export const editUser = (userdata: any) => dispatch => {
	dispatch({type: types.EDIT_USER_STARTED});

	new UsersService()
		.editUser(userdata)
		.then(({data, status}) => {
			switch (status) {
				case 200:
					dispatch({type: types.EDIT_USER_SUCCESS, data})
					dispatch(SnackBar.success(`${userdata.forename} ${userdata.surname} has been edited!`))
					dispatch(me())
					break;
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.EDIT_USER_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.EDIT_USER_FAILURE, data)));
}

export const checkIsSuper = (userdata: any) => dispatch => {
	dispatch({type: types.CHECK_SUPER_USER_STARTED});

	new UsersService()
		.checkIsSuper(userdata)
		.then(({data, status}) => {

			switch (status) {

				case 200:
					if (data.data.is_super === false) {
						window.location = '/'
						break;
					} else {
						break;
					}
				default:
					dispatch(SnackBar.warnAPIStatus(data.code, types.CHECK_SUPER_USER_FAILURE))
					break;
			}
		})
		.catch(data => dispatch(handleRequestError(types.CHECK_SUPER_USER_FAILURE, data)));
}
