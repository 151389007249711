import SoftwaresService from "../../services/Softwares";
import {GET_SOFTWARES, GET_API_UPLOADS, GET_API_DATE_UPLOADS} from "./types";


export const get = () => dispatch => {
	new SoftwaresService()
		?.get(['third_party_software'])
		?.then(response => dispatch({
				type: GET_SOFTWARES,
				softwareList: response?.data?.data ?? [],
			})
		)
		?.catch(error => console.error(error));
};

export const getApiUploads = (data = {}, queryString = {}) => dispatch => {
	dispatch({
		type: GET_API_UPLOADS,
		isLoading: true
	});
	new SoftwaresService()
		?.getApiUploads(data, queryString)
		?.then(response => {
				dispatch({
					type: GET_API_UPLOADS,
					apiUploads: response?.data?.data ?? [],
					isLoading: false
				});
			}
		)
		?.catch(error => {
			dispatch({
				type: GET_API_UPLOADS,
				isLoading: false
			});
			console.error(error);
		});
};

export const getApiUploadsSpreadSheet = (data) => dispatch => {
	dispatch({
		type: GET_API_UPLOADS,
		isLoading: true
	});
	new SoftwaresService()
		?.getApiUploadsSpreadSheet(data)
		?.then(response => {
			if (response?.data?.data) {
				const url = window.URL.createObjectURL(new Blob([response.data.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'result.csv');
				document.body.appendChild(link);
				link.click();
			}
			dispatch({
				type: GET_API_UPLOADS,
				isLoading: false
			});
		})
		?.catch(error => {
			dispatch({
				type: GET_API_UPLOADS,
				isLoading: false
			});
			console.error(error);
		});
};

/**
 * @param id
 * @param data
 * @returns {(function(*): void)|*}
 */
export const update = (id, data = {}) => dispatch => {
	if (id && data) {
		new SoftwaresService()
			?.update(id, data)
			?.then(() => dispatch(get()))
			?.catch(error => console.error(error));
	}
};

export const getPayrollDates = (data) => dispatch => {
	dispatch({
		type: GET_API_DATE_UPLOADS,
		isLoading: true,
	});
	new SoftwaresService()
		?.getPayrollDates(data)
		?.then(response => {
			if (response?.data?.data?.length > 0) {
				let payrollStartDate = [];
				let payrollEndDate = [];
				for (let k = 0; k < response?.data?.data?.length; k++) {
					payrollStartDate[k] = response?.data?.data[k]?.payroll_start_date;
					payrollEndDate[k] = response?.data?.data[k]?.payroll_end_date;
				}
				dispatch({
					type: GET_API_DATE_UPLOADS,
					payroll_start_date: payrollStartDate,
					payroll_end_date: payrollEndDate,
					isLoading: false,
				});
			} else {
				dispatch({
					type: GET_API_DATE_UPLOADS,
					isLoading: false,
				});
			}
		})
		?.catch(error => {
			dispatch({
				type: GET_API_DATE_UPLOADS,
				isLoading: false,
			});
			console.error(error);
		});
};

export const downloadAllApiUpload = (data) => dispatch => {
	dispatch({
		type: GET_API_UPLOADS,
		isLoading: true
	});
	new SoftwaresService()
		?.downloadAllApiUpload(data)
		?.then(response => {
			if (response?.data?.data) {
				const url = window.URL.createObjectURL(new Blob([response.data.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'AllApiUploads.csv');
				document.body.appendChild(link);
				link.click();
			}
			dispatch({
				type: GET_API_UPLOADS,
				isLoading: false
			});
		})
		?.catch(error => {
			dispatch({
				type: GET_API_UPLOADS,
				isLoading: false
			});
			console.error(error);
		});
};