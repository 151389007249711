import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @constructor
 */
export default function AdvisorsCard(props = {
	history: {
		push: () => {},
	}
}): JSX.Element|*[] {
	if (props) {

		return (
			<Card
				id={"advisors-card-container"}
				onClick={() => props.history("/sign-up-advisor")}
			>
				<CardHeader
					avatar={
						<Typography gutterBottom className={"MuiCardHeader-avatar"}>
							|
						</Typography>
					}
					title={"For Advisors"}
					subheader={"Sign up as an advisor"}
				/>
				<CardContent className={"MuiCard-content"}>
					<Typography  gutterBottom>
						No matter how many clients, there is a workplace for all of them.
					</Typography>
				</CardContent>
				<CardActions>
					<Grid
						xs={6}
						sm={6}
						md={6}
						lg={6}
						container
						item
					>
						<Grid
							xs={6}
							sm={6}
							md={6}
							lg={6}
							alignContent={"flex-start"}
							direction={"column"}
							item
							container
						>
							<Button className={"MuiCardHeader-button"}>
								SIGN UP
							</Button>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
		);
	}

	return [];
}
