import React from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyTitle = (props = {
	history: {
		location: {
			state: {
				company: {
                    title: ""
                }
			}
		}
	}
}): JSX.Element => (
	<div>
		<FormControl
			className={"signup-form-control-2 confirm-company-title"}
			fullWidth
		>
			<Typography
				align={"left"}
				style={{fontWeight: "bold", marginBottom: 22}}
			>
				{JSON.parse(sessionStorage.getItem("EmployerObject")).title
				+ " - "
				+ JSON.parse(sessionStorage.getItem("EmployerObject")).company_number}
			</Typography>
		</FormControl>
	</div>
);

CompanyTitle.propTypes = {
	history: PropTypes.shape({
		history: PropTypes.shape({
			location: PropTypes.shape({
				state: PropTypes.shape({
					employerAttributes: PropTypes.shape({
						title: PropTypes.string,
					})
				})
			})
		})
	}).isRequired,
};

export default CompanyTitle;