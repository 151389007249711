import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param token
 * @returns {Promise<AxiosResponse<any>>}
 */
const CheckForgotPasswordToken = (token:string = ""): Promise<AxiosResponse<any>> => Request.get(`/advisors/forgot-password/check-token/${token}`);

export default CheckForgotPasswordToken;
