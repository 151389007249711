import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass"
import Request from "../../request";
import {AxiosResponse} from "axios";

export default class SoftwaresService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Softwares)
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	getApiUploads(data: any[] = false, queryString = {}): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this.request.GetApiUploads(data ?? false, queryString);
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	getApiUploadsSpreadSheet(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this.request.GetApiUploadsSpreadSheet(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	getPayrollDates(data: any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this.request.GetPayrollDates(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	downloadAllApiUpload(data:any[] = false): Promise<AxiosResponse<*>>|null {
		if (this?.request && data) {
			return this.request.DownloadAllApiUpload(data ?? false);
		}

		return new Promise((resolve,reject) => reject());
	}
}