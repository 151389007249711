import React, {useState} from "react";
import {useDispatch} from "react-redux";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MaskedInput from "react-text-mask";
import {createAutoCorrectedDatePipe, onChange} from "../../../utils/Helpers";
import PropTypes from "prop-types";
import GetStagingDateByApi from "../../GetStagingDateByApi";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyStagingDate = (props = {
	companyExist: false,
	stagingDate: "",
	setStagingDate: () => {
	},
}): JSX.Element => {
	const [stagingDateOption, setStagingDateOption] = useState(false);
	const [taxRelief, setTaxRelief] = useState("");
	const [searchingText, setSearchText] = useState(false);
	const dispatch = useDispatch();

	return (
		<div
			style={props?.companyExist ? {display: "none"} : {display: "block"}}
		>
			<Divider
				style={{margin: "25px 0"}}
			/>
			<FormControl
				className={"signup-form-control-2 confirm-text"}
				fullWidth
			>
				<Typography
					align={"left"}
					style={{fontSize: 18, marginBottom: 15}}
				>
					Staging date
				</Typography>
			</FormControl>

			<Typography
				style={{marginBottom: "2%"}}
			>
				You don't know your employer's staging date?
				<a
					href="# " onClick={()=> setStagingDateOption(!stagingDateOption)}
				>
					{!stagingDateOption ? " Click here" : " Hide"}
				</a>
			</Typography>

			<FormControl
				variant={"outlined"}
				className={"login-form-control-staging-date"}
				fullWidth
			>
				<MaskedInput
					mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
					placeholderChar={'\u2000'}
					className={"staging-date-input"}
					placeholder="Staging date (dd/mm/yyyy format). *"
					label="Staging date"
					variant="outlined"
					name={"staging"}
					id={"staging"}
					value={props?.stagingDate}
					onChange={event => onChange(props?.setStagingDate(event.target.value))}
					pipe={createAutoCorrectedDatePipe("dd mm yyyy", {
						minYear: 1000,
						maxYear: new Date().getFullYear()
					})}
				/>
			</FormControl>
			<FormControl
				variant={"outlined"}
				className={"login-form-control-staging-date"}
				fullWidth
				style={
					stagingDateOption ? {display: "block"} : {display: "none"}
				}
			>
				<GetStagingDateByApi
					searchingText={searchingText}
					setSearchText={setSearchText}
					setStagingDate={props?.setStagingDate}
					taxRelief={taxRelief}
					setTaxRelief={setTaxRelief}
					dispatch={dispatch}
				/>
			</FormControl>
			<Divider style={{margin: "2% 0"}}/>
		</div>
	);
}

CompanyStagingDate.propTypes = {
	stagingDate: PropTypes.string.isRequired,
	setStagingDate: PropTypes.func.isRequired,
	companyExist: PropTypes.any,
};

export default CompanyStagingDate;