import SignUp from "./SignUp";
import Profile from "./Profile";
import Authorization from './Authorization';
import Employer from './Employer';
import Users from "./Users";
import PAPDIS from "./PAPDIS";
import Contributions from './Contributions';
import GetOptOutRequest from "./OptOut";
import Softwares from "./Softwares";
import Notification from "./Notification";
import BulkEmployersUploadDetails from "./BulkEmployers";
import Referral from "./Referral";
import IndividualIFA from "./IFA/IndividualIFA";
import EmployerIFA from "./IFA/EmployerIFA";
import IFA from "./IFA/IFA";
import Forbidden from './403';

const Requests = {
	SignUp,
	Profile,
	Authorization,
	Employer,
	Users,
	PAPDIS,
	Contributions,
	GetOptOutRequest,
	Softwares,
	Notification,
	BulkEmployersUploadDetails,
	Referral,
	IndividualIFA,
	EmployerIFA,
	IFA,
	Forbidden,
};

export default Requests;