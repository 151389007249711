import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import {CardActions} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

/**
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
export const BeneficiaryCard = (
	{
		history = () => {
		},
	}
): JSX.Element => (
	<Card
		id={"beneficiary-information"}
		onClick={() => history("/beneficiary-information")}
	>
		<CardHeader
			title={"BENEFICIARY"}
			subheader={"To start using your IFA Dashboard, we need to verify some information"}
		/>
		<CardActions>
			<Grid
				xs={12}
				sm={12}
				md={12}
				lg={12}
				container
				item
			>
				<Grid
					xs={6}
					sm={6}
					md={6}
					lg={6}
					alignContent={"flex-start"}
					direction={"column"}
					item
					container
				>
					<Button className={"MuiCardHeader-button"}>LET'S DO IT</Button>
				</Grid>
				<Grid
					xs={6}
					sm={6}
					md={6}
					lg={6}
					alignContent={"flex-end"}
					direction={"column"}
					item
					container
				>
					<IconButton
						className={"MuiCardHeader-icon-button"}
						aria-label={"YOUR NIN AND ADDRESS NEED TO BE INFORMED"}
						disableFocusRipple
						disableTouchRipple
						disableRipple
					>
						<ReportOutlinedIcon/>
					</IconButton>
				</Grid>
			</Grid>
		</CardActions>
	</Card>
);