import React, {useEffect} from "react";

import {connect} from "react-redux";
import Wrapper from "../../layout/Logged/Wrapper";

import Loading from "../../components/Loading";

import {me} from "../../actions/Profile";

import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import {ModalContents} from "../../utils/ModalContents";
import MUIDataTable from "mui-datatables";
import {getFunds} from "../../actions/IFA/IndividualsIFA";
import Grid from "@mui/material/Grid";
import TableCellItem from "../../components/TableCellItem";
import DescriptionIcon from '@mui/icons-material/Description';
import IconButton from "@mui/material/IconButton";

/**
 * @param me
 * @param profile_data
 * @param profile_loading
 * @param history
 * @param funds
 * @param getFunds
 * @returns {JSX.Element}
 * @constructor
 */
const IfaFunds = (
	{
		me = () => false,
		profile_data = false,
		profile_loading = false,
		history = {},
		funds = [],
		getFunds = () => {
		},
	}
): JSX.Element => {

	useEffect(() => {
			me();
			getFunds();
		},
		[
			me,
			getFunds,
			profile_data?.id
		]
	);

	const renderPerformance = (tableMeta) => (
		<Grid
			container
			justifyContent={"space-around"}
		>
			<TableCellItem
				title={tableMeta?.rowData[2] !== null ? `%${tableMeta?.rowData[2]}` : "N/A"}
				subtitle={"1 month"}
				item
				sm={4}
			/>

			<TableCellItem
				title={tableMeta?.rowData[3] !== null ? `%${tableMeta?.rowData[3]}` : "N/A"}
				subtitle={"3 months"}
				item
				sm={4}
			/>

			<TableCellItem
				title={tableMeta?.rowData[4] !== null ? `%${tableMeta?.rowData[4]}` : "N/A"}
				subtitle={"YTD"}
				item
				sm={4}
			/>
		</Grid>
	);

	const columns = [
		{
			name: "id",
			label: "ID",
			options: {
				display: false,
			}
		},
		{
			name: 'name',
			label: 'Fund',
			options: {
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"})
			}
		},
		{
			name: 'one_month',
			label: 'one_month',
			options: {
				display: false
			}
		},
		{
			name: 'three_months',
			label: 'three_months',
			options: {
				display: false
			}
		},
		{
			name: 'ytd',
			label: 'ytd',
			options: {
				display: false
			}
		},
		{
			name: 'performance',
			label: 'Performance',
			options: {
				customBodyRender: (value, tableMeta) => renderPerformance(tableMeta),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"})
			}
		},
		{
			name: 'fact_sheets',
			label: 'FACT-SHEET',
			flex: 0.5,
			options: {
				customBodyRender: (value) => value !== null && (
					<IconButton
						onClick={() => window.open(value, '_blank')}
					>
						<DescriptionIcon/>
					</IconButton>
				),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"})
			}
		},
	]


	if (!profile_data || profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	if (profile_data?.email) {
		window.$crisp.push([
			"set",
			"user:email",
			profile_data.email
		]);
		window.$crisp.push([
			"set",
			"user:nickname",
			[
				`${profile_data?.userLogged?.forename} ${profile_data?.userLogged?.surname}`
			]
		]);
		window.$crisp.push(["set", "user:phone", [profile_data?.telephone]]);
	}

	return (
		<Wrapper
			history={history}
			profile_data={profile_data}
			isIFA={true}
		>

			<PrimaryHeader
				backIcon
				helpIcon
				helpModal={ModalContents.home_help}
			>
				IFA Dashboard - Funds
			</PrimaryHeader>
			<Grid>
				<MUIDataTable
					className={"data-table funds-table"}
					columns={columns}
					data={funds}
					options={{
						filter: false,
						selectableRowsHeader: false,
						selectableRows: "none",
						responsive: "simple",
						viewColumns: false,
					}}
					pagination
				/>
			</Grid>
		</Wrapper>
	);
}

export default connect(
	(state) => ({
		...state.Profile,
		...state.IndividualsIFA
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
		getFunds: () => dispatch(getFunds()),
	})
)(IfaFunds);