import React, {useState} from 'react';

import Dropzone from 'react-dropzone';

import UploadDNDDrawing from '../images/UploadDNDDrawing.svg';
import Typography from '@mui/material/Typography';
import DialogComponent from "./DialogComponent";
import Grid from "@mui/material/Grid";
import LockIcon from '@mui/icons-material/Lock';

/**
 *
 * @param {*} props
 * @returns {JSX.Element}
 * @constructor
 */
const FileUpload: JSX.Element = React.forwardRef(({
	id = 0,
	sendFile = () => {
	},
	is_b2b_b2c = false,
	onError = () => console.warn(`<FileUpload /> is missing the onError callback`),
	triggerModal = () => {
	},
	acceptedFormats = "*",
	account_locked = false
}: {
	onError: Function,
	acceptedFormats: String
}, ref): JSX.Element => {
	const [handleDialog, setHandleDialog] = useState(false);
	const [file, setFile] = useState(false);
	const handleInput = (e) => {
		if (e.length > 0) {
			setHandleDialog(true);
			setFile(e);
		} else {
			try {
				triggerModal([{message: `The file is invalid. Please upload .csv file`}]);
			} catch (e) {
				console.error(e);
			}
		}
	}

	return (
		<Grid width={"100%"}>
			<Dropzone
				ref={ref}
				disabled={account_locked}
				accept={acceptedFormats}
				onDrop={(e) => handleInput(e)}
			>
				{({getRootProps, getInputProps, isDragAccept}) => (
					<div className={"upload-box"} {...getRootProps()}>
						<input {...getInputProps()} />
						{
							account_locked ?
								<LockIcon/>
								:
								<img alt="upload here" src={UploadDNDDrawing}/>
						}
						<Typography
							variant={"body1"}
							component={"p"}
							align={"center"}
							className={"drag-and-drop"}
						>
							{
								account_locked ?
									"Your account is locked. Please contact support."
									:
									"Drag and drop your file here"
							}
						</Typography>
					</div>
				)}
			</Dropzone>
			<DialogComponent
				id={id}
				handleDialog={handleDialog}
				is_b2b_b2c={is_b2b_b2c}
				file={file}
				setHandleDialog={setHandleDialog}
				sendFile={sendFile}
			/>
		</Grid>
	);
})

export default FileUpload;