import {AxiosResponse} from "axios";
import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";
import Request from '../../request';

export default class OptOut extends AbstractServiceRequestClass {
    constructor() {
        super(Request.GetOptOutRequest);
    }

    /**
     * @param data
     * @returns {Promise<AxiosResponse<*>>|null}
     */
    getMyOptOut(data:any[] = false): Promise<AxiosResponse<*>>|null {
        if (this?.request && data) {

            return this?.request?.GetOptOut(data ?? false);
        }

        return new Promise((resolve,reject) => reject());
    }
}