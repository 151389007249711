import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param employer_id
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const ViewCompanyRequest = (employer_id:any = null): Promise<AxiosResponse<any>> => (
	Request.post(`/advisors/employer/view-company/${employer_id}`)
);

export default ViewCompanyRequest;