import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetRequest = (relationships = []): Promise<AxiosResponse<any>> => {
	if (relationships.length > 0) {
		return Request.get(`/advisors/softwares?${Request.parseParams(relationships)}`);
	}

	return Request.get(`/advisors/softwares`);
}

export default GetRequest;