import Request from "../../utils/Request";
import { AxiosResponse } from "axios";

/**
 * @returns {Promise<AxiosResponse<T>>}
 * @param data
 */
const DownloadPAPDISPastUploads = (data): Promise<AxiosResponse<*>> =>
{
	return Request.post(`/advisors/employer/download-papdis-past-uploads`, data);
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DownloadPAPDISPastUploads;
