import React, {useEffect} from "react";

import Wrapper from "../../layout/Logged/Wrapper";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import {connect} from "react-redux";
import Loading from "../../components/Loading";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useLocation, useNavigate} from "react-router-dom";
import {cancelPrepareEmandate, getEndToEnd, insert, prepareEmandate, retrieveBankList} from "../../actions/Banks";
import {me} from "../../actions/Profile";
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";

/**
 * @returns {JSX.Element}
 */
const SelectBanks = ({
	payment_loading = false,
	profile_data = [],
	retrieveBankList = () => {},
	me = () => {},
	token = "",
	prepareEmandate = () => {},
	prepare_emandate_loading = false,
}): JSX.Element => {
	const location = useLocation();
	const { state } = location;
	const { contributionValue, paymentDate} = state;

	const navigate = useNavigate();

	useEffect(() => {
		me();
	}, [me]);

	useEffect(() => {
		prepareEmandate({
			advisors: {
				collection_day: paymentDate ?? 1,
				contribution_value: contributionValue ?? 0
			}
		});
	}, [prepareEmandate, paymentDate, contributionValue]);

	if (!retrieveCollegiaAccessToken()) {
		navigate("/login");
	}

	if (payment_loading || prepare_emandate_loading) {
		return (
			<Grid
				container
				justifyContent={"center"}
			>
				<h2>
					We are fetching your bank data. Please wait...
				</h2>
				<Loading absolute/>
			</Grid>
		);
	}

	return (
		<Wrapper>
			<Slide in={true} direction={"left"}>
				<Grid id={"direct-debit-wrapper"}>
					<Grid
						direction={"column"}
						alignItems={"center"}
						alignContent={"center"}
						container
						item
						maxWidth={"1212px"}
						className={"intermediary-container select-banks-container"}
					>
						<PrimaryHeader
							history={navigate}
							backIcon
							backPage={"/"}
						>
							Finish your mandate
						</PrimaryHeader>

						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							item
							container
							id={"digit-your-bank-container"}
						>
							<Grid
								className={"info-container"}
								xs={12}
								sm={12}
								lg={12}
								md={12}
								direction={"column"}
								alignContent={"center"}
								alignItems={"center"}
								container
								item
							>
								{
									token.length > 0 ?
										<Grid
											container
											justifyContent={"center"}
											direction={"column"}
											id={"fetching-data"}
										>
											<h2>
												Fetching data complete. Please click on Continue to proceed.
											</h2>

											<Grid
												container
												justifyContent={"center"}
											>
												<Button
													variant={"contained"}
													color={"primary"}
													onClick={() => {
														let link = "https://sandbox.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=" + token;
														if (process.env.REACT_APP_ENV === "production") {
															link = "https://api.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=" + token;
														}
														window.open(link, "_blank");
														window.location.href = "/sign-direct-debit";
													}}
												>
													Continue
												</Button>
											</Grid>
										</Grid>
										: <>
											<Grid
												maxWidth={"800px"}
											>
												<Typography
													component={"p"}
													align={"center"}
												>
													For security reasons, Collegia requires a secure Open Banking
													transaction to
													independently verify your data and account details. This will allow
													us to
													securely set up a Direct Debit Instruction so that Collegia can
													collect
													your pension contributions.
												</Typography>
											</Grid>
										</>
								}

							</Grid>
							<Grid
								className={"ddi-pdf-download"}
								justifyContent={"center"}
								container
							>
								<div>
									<h5
										className={"ddi-pdf-download-header"}
									>
										Didn't find your bank?
										<a
											href={"/upload-bank-ddi-file"}
											rel="noreferrer"
										>
											{" Click here"}
										</a>
									</h5>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Slide>
		</Wrapper>
	);
}

const _mapStateToProps = state => {
	const {
		Profile = [],
		DirectDebit = [],
	} = state;

	return {
		...Profile,
		...DirectDebit,
	};
};

/**
 * @param dispatch
 * @returns {{retrieveBankList: (function(): *), insert: (function(*[]=): *)}}
 * @private
 */
const _mapDispatchToProps = dispatch => ({
	insert: (data: any[] = false, collegiaToken) => dispatch(insert(data, collegiaToken)),
	retrieveBankList: dispatch(retrieveBankList()),
	getEndToEnd: dispatch(getEndToEnd()),
	me: () => dispatch(me()),
	prepareEmandate: (data: any[] = false) => dispatch(prepareEmandate(data)),
	cancelPrepareEmandate: (id: number = 0) => dispatch(cancelPrepareEmandate(id)),
})

export default connect(_mapStateToProps, _mapDispatchToProps)(SelectBanks);