import {
	EDITED_FIELDS_RESET,
	EDITED_FIELDS_SET,
	EDITED_FIELDS_UPDATE,
	FETCH_PROFILE_DATA,
	LOGIN,
	UPDATE_FIELDS_FAILURE,
	UPDATE_FIELDS_STARTED,
	UPDATE_FIELDS_SUCCESS,
	DELETE_EMAIL_CONFIGURATIONS,
	UPLOAD_DDI_FILE, DOWNLOAD_PAYMENTS,
} from "../../actions/Profile/types";
import {resolveReduxState} from "../../utils/Helpers";
import PropTypes from "prop-types";
import {Reducer} from "redux";


/**
 * @type {{profile_loading: boolean, walkthrough_complete: boolean, login_text: string, profile_data: boolean}}
 */
const initial_state = {
	profile_loading: false,
	profile_data: {
		is_b2b_b2c: false,
		has_beneficiary: false,
		is_payroll_advisor: false,
		title: "",
		userLogged: {
			forename: "",
			surname: "",
		},
		my_ip: "",
	},
	login_text: "",
	employers: [],
	pending_request: false,
	upload_loading: false,
};

const ProfileReducerStore: Reducer = (state = initial_state, action = false) => {
	const {type = false} = action;

	switch (type) {
		case FETCH_PROFILE_DATA:
		case LOGIN:
			return resolveReduxState(state, action);

		case EDITED_FIELDS_RESET:
			return {...state, edited_fields: {}};

		case EDITED_FIELDS_SET:
			return {...state, edited_fields: action.edited_fields};

		case EDITED_FIELDS_UPDATE:
			return {
				...state,
				edited_fields: {
					...state.edited_fields,
					[action.fieldname]: action.value
				}
			};

		case UPDATE_FIELDS_STARTED:
			return {...state, pending_request: true};

		case UPDATE_FIELDS_FAILURE:
			return {...state, pending_request: false};

		case UPDATE_FIELDS_SUCCESS:
			return {...state, pending_request: false};

		case DELETE_EMAIL_CONFIGURATIONS:
		case UPLOAD_DDI_FILE:
		case DOWNLOAD_PAYMENTS:
			return resolveReduxState(state, action);

		default:
			return state;
	}
};

ProfileReducerStore.propTypes = {
	profile_loading: PropTypes.bool,
	profile_data: PropTypes.shape({
		is_b2b_b2c: PropTypes.bool,
	}),
	login_text: PropTypes.string,
	employers: PropTypes.array,
	pending_request: PropTypes.any,
};

export default ProfileReducerStore;