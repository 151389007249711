import {OPTOUTGET} from "./type";
import OptOut from "../../services/OptOut";

/**
 *
 * @param data
 * @returns {function(*): void}
 */

export const getOptOut = (data) => dispatch => {
	if (data) {
		new OptOut()
			.getMyOptOut(data)
			.then(response => {
				dispatch({
					type: OPTOUTGET,
					myoptoutlist: response.data.data ?? [],
				});
			})
			.catch(error => console.error(error));
	}
};