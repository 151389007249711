import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetApiUploadsRequest = (data = [], queryString: {}): Promise<AxiosResponse<any>> => {
	if (queryString) {
		return Request.post(`/advisors/api-uploads?${Request.parseParams(queryString)}`, data);
	}
	return Request.post("/advisors/api-uploads",data);
}
export default GetApiUploadsRequest;