import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Wrapper from "../layout/Logged/Wrapper";
import LoadingPanel from "../components/LoadingPanel";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import {me, uploadDdiFile} from "../actions/Profile";
import Dropzone from "react-dropzone";
import Delete from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import UploadDNDDrawing from '../images/UploadDNDDrawing.svg';
import ProcessingFileDdi from '../images/ProcessingFileDdi.svg';
import PdfIcon from "../images/PdfIcon.svg";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {warn} from "../actions/SnackBar";
import UploadBankDdiFileModal from "../components/UploadBankDdiFileModal";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import TabPanel from "../components/TabPanel";


const acceptedPdfFormats = [
	".pdf"
].join(',');

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UploadBankDdiFile = ({
	profile_data = [],
	profile_loading = false,
	upload_loading = false,
	me = () => {
	},
	uploadDdiFile = () => {
	},
	warn = () => {
	},
	history = () => {
	},
}): JSX.Element => {
	const initialState = {alt: "", srcPdf: null},
		[{alt, srcPdf}, setPreview] = useState(initialState),
		[open, setOpen] = React.useState(false);

	const handleDrop = event => {
		const files = event;
		let file_size = files[0].size;
		if (file_size > 3145728) {
			warn("The file must be less than 3 Megabytes");
		} else {
			if (files.length > 0) {
				setPreview(
					files.length ?
							{srcPdf: files[0], alt: files[0].name}
						:
							initialState
				);
			}
		}
	};

	const handleModal = (open) => {
		setOpen(!open);
	};

	useEffect(() => {
		me();
	}, [
		me,
	]);

	if (profile_loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}

	return (
		<Wrapper
			history={history}
			showSandwich={true}
		>
			<TabPanel
				className={"main-page-container-wrapper"}
				id={"new-company-info-logged-wrapper"}
				value={1}
				index={1}
			>
				<PrimaryHeader
					history={history}
					backPage={'/main-page'}
					backIcon
				>
					Select Your Bank
				</PrimaryHeader>

				{
					profile_data?.ddi_pdf_link ?
						<Grid>
							<Grid
								container
								justifyContent={"center"}
								className={"upload-bank-ddi-file"}
							>
								<Typography
									className={"upload-bank-ddi-file-processing-file"}
									variant={"body1"}
								>
									We are processing your file, you will receive an email once it is done.
								</Typography>
								<img
									width={"100%"}
									className={"ddi-file-upload-img-size"}
									alt={""}
									src={ProcessingFileDdi}
								/>
							</Grid>
							<Grid
								container
								justifyContent={"flex-end"}
								marginTop={3}
							>
								<Button
									color="primary"
									variant="contained"
									onClick={() => history("/")}
								>
									Go back to the dashboard
								</Button>
							</Grid>
						</Grid>
						:
						<Grid
							className={"upload-bank-ddi-file"}
						>
							<Grid
								container
							>
								{
									upload_loading ?
										<LoadingPanel/>
										:
										<Dropzone
											accept={acceptedPdfFormats}
											onDrop={e => handleDrop(e)}
										>
											{({getRootProps, getInputProps}) => (
												<Grid
													container
													justifyContent={"center"}
													className={!srcPdf ? "drag-and-drop-bank-ddi-file" : "upload-logo-no-hover"}
													{...getRootProps()}
												>
													<Grid
														container
														justifyContent={"center"}
													>
														<Typography
															variant={"body1"}
															noWrap
														>
															{srcPdf ? "" : "Upload your completed DDI file here"}
														</Typography>
													</Grid>

													<input
														{...getInputProps()}
														onChange={ e => handleDrop(e.target.files) }
														disabled={srcPdf ? "disabled" : ""}
													/>
													<img
														alt=""
														src={!srcPdf ? UploadDNDDrawing : PdfIcon}
														className={!srcPdf ?
															"upload-bank-ddi-file-img-drawing"
															:
															""
														}
													/>
													<Grid
														container
														justifyContent={"center"}
													>
														{
															!srcPdf ?
																<Typography
																	variant={"body1"}
																	noWrap
																	className={"company-info-drag-and-drop"}
																>
																	Drag and drop your file here
																</Typography>
																:
																<Typography
																	variant={"body1"}
																	className={"company-info-img-name"}
																	noWrap
																>
																	{alt}
																</Typography>
														}
														{
															srcPdf &&
																<Delete
																	className={"upload-bank-ddi-deleted"}
																	onClick={() =>
																		setPreview({
																			srcPdf: null,
																			alt: ""
																		})
																	}
																/>
														}
													</Grid>
												</Grid>
											)}
										</Dropzone>
								}
							</Grid>

							<Grid
								container
								justifyContent={"flex-end"}
								marginTop={2}
							>
								{
									open &&
										<UploadBankDdiFileModal
											open={open}
											handleModal={handleModal}
										/>
								}
								<Button
									color={"primary"}
									variant={"contained"}
									className={"company-info-buttons-refresh"}
									onClick={() =>
										uploadDdiFile(
											{pdf: srcPdf},
										)
									}
								>
									Send
								</Button>
							</Grid>
						</Grid>
				}
			</TabPanel>
			{
				!profile_data?.ddi_pdf_link &&
					<Grid
						container
						justifyContent={"center"}
						marginTop={3}
					>
						<Grid
							className={"btn-download-ddi-file"}
						>
							<Button
								color={"primary"}
								variant={"outlined"}
								className={"company-info-buttons-refresh"}
								onClick={() => window.open(process.env.REACT_APP_API + "download-ddi-file")}
							>
								<FileDownloadIcon
									className={"company-info-buttons-refresh"}
								/>
								Download the DDI file
							</Button>
						</Grid>
						<Grid
							className={"fill-online"}
						>
							<Button
								color={"primary"}
								variant={"contained"}
								className={"company-info-buttons-refresh"}
								onClick={() => handleModal(open)}
							>
								Fill Online
							</Button>
						</Grid>
					</Grid>
			}
		</Wrapper>
	);
}
export default connect(
	(state) => ({
		...state.Profile,
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
		uploadDdiFile: (data: any[] = false) => dispatch(uploadDdiFile(data)),
		warn: (message = "") => dispatch(warn(message))
	})
)(UploadBankDdiFile);