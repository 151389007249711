
import GetIfaEmployers from "./GetIfaEmployers";
import EmployerIfaAccess from "./EmployerIfaAccess";
import GetIfaEmployees from "./GetIfaEmployees";

const EmployerIFA = {
	GetIfaEmployers,
	EmployerIfaAccess,
	GetIfaEmployees,
};

export default EmployerIFA;