import {
	PAPDIS_GET_FAILURE,
	PAPDIS_GET_STARTED,
	PAPDIS_GET_SUCCESS,
	PAPDIS_GET_UPLOADS_FAILURE,
	PAPDIS_GET_UPLOADS_STARTED,
	PAPDIS_GET_UPLOADS_SUCCESS,
	PAPDIS_UPLOAD_FAILURE,
	PAPDIS_UPLOAD_SET_TRIGGER,
	PAPDIS_UPLOAD_STARTED,
	PAPDIS_UPLOAD_SUCCESS,
	PAPDIS_UPLOAD_PROGRESS_UPDATE, DOWNLOAD_PAPDIS_PAST_UPLOADS,
} from "../../actions/PAPDIS/types";
import {resolveReduxState} from "../../utils/Helpers";
import {CONTRIBUTIONS_GET_SUCCESS} from "../../actions/Contributions/types";

const initial_state = {
	triggerUpload: false,
	isLoadingPastUploads: false,
	isContribution: false,
	employee_contributions: [],
	upload_percentage: 0,
	papdis_file_upload_id: null,
	fileName: "",
	label: "",
};

const PAPDISReducer = (state = initial_state, action = false) => {
	if (!action) {
		return state;
	}

	const {type = false, data = []} = action;

	switch (type) {
		case DOWNLOAD_PAPDIS_PAST_UPLOADS:
			return resolveReduxState(state, action)
		case PAPDIS_UPLOAD_STARTED:
			return {
				...state,
				isLoading: true,
			};

		case PAPDIS_UPLOAD_SUCCESS:
			return {...state, isLoading: false, success: true};

		case PAPDIS_UPLOAD_FAILURE:
			return {...state, isLoading: false, success: true, message: action.message};

		case PAPDIS_UPLOAD_SET_TRIGGER:
			return {...state, triggerUpload: action.triggerUpload};

		case PAPDIS_GET_STARTED:
			return {
				...state,
				isLoading: true,
				employees_contributions_loading: action?.employees_contributions_loading ?? false,
			};

		case PAPDIS_GET_SUCCESS:
			return {
				...state,
				isLoading: false,
				employees_contributions_loading: action?.employees_contributions_loading,
				employee_contributions: action?.employee_contributions ?? [],
				...(action.papdis),
				...(action?.contributions),
				...(action?.past_contributions),
				isContribution: false,
			};

		case CONTRIBUTIONS_GET_SUCCESS:
			return {
				...state,
				isLoading: false,
				employees_contributions_loading: action?.employees_contributions_loading,
				employee_contributions: action?.employee_contributions ?? [],
				...(action.papdis),
				...(action?.contributions),
				...(action?.past_contributions),
				isContribution: action?.isContribution,
			};
		case PAPDIS_GET_FAILURE:
			return {...state, isLoading: false};

		case PAPDIS_GET_UPLOADS_STARTED:
			if (state.lastRequestCancelToken) {
				state.lastRequestCancelToken.cancel();
			}
			return {
				...state,
				isLoadingPastUploads: state.loading ?? false,
				lastRequestCancelToken: action.cancelToken,
			};

		case PAPDIS_GET_UPLOADS_FAILURE:
			return {...state, isLoadingPastUploads: false};
		// case PAPDIS_GET_UPLOADS_SUCCESS: return { ...state, isLoadingPastUploads: false, past_uploads: data };

		case PAPDIS_GET_UPLOADS_SUCCESS:
			return {...resolveReduxState(state, {past_uploads: data}), isLoadingPastUploads: false};

		case PAPDIS_UPLOAD_PROGRESS_UPDATE:
			return {
				...resolveReduxState(state, {
						upload_percentage: action?.percentage ?? 0,
						papdis_file_upload_id: action?.fileId ?? null,
						fileName: action?.fileName ?? "",
						label: action?.label ?? "In progress...",
					}
				)
			};

		default:
			return state;
	}
};

export default PAPDISReducer;
