import Request from "../../utils/Request";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const uploadDdiFile = (data: any[] => false) =>
	Request.post('/advisors/upload-ddi-file', data);

export default uploadDdiFile;