import React from 'react';

// importing the default app bar component.
import AppBarHeader from "../Header/Logged/AppBar";

/**
 * @stateless
 * @param props
 * @returns {*}
 */
const Header = (props) => (
    <AppBarHeader {...props} />
);

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default Header;