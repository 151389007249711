import AbstractServiceRequestsClass from "../../../classes/AbstractServiceRequestsClass";
import Request from "../../../request";
import {AxiosResponse} from "axios";

export default class EmployerIFAService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.EmployerIFA);
	}

	/**
	 * @param data
	 * @param queryString
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	getIfaEmployers(data: any[] = false, queryString = {}): Promise<AxiosResponse<*>> | null {
		if (!this.request && !data) {
			return Promise?.reject("Cannot complete the request");
		}

		return this.request.GetIfaEmployers(data, queryString);
	}

	/**
	 * @param data
	 * @param queryString
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	getEmployees(data: any[] = false, queryString = {}): Promise<AxiosResponse<*>> | null {
		if (!this.request && !data) {
			return Promise?.reject("Cannot complete the request");
		}

		return this.request.GetIfaEmployees(data, queryString);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	employerIfaAccess(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request && !data) {
			return Promise?.reject("Cannot complete the request");
		}

		return this.request.EmployerIfaAccess(data);
	}


}