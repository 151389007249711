import React from "react";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import PropTypes from "prop-types";

const DirectorsInfo = (props = {
	directors: [{
		name: "",
	}],
	directorList: [],
	setDirector: () => {},
}) => (
	<React.Fragment>
		<FormControl
			className={"signup-form-control-2 confirm-text"}
			fullWidth
		>
			<Typography
				align={"left"}
				style={{fontWeight: "bold", fontSize: 16, display: 'none'}}
			>
				A director or authorised signatory of the company will need to
				sign a participation agreement with Collegia.
			</Typography>
		</FormControl>

		{props?.directors?.length > 0 && props?.directors[0].name !== ""
			? props?.directors.map((director, i) => (
				<RadioGroup
					aria-label="gender"
					name="gender1"
					value={props?.directorList}
					onChange={(e) => props?.setDirector(e)}
					key={`director-${i}`}
					style={{marginBottom: 18}}
				>
					<FormGroup>
						<FormControlLabel
							className={"signup-form-control-2 confirm-title"}
							control={
								<Radio
									name={"director_check_address"}
									id={"director_check_address"}
									color="primary"
									onChange={(e) => props?.setDirector(e)}
									value={director.name}
								/>
							}
							label={director.name}
						/>
					</FormGroup>
				</RadioGroup>
			))
			:
			[]
		}
	</React.Fragment>
);

DirectorsInfo.propTypes = {
	directors: PropTypes.array.isRequired,
    directorList: PropTypes.array.isRequired,
    setDirector: PropTypes.func.isRequired,
};

export default DirectorsInfo;