import Grid from "@mui/material/Grid";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const TabButton = (props): JSX.Element => (
	<Grid
		className={props.active === 'true' ? 'tab-button' : 'tab-button-inactive'}
		{...props}
	>
		<Grid
			className={'tab-button-title'}
			justifyContent={"center"}
			alignItems={"center"}
			container
		>
			{props?.children}
		</Grid>

		<Grid
			justifyContent={"center"}
			alignItems={"center"}
			container
		>
			<img
				src={props?.image}
				alt={props?.children}
			/>
		</Grid>
	</Grid>
);

