import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const createCompanyInAdvisor = (data): Promise<AxiosResponse<any>> => {
	return Request.post("/advisors/employer", {
		advisors: {
			...data,
			companies: [
				{
					company_number: data.company_number,
					nickname: data.title,
					primary_email: data.email,
					admins: [
						{
							forename:data.firstname,
							surname: data.surname,
							email: data.email,
						}
					],
					billing_type: "pull",
					setup: "email",
					staging_date: data.staging_date,
					terms_accepted: data.terms_accepted,
					callback_url: "https://www.collegia.co.uk",
					is_employer_under_bureau: data.is_employer_under_bureau,
					send_email: data.send_email,
					advisor_id: data.advisor_id,
					account_manager: data.account_manager,
					director: {
						name: data.director.name,
						email: data.director.email,
						phone_number: data.director.phone_number,
					},
				}
			]
		}
	});
}
/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default createCompanyInAdvisor;