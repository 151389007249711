import Request from "../../../utils/Request"
import { AxiosResponse } from "axios"

/**
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const GetFunds = (): Promise<AxiosResponse<T>> => {
	return Request.get("/advisors/individuals_ifa/funds");
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetFunds
