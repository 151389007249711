import React, {useState} from "react";
import FormControl from "@mui/material/FormControl"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import {validateEmail, renderReferralData} from "../../utils/Helpers";
import {useNavigate} from "react-router-dom";


/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderErrorMessage = (props: any[] = false): JSX.Element | *[] => {
	if (props) {
		const {errorMessage = ""} = props;

		if (errorMessage !== "") {
			return (
				<small className={"red-text"}>
					{errorMessage ?? ""}
				</small>
			);
		}
	}

	return [];
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const EmailDetail = (props) => {

	const [displayMessageEmailInUseError, setDisplayMessageEmailInUseError] = useState(false);
	const [displayMessageEmailInUseEmailValue, setDisplayMessageEmailInUseEmailValue] = useState("");
	const history = useNavigate();

	return (
		<Grid
			id={"signup-container"}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			container
			item
		>
			<form
				noValidate
				autoComplete={"off"}
				method={"post"}
				onSubmit={(event) => event.preventDefault()}
			>
				{renderReferralData(props?.referral ?? false)}
				<FormControl className={"signup-form-control-2 first-title"} fullWidth>
					<Typography align={"center"}>
						Sign up as an advisor
					</Typography>
				</FormControl>
				<FormControl className={"signup-form-control-2 second-title"} fullWidth>
					<Typography align={"center"}>
						What is your email?
					</Typography>
				</FormControl>
				<FormControl className={"signup-form-control"} fullWidth>
					<TextField
						type={"email"}
						onChange={(event) => {
							props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
							setDisplayMessageEmailInUseError(false)
						}
						}
						label={"E-mail"}
						variant={"outlined"}
						name={"email"}
						id={"email"}
						value={props.email}
						error={
							(!validateEmail(props.email) && !(props.email === "")) ||
							(displayMessageEmailInUseError && props.errorMessage !== "") ||
							(displayMessageEmailInUseEmailValue === props.email && props.errorMessage !== "")
						}
					/>

					{
						(!validateEmail(props.email) && props.email) &&
							<small className={"red-text"}>
								Please enter a valid e-mail
							</small>
					}

					{
						(displayMessageEmailInUseError || displayMessageEmailInUseEmailValue === props.email) &&
							_renderErrorMessage(props ?? false)
					}

				</FormControl>
				<FormControl className={"signup-form-control"} fullWidth>
					<Button
						variant={"contained"}
						color={"primary"}
						onClick={() => {
							props.proceedStep(props.stepIndex + 1)
							setDisplayMessageEmailInUseError(true)
							setDisplayMessageEmailInUseEmailValue(props.email)
						}}
						disabled={
							!props.email ||
							props.error ||
							!validateEmail(props.email) ||
							displayMessageEmailInUseError ||
							displayMessageEmailInUseEmailValue === props.email
						}
					>
						Continue
					</Button>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
					<span
						onClick={() => history("/")}
						style={{marginTop: "10px", fontSize: "0.9rem"}}
					>
						Back
					</span>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
					<small>Already have an account?</small>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
					<span onClick={() => history("/")}>Sign in</span>
				</FormControl>
			</form>
		</Grid>
	)
};

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default EmailDetail;