import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass"

import Request from "../../request"
import { AxiosResponse } from "axios"


export default class UsersService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Users)
	}

	/**
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	createUser(userdata: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request) return null

		return this?.request?.CreateUser(userdata)
	}


	deleteUser(userdata: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.DeleteUser(userdata);
	}

	editUser(userdata: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.EditUser(userdata);
	}


	checkIsSuper(userdata: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.CheckIsSuper(userdata);
	}

}