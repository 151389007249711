import {Reducer} from 'react';
import * as EmployerNotificationAlert from "../../actions/EmployerNotificationAlert/types";


const initial_state: {
	severity: string,
	message: string,
	open: boolean
} = {
	severity: "info",
	message: "",
	open: false
}

const EmployerNotificationAlertReducer: Reducer = (state = initial_state, action: any[] = {
	severity: "info",
}) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case EmployerNotificationAlert.HIDE_EMPLOYER_NOTIFICATION_ALERT:
			return {
				...state,
				open: false,
			};
		case EmployerNotificationAlert.TRIGGER_EMPLOYER_NOTIFICATION_ALERT:
			return {
				...state,
				open: true,
				message: action.message,
				severity: action.severity ?? state.severity,
			};

		default:
			return state;
	}
}

export default EmployerNotificationAlertReducer;