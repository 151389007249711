import {BANKS_FETCH_REQUEST, CREATE_DIRECT_DEBIT, GET_END_TO_END} from "../../actions/Banks/types";
import {resolveReduxState} from "../../utils/Helpers";
import PropTypes from "prop-types";


const initial_state = {
	banks_loading: false,
	payment_loading: false,
	banks: [],
	endToEnd: 0,
	new_payment_object: {
		fullUrl: null,
		aspspAuthUrl: null,
	}
};

const BanksStore = (state = initial_state, action = {}) => {
	if (!action?.type) return state;

	switch (action.type) {
        case BANKS_FETCH_REQUEST:
			return resolveReduxState(state, action);

		case GET_END_TO_END:
			return resolveReduxState(state, action);

		case CREATE_DIRECT_DEBIT:
			return resolveReduxState(state, action);

        default:
            return state;
    }
}

BanksStore.propTypes = {
	banks_loading: PropTypes.bool.isRequired,
	payment_loading: PropTypes.bool.isRequired,
    banks: PropTypes.array.isRequired,
	endToEnd: PropTypes.number.isRequired,
};

export default BanksStore;