import GetEmployerList from './GetEmployerList';
import UploadPapidsRequest from "./Upload";
import ViewCompany from "./ViewCompany";
import DeleteEmployeesList from "./DeleteEmployer";
import BulkEmployers from "./BulkEmployers";
import GetBulkEmployersHistory from "./GetBulkEmployersHistory";
import GenerateCompanyNumber from "./GenerateCompanyNumber";
import AddCompanyManually from "./AddCompanyManually";
import DownloadEmployerList from "./DownloadEmployerList";
import GetStagingDateByApiRequest from "./GetStagingDateByApiRequest";
import VerifyEmail from "./VerifyEmail";
import ConfirmOptOutContribution from "./ConfirmOptOutContribution";
import deletePastUpload from "./DeletePastUpload";
import GetCommunications from './GetCommunications';
import GetEmployeesCommunications from './GetEmployeesCommunications';

const Employer = {
    GetEmployerList,
	DownloadEmployerList,
    UploadPapidsRequest,
    ViewCompany,
    DeleteEmployeesList,
	BulkEmployers,
	GetBulkEmployersHistory,
	GenerateCompanyNumber,
	AddCompanyManually,
	GetStagingDateByApiRequest,
	VerifyEmail,
	ConfirmOptOutContribution,
	deletePastUpload,
	GetCommunications,
	GetEmployeesCommunications,
};

export default Employer;