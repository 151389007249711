import React, {useState} from "react";
import {connect, useDispatch} from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {clearArrayCompanies, getCompanies} from "../../actions/SignUp";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompaniesList = (props = {
	companies: [],
	employer: null,
	timeout: null,
	textInput: null,
	setEmployerValue: () => {
	},
	setTimeoutDispatch: () => {
	},
	setTextInput: () => {
	},
}) => {
	const {
			companies = [],
			setEmployerValue = () => {
			},
		} = props,
		dispatch = useDispatch(),
		[
			value,
			setValue,
		] = useState(null);

	/**
	 * @private
	 */
	const _companiesSearch = () => {

		if (props.timeout) {
			clearTimeout(props.timeout);
		}

		if (value) {
			clearTimeout(props.timeout);
			props.setTimeoutDispatch(setTimeout(() => dispatch(getCompanies({
				q: value,
			})), 1000));
		} else {
			dispatch(clearArrayCompanies());
		}
	};

	function setInputValues(employer) {
		props.setTextInput(employer);
		setEmployerValue(employer);
	}

	return (
		<Autocomplete
			id={"companies-dropdown"}
			options={Object.values(companies) ?? []}
			getOptionLabel={(option) => option.company_number + ' - ' + option.title}
			isOptionEqualToValue={(option, employer) => option && (option.title === employer.title)}
			onChange={(option, employer) => {
				setInputValues(employer);
			}}
			onKeyUp={() => _companiesSearch()}
			renderInput={(params) =>
				<TextField
					{...params}
					onChange={(event) => {
						setValue(event.target.value);
						props.setTextInput(event.target.value);
					}}
					value={value}
					style={{marginBottom: 15}}
					type={"text"}
					label={"Find a Company"}
					variant={"outlined"}
					name={"search-company"}
					id={"search-company"}
					fullWidth
				/>
			}
		/>
	);
}

export default connect(
	(state) => ({
		...state.Profile,
		...state.SignUp,
	}),
	(dispatch) => ({
		getCompanies: (data = {}) => dispatch(getCompanies(data)),
	})
)(CompaniesList);
