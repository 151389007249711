import React from "react";
import ContentGrid from "../../layout/ContentGrid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SectionGrid from "../../layout/SectionGrid";


/**
 * @returns {JSX.Element}
 * @constructor
 */
const TopBannerGrid = (): JSX.Element => (
	<SectionGrid className={"main-page-container-wrapper top-banner-content"}>
		<ContentGrid
			direction={"row"}
			alignItems={"flex-start"}
			alignContent={"center"}
			id={"confirm-new-company-container"}
			item
			container
		>
			<Grid
				justifyContent={"center"}
				direction={"column"}
				container
				item
				xs={8}
				sm={8}
				md={8}
				lg={8}
			>
				<Typography
					variant={"subtitle1"}
					className={"title-individual"}
					align={"center"}
					style={{fontSize: 30}}
				>
					Please, confirm your company
				</Typography>
				<Typography
					component={"p"}
					variant={"caption"}
					className={"subtitle-individual"}
					align={"center"}
				>
					Please, review the details below and confirm they are correct
				</Typography>
			</Grid>
		</ContentGrid>
	</SectionGrid>
);

export default TopBannerGrid;