import VerifyEmail from "./VerifyEmail";
import Create from "./Create";
import GetCompanies from "./GetCompanies";
import Setup from "./Setup";
import CreateSetup from './CreateSetup';
import GetDirectors from "../Employer/GetDirectors";
import CheckCompany from "./CheckCompany";
import requestAccessToEmployer from "./requestAcessToEmployer";
import createCompanyInAdvisor from "./createCompanyInAdvisor";


/**
 * @type {{VerifyAdminEmail: (function({}=): Promise<AxiosResponse<*>>), VerifyEmail: (function(*[]=): Promise<AxiosResponse<*>>), Create: (function(*[]=): Promise<AxiosResponse<*>>), GetCompanies: (function(*[]=): Promise<AxiosResponse<*>>), GetDirectors: (function(*[]=): Promise<AxiosResponse<*>>), Setup: (function(string=): Promise<AxiosResponse<*>>)}}
 */
const SignUpRequests = {
    VerifyEmail,
    Create,
    GetCompanies,
    Setup,
    CreateSetup,
    GetDirectors,
    CheckCompany,
    requestAccessToEmployer,
    createCompanyInAdvisor,
}

export default SignUpRequests;