export const FETCH_PROFILE_DATA = "PROFILE.fetch_profile_data";
export const RETRIEVE_NEW_PASSWORD = "PROFILE.RETRIEVE_NEW_PASSWORD";
export const LOGIN = "PROFILE.LOGIN";


// My Account Edit field
export const EDITED_FIELDS_UPDATE   = "PROFILE.EDITED_FIELDS_UPDATE";
export const EDITED_FIELDS_SET      = "PROFILE.EDITED_FIELDS_SET";
export const EDITED_FIELDS_RESET    = "PROFILE.EDITED_FIELDS_RESET";

// My Account Flow
export const UPDATE_FIELDS_STARTED = "PROFILE.UPDATE_FIELDS_STARTED";
export const UPDATE_FIELDS_FAILURE = "PROFILE.UPDATE_FIELDS_FAILURE";
export const UPDATE_FIELDS_SUCCESS = "PROFILE.UPDATE_FIELDS_SUCCESS";

export const UPDATE_EMAIL_CONFIGURATIONS = "UPDATE.EMAIL_CONFIGURATIONS";
export const DELETE_EMAIL_CONFIGURATIONS   = "PROFILE.DELETE_EMAIL_CONFIGURATIONS";

export const UPLOAD_DDI_FILE = "PROFILE.UPLOAD_DDI_FILE";

export const DOWNLOAD_PAYMENTS = "PROFILE.DOWNLOAD_PAYMENTS";
