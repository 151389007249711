import React from 'react';

import ContentGrid from "../../layout/ContentGrid";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


/**
 * @returns {JSX.Element}
 * @constructor
 */
const NoSoftwaresWarning: JSX.Element = () => (
	<Typography
		variant={"h4"}
		className={"manage-software-warning"}
	>
		You don't have any connected softwares
	</Typography>
);

/**
 * @param name
 * @param email
 * @param active_label
 * @param update
 * @param id
 * @returns {JSX.Element}
 * @constructor
 */
const SoftwareRow: JSX.Element = (
	{
		name = "",
		email = "",
		active_label = null,
		update = (id, data) => {
		},
		id = 0,
	}: {
		id: Number,
		name: string,
		email: string,
		active_label: any,
		update: Function,
	}
): JSX.Element => (
	<TableRow>
		<TableCell>{name}</TableCell>
		<TableCell>{email}</TableCell>
		<TableCell>{active_label}</TableCell>
		<TableCell align="right" width={140}>
			<IconButton onClick={() => update(id, {
				software_advisors: {
					active: true,
				}
			})}
			>
				<CheckCircleIcon/>
			</IconButton>
			<IconButton onClick={() => update(id, {
				software_advisors: {
					active: false,
				}
			})}
			>
				<CancelIcon/>
			</IconButton>
		</TableCell>
	</TableRow>
);

/**
 * @param softwareList
 * @param update
 * @returns {JSX.Element}
 * @constructor
 */
const SoftwaresTable: JSX.Element = (
	{
		softwareList = [],
		update = (id, data) => {
		},
	}
): JSX.Element => (
	<Table>
		<TableHead
			className={"manage-software-table-head"}
		>
			<TableRow>
				{["Name", "Email", "Connected", "Action"].map(label => (
					<TableCell
						key={`th-${label}`}
						align={label === "Action" ? "right" : "left"}>
						{label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
		<TableBody>
			{softwareList.map((option, index) => (
				<SoftwareRow
					key={index}
					value={option}
					name={softwareList[index]?.third_party_software?.name}
					email={softwareList[index]?.third_party_software?.email}
					active_label={softwareList[index]?.active_label}
					id={softwareList[index]?.id}
					update={update}
				>
				</SoftwareRow>
			))}
		</TableBody>
	</Table>
);

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SoftwareTablePanel = (props: Object = {
	softwareList: [],
}): JSX.Element => (
	<Grid
		alignItems={"center"}
		justifyContent={"space-between"}
		container
	>
		<Grid
			md={6}
			alignItems={"center"}
			justifyContent={"flex-start"}
			item
			container
		>
			<Typography
				variant={"h4"}
				className={"manage-software-title"}
			>
				Manage your connected software
			</Typography>
		</Grid>

		<ContentGrid
			md={12}
			item
		>
			{props?.softwareList != null
				? <SoftwaresTable {...props} />
				: <NoSoftwaresWarning/>
			}
		</ContentGrid>
	</Grid>
);

export default SoftwareTablePanel;