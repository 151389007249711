import React from 'react';

import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import {onChange} from "../utils/Helpers";
import {getStagingDateByApiAction} from "../actions/Employers";


/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderErrorMessage = (props: any[] = {
	errorMessage: "",
}): JSX.Element | *[] => {
	if (props) {
		const {
			errorMessage = ""
		} = props;

		if (errorMessage !== "") {
			return (
				<FormControl
					className={"Card-form"}
					fullWidth
				>
                    <span className={"error"}>
                        {errorMessage ?? ""}
                    </span>
				</FormControl>
			);
		}
	}

	return [];
}

const _submitUpdateStagingDateByApi = (props) => {
	props.setSearchText(true);
	const paye_number = props.taxRelief.split('/');
	props.dispatch(getStagingDateByApiAction({
		employers: {
			paye_district: paye_number[0],
			paye_code: paye_number[1]
		}
	},props.setStagingDate, props.setSearchText));
}

/**
 * @param props
 * @returns {*}
 * @constructor
 */
export default function GetStagingDateByApi(props) {
	if (!props) return;

	return (
		<Grid
			lg={12}
			xs={12}
			md={12}
			sm={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<FormControl
				className={"Card-content"}
				fullWidth
				style={{marginTop: 12,marginBottom:15}}
			>
				You can retrieve your company staging date using your Paye Scheme Reference in the form below.
			</FormControl>
			<Grid
				container
				spacing={3}
			>
				<Grid
					lg={8}
					sm={8}
					xs={8}
					md={8}
					item
				>
					<FormControl
						className={"Card-form"}
						fullWidth
					>
						<TextField
							type={"text"}
							onChange={event => onChange(props.setTaxRelief(event.target.value))}
							label={"Tax Office Number"}
							variant={"outlined"}
							name={"taxOfficeNumber"}
							id={"taxOfficeNumber"}
							value={props.taxRelief}
						/>
						<small style={{marginBottom: 25}}>
							Paye Scheme Reference (example: 123/AB45678)
						</small>
					</FormControl>
				</Grid>

				{_renderErrorMessage(props ?? false)}

				<Grid
					lg={4}
					sm={4}
					xs={4}
					md={4}
					item
				>
					<FormControl
						className={"Card-button"}
						fullWidth
					>
						<Button
							style={{height: "56px"}}
							variant={"contained"}
							color={"primary"}
							onClick={() => _submitUpdateStagingDateByApi(props)}
							disabled={!props.taxRelief || props.searchingText}
						>
							{!props.searchingText ? "Look Up" : "Searching..."}
						</Button>
					</FormControl>
				</Grid>
			</Grid>
		</Grid>
	);
}