import * as SnackBar from '../actions/SnackBar'

/**
 * @param action_type
 * @param responsedata
 * @returns {(function(*): void)|*}
 */
export const handleRequestError = (action_type, responsedata) => dispatch => {

	const { status = "?", data = {}} = responsedata?.response || {};

	const message = data?.data?.message
		// Message may come as a string inside an array inside an object keyh
		|| Object.values(data?.data || {}) // Generate array of arrays of strings
			?.reduce((m1, m2) => [...m1, ...m2], []) // flatten the array
			?.join(' ') // Generate final string
		// It could be just a js new Error("")
		|| responsedata?.message
		// No message returned from api
		|| `Something went wrong (${status})`;

	dispatch({ type: action_type, error: data });
	dispatch(SnackBar.error(message));
}