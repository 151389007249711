import Request from "../../../utils/Request"
import { AxiosResponse } from "axios"

/**
 * @param data
 * @param queryString
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const GetEmployees = (data:any[] = false, queryString = {}): Promise<AxiosResponse<T>> => {
	if (queryString) {
		return Request.post( `/advisors/employers_ifa/get-employees?${Request.parseParams(queryString)}`, data);
	}
	return Request.post("/advisors/individuals_ifa/get-employees", data);
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetEmployees
