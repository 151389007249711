import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param schedule_id
 * @param papdis_ids
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const GetContributionsPaymentRequest = (schedule_id:null|number = null, papdis_ids:any[] = []): Promise<AxiosResponse<any>> => {
	if (schedule_id) {
		return Request.get(`employers/contributions/payments/${schedule_id}/null`);
	} else if (papdis_ids.length > 0) {
		return Request.get(`employers/contributions/payments/${schedule_id}/${papdis_ids}`);
	}
}

export default GetContributionsPaymentRequest;