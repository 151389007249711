import IfaDashboard from "./IfaDashboard";
import AddNewClient from "./AddNewClient";
import IfaFunds from "./IfaFunds";
import IfaKeyStats from "./IfaKeyStats";
import BeneficiaryInformation from "./BeneficiaryInformation";


const IFADashboardPages = {
	IfaDashboard,
	IfaFunds,
	AddNewClient,
	IfaKeyStats,
	BeneficiaryInformation,
}

export default IFADashboardPages;