import {
	BANKS_FETCH_REQUEST,
	CANCEL_PREPARE_EMANDATE,
	CREATE_DIRECT_DEBIT,
	PREPARE_EMANDATE,
	VERIFY_EMANDATE
} from "./types";
import Request from "../../utils/Request";
import {requestNewLogin} from "../Profile";
import SnackBar from "../../store/SnackBar";


export const retrieveBankList = () => dispatch => {
	dispatch({
		type: BANKS_FETCH_REQUEST,
		banks_loading: true,
	});

	Request.get("/advisors/banks")
		.then(response => dispatch({
			type: BANKS_FETCH_REQUEST,
			banks_loading: false,
			banks: response?.data?.data ?? [],
		}))
		.catch(error => {
			if (error !== undefined) {
				if (error?.response) {
					if (error?.response?.status === 401) {
						requestNewLogin(false);
					}
				}
			}

            dispatch({
                type: BANKS_FETCH_REQUEST,
                banks_loading: false,
                banks: [],
            });
			console.error(error);
        });
}

export const getEndToEnd = () => dispatch => {
	dispatch({
		type: BANKS_FETCH_REQUEST,
		banks_loading: true,
	});

	Request.get("/advisors/banks/get-end-to-end")
        .then(response => dispatch({
            type: BANKS_FETCH_REQUEST,
            banks_loading: false,
	        endToEnd: response?.data ?? "",
        }))
        .catch(error => {
	        if (error !== undefined) {
		        if (error?.response) {
			        if (error?.response?.status === 401) {
				        requestNewLogin(false);
			        }
		        }
	        }
            dispatch({
                type: BANKS_FETCH_REQUEST,
                banks_loading: false,
	            endToEnd: 0,
            });
            console.error(error);
        });
}

export const insert = (data = {}) => dispatch => {
	if (data) {
		dispatch({
			type: CREATE_DIRECT_DEBIT,
			banks_loading: true,
			payment_loading: true,
		});
		Request.post("/advisors/direct-debit", data)
			.then(response =>
				dispatch({
					type: CREATE_DIRECT_DEBIT,
					new_payment_object: response?.data?.data,
					banks_loading: false,
					payment_loading: false,
				}))
			.catch(error => {
				if (error !== undefined) {
					if (error?.response) {
						if (error?.response?.status === 401) {
							requestNewLogin(false);
						}
					}
				}
				dispatch({
					type: CREATE_DIRECT_DEBIT,
					banks_loading: false,
					payment_loading: false,
					endToEnd: 0,
				});
				console.error(error);
			});
	}
};

export const prepareEmandate = (data) => dispatch => {
	dispatch({
		type: PREPARE_EMANDATE,
		prepare_emandate_loading: true,
		token: "",
	});
	if (data) {
		Request.post("/advisors/prepare-emandate", data)
			.then(response => {
				if (response?.data?.data?.success) {
					dispatch({
						type: PREPARE_EMANDATE,
						prepare_emandate_loading: false,
						token: response?.data?.data?.token
					});
				} else {
					dispatch({
						type: PREPARE_EMANDATE,
						prepare_emandate_loading: false,
						token: "",
					});
					SnackBar.error(response?.data?.message);
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: PREPARE_EMANDATE,
					prepare_emandate_loading: false,
					token: "",
				});
			});
	}
};

export const cancelPrepareEmandate = (id) => dispatch => {
	dispatch({
		type: CANCEL_PREPARE_EMANDATE,
		prepare_emandate_loading: true,
		token: "",
	});
	if (id) {
		Request.delete(`/advisors/cancel-preparate-emandate/${id}`)
			.then(response => {
				if (response?.data?.success) {
					dispatch({
						type: CANCEL_PREPARE_EMANDATE,
						prepare_emandate_loading: false,
						token: "",
					});
					window.location.href = "/";
				} else {
					dispatch({
						type: CANCEL_PREPARE_EMANDATE,
						prepare_emandate_loading: false,
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: CANCEL_PREPARE_EMANDATE,
					prepare_emandate_loading: false,
					token: "",
				});
			});
	}
};

export const verifyIfTheMandateFinish = (id: number = 0) => dispatch => {
	dispatch({
		type: VERIFY_EMANDATE,
		verify_emandate_loading: true
	});
	if (id) {
		Request.get(`/advisors/verify-if-the-emandate-finish/${id}`)
			.then(response => {
				if (response?.data?.data?.success) {
					dispatch({
						type: VERIFY_EMANDATE,
						verify_emandate_loading: false
					});
					window.location.href = "/";
				} else {
					dispatch({
						type: VERIFY_EMANDATE,
						verify_emandate_loading: true
					});
				}
			})
			.catch(error => {
				console.error(error);
				dispatch({
					type: VERIFY_EMANDATE,
					verify_emandate_loading: false
				});
			});
	}
};