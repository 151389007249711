import React from 'react';

import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Wrapper from "../layout/Wrapper";
import Logo from '../images/logo-icon.svg';
import MinimalFooter from "../layout/MinimalFooter";
import ForgotPasswordImage from '../images/ForgotPassword.svg';

const PasswordRequestSent = (props): JSX.Element => {
	return (
		<Wrapper showSandwich={false}>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"forgot-password-requested-wrapper"}>
					<Grid
						xs={12}
						lg={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						id={"header"}
						container
						item
						onClick={() => props.history("/login")}
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</Grid>
					<Grid
						id={"login-container"}
						lg={12}
						xs={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						spacing={0}
						container
						item
					>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Grid
									lg={12}
									xs={12}
									md={12}
									sm={12}
									alignItems={"center"}
									alignContent={"center"}
									direction={"column"}
									spacing={0}
									container
									item
									marginTop={18}
								>
									<img
										src={ForgotPasswordImage}
										alt={"Password forgot e-mail sent."}
										width={315}
									/>
								</Grid>
							</FormControl>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Done, instructions sent!
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Check your email <strong>{localStorage.getItem("passwordRetrieveEmail")}</strong> and follow the instructions.
								</Typography>
							</FormControl>
						</form>
					</Grid>
					<MinimalFooter />
				</div>
			</Slide>
		</Wrapper>
	);
}

export default PasswordRequestSent;