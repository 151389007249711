import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdateEmailConfigurations = (data:any[] = false): Promise<AxiosResponse<any>> =>
	Request?.put(`/advisors/update-email-configurations`, data)
	??
	Promise.reject(new Error('Request is not defined'));



export default UpdateEmailConfigurations;