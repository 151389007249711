import Request from "../../utils/Request"
import { AxiosResponse } from "axios"

/**
 * @param data
 * @param queryString
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const GetEmployerList = (data:any[] = false, queryString = {}): Promise<AxiosResponse<T>> => {
	if (queryString) {
		return Request.post( `/advisors/get-employers?${Request.parseParams(queryString)}`, data);
	}
	return Request.post("/advisors/get-employers", data);
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetEmployerList
