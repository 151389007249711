import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import Grid from "@mui/material/Grid";
import DownloadIcon from '@mui/icons-material/Download';
import {toggleSnackBar} from "../../utils/Helpers";
import {useDispatch} from "react-redux";
import TextField from "@mui/material/TextField";
import LoadingPanel from "../LoadingPanel";

function _renderDownloadIcon (tableMeta, profile_data, download) {
	return (
		<Grid>
			<DownloadIcon
				cursor={"pointer"}
				onClick={() =>
					download({
						"advisors" : {
							advisorId: profile_data?.id,
							start: tableMeta.rowData[1],
							end: tableMeta.rowData[2]
						}
					})
			}
			/>
		</Grid>

	)
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ApiUploadTable = ({
	profile_data = [],
	profile_loading = false,
	apiUploads = [],
	download = () => {},
	get = () => {
	},
	advisor_id = 0,
	payroll_start_date = [],
	payroll_end_date = [],
	downloadAllApiUpload = () => {
	},
	isLoading = false,
}): JSX.Element  => {

	const [rowsPerPage, setRowsPerPage] = useState(10),
		[sortDirection, setSortDirection] = useState("asc"),
		[actualPage, setActualPage] = useState(0),
		[filterArray, setFilterArray] = useState({}),
		[searchFindText, setSearchFindText] = useState("");
	let dispatch = useDispatch();

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (searchFindText.length >= 3 || searchFindText.length === 0) {
				get({
					advisors: {
						advisor_id: advisor_id,
						filter: searchFindText,
						arrayFilter: filterArray
					}
				});
			} else {
				toggleSnackBar(dispatch,
					false,
					true,
					false,
					"The filter must be greater than 3 characters!",
					{},
					true,
				);
			}
		}, 1000);

		return () => clearTimeout(delayDebounceFn)
	}, [
		get,
		searchFindText,
		filterArray,
		dispatch,
		advisor_id,
	]);

	const columns = [
		{
			name: 'id',
			label: "ID",
			options: {
				display: false,
				filter: false,
			},
		},
		{
			name: 'title',
			label: "Employer",
			options: {
				display: true,
				filter: false,
				sort: false,
			},
		},
		{
			name: 'payroll_start_date',
			label: "PAYROLL START DATE",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
				filterOptions: {
					names: payroll_start_date,
					logic: (value, filters) => {
						return filters.some(filter => {
							return value.startsWith(filter);
						});
					},
					display: (filterList, onChange, index, column) => {
						return (
							<TextField
								variant="outlined"
								label={`Filter by ${column.label}`}
								value={filterList[index]}
								onChange={event => {
									filterList[index] = event.target.value;
									onChange(filterList, index, column);
								}}
							/>
						);
					}
				}
			},
		},
		{
			name: 'payroll_end_date',
			label: "PAYROLL END DATE",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
				filterOptions: {
					names: payroll_end_date,
					logic: (value, filters) => {
						return filters.some(filter => {
							return value.startsWith(filter);
						});
					},
					display: (filterList, onChange, index, column) => {
						return (
							<TextField
								variant="outlined"
								label={`Filter by ${column.label}`}
								value={filterList[index]}
								onChange={event => {
									filterList[index] = event.target.value;
									onChange(filterList, index, column);
								}}
							/>
						);
					}
				}
			},
		},
		{
			name: 'sum',
			label: "TOTAL AMOUNT",
			options: {
				filter: true,
				sort: true,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
				customBodyRender: (value) => parseFloat(value).toLocaleString("en-GB", {
					style: "currency",
					currency: "GBP",
				})
			},
		},
		{
			name: 'employees',
			label: "Number of Employees",
			options: {
				filter: false,
				sort: false,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			},
		},
		{
			name: 'employer_contributions_amount',
			label: "Employer Contributions",
			options: {
				filter: false,
				sort: false,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
				customBodyRender: (value) => parseFloat(value).toLocaleString("en-GB", {
					style: "currency",
					currency: "GBP",
				})
			},
		},
		{
			name: 'employee_contributions_amount',
			label: "Employee Contributions",
			options: {
				filter: false,
				sort: false,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
				customBodyRender: (value) => parseFloat(value).toLocaleString("en-GB", {
					style: "currency",
					currency: "GBP",
				})
			},
		},
		{
			name: 'additional_voluntary_contributions_amount',
			label: "Additional Voluntary Contributions",
			options: {
				filter: false,
				sort: false,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
				customBodyRender: (value) => parseFloat(value).toLocaleString("en-GB", {
					style: "currency",
					currency: "GBP",
				})
			},
		},
		{
			name: 'details',
			label: "DETAILS",
			options: {
				filter: false,
				sort: true,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
				customBodyRender: (value, tableMeta) => _renderDownloadIcon(tableMeta, profile_data, download)
			},
		},
	];

	return (
		<Grid>
			{
				(isLoading) ? (
					<Grid
						xs={12}
						lg={12}
						md={12}
						sm={12}
						container
						item
						alignContent={"center"}
					>
						<LoadingPanel/>
					</Grid>
				) : (
					<Grid>
						<MUIDataTable
							className={"data-table payments-table"}
							columns={columns}
							data={apiUploads?.data ?? []}
							options={{
								filter: true,
								selectableRowsHeader: false,
								selectableRows: "none",
								responsive: "simple",
								viewColumns: false,
								enableNestedDataAccess: ".",
								serverSide: true,
								count: apiUploads?.total,
								page: apiUploads?.current_page - 1 || 0,
								rowsPerPage: rowsPerPage,
								onChangeRowsPerPage: (numberOfRows: number) => {
									setRowsPerPage(numberOfRows);
									get({
										advisors: {
											advisor_id: advisor_id,
											filter: searchFindText,
											arrayFilter: filterArray
										},
									}, {
										perPage: numberOfRows,
										page: actualPage,
									});
								},
								onChangePage: (currentPage: number) => {
									get({
										advisors: {
											advisor_id: advisor_id,
											filter: searchFindText,
											arrayFilter: filterArray
										},
									}, {
										perPage: rowsPerPage ?? 10,
										page: currentPage + 1,
									});
									setActualPage(currentPage);
								},
								onColumnSortChange: (changedColumn: string) => {
									setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
									switch (changedColumn) {
										case "title":
										case "payroll_start_date":
										case "payroll_end_date":
										case "sum":
											get({
												advisors: {
													advisor_id: advisor_id,
													sort: [
														true,
														changedColumn,
														sortDirection
													],
													filter: searchFindText,
													arrayFilter: filterArray
												},
											}, {
												perPage: rowsPerPage ?? 10,
												page: actualPage
											});
											break;
										default:
											break;
									}
								},
								onSearchChange: (searchText) => {
									if (searchText !== null && searchText.includes("'")) {
										setSearchFindText(searchText.replace(/'/g, "''" ?? ""));
									} else {
										setSearchFindText(searchText ?? "");
									}
								},
								onDownload: () => {
									downloadAllApiUpload(advisor_id);
									return false;
								},
								onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
									if (type === "reset") {
										setFilterArray({});
									} else {
										setFilterArray({
											...filterArray,
											[changedColumn]: filterList[changedColumnIndex][0]
										});
									}
								},
							}}
							pagination
							autoHeight
						/>
					</Grid>
			)}
		</Grid>
	);
}

export default ApiUploadTable;