import {connect} from "react-redux";
import React, {useEffect} from "react";

import SoftwareTablePanel from "./SoftwareTablePanel";
import Wrapper from "../../layout/Logged/Wrapper";

import {me} from "../../actions/Profile";
import Grid from "@mui/material/Grid";
import ContentGrid from "../../layout/ContentGrid";
import Typography from "@mui/material/Typography";
import LoadingPanel from "../../components/LoadingPanel"

import {get, update} from "../../actions/Softwares";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Softwares: JSX.Element = (props) => {
	const {
		history,
		me = () => {
		},
		update = (id, data) => {
		},
		isLoading,
		softwareList = [],
	} = props;


	useEffect(() => me(), [
		me,
	]);

	return (
		<Wrapper history={history}>
			{(isLoading)
				? <LoadingPanel/>
				: <>
					<Grid className={"main-page-container-wrapper container-wrapper"}>
						<ContentGrid
							direction={"row"}
							alignItems={"flex-start"}
							alignContent={"center"}
							id={"profile-header-page-container-my-users"}
							className={"container-wrapper"}
							item
							container
						>
							<Grid
								xs={8}
								sm={8}
								md={8}
								lg={8}
								justifyContent={"center"}
								direction={"column"}
								container
								item
							>
								<Typography
									variant={"subtitle1"}
									className={"hello-individual"}
									align={"center"}
								>
									Welcome, {props?.profile_data?.title}!
								</Typography>
								<Typography
									component={"p"}
									variant={"caption"}
									className={"this-is-your-retirement-story"}
									align={"center"}
								>
									Here you can manage your connected softwares.
								</Typography>
							</Grid>
						</ContentGrid>
					</Grid>

					<div className={"dark-bg-container"}>
						<Grid className={"container-wrapper"}>
							<SoftwareTablePanel
								softwareList={softwareList}
								update={update}
							/>
						</Grid>
					</div>
				</>
			}
		</Wrapper>
	);
};

export default connect(
	({
		 Profile = {},
		 Softwares = {},
	 }) => ({
		...Profile,
		...Softwares,
	}),
	dispatch => ({
		me: () => dispatch(me()),
		get: dispatch(get()),
		update: (id, data) => dispatch(update(id, data)),
	})
)(Softwares);
