import React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import MUIDataTable from "mui-datatables";
import TableCellItem from "../TableCellItem";


/**
 * @returns {JSX.Element}
 * @constructor
 */
const Loading: JSX.Element = () => (
	<Box justifyContent="center" display="flex" padding={4}>
		<CircularProgress/>
	</Box>
);

/**
 * @param tableMeta
 * @returns {JSX.Element}
 */
const renderEmployerId: JSX.Element = (tableMeta) => (
	<TableCellItem
		title={tableMeta.rowData[0]}
	/>
);

/**
 * @param tableMeta
 * @returns {JSX.Element}
 */
const renderEmployerName: JSX.Element = (tableMeta) => (
	<TableCellItem
		subtitle={tableMeta.rowData[1]}
	/>
);

/**
 * @param tableMeta
 * @returns {JSX.Element}
 */
const renderIndividualForeName: JSX.Element = (tableMeta) => (
	<TableCellItem
		subtitle={tableMeta.rowData[2]}
	/>
);

/**
 * @param tableMeta
 * @returns {JSX.Element}
 */
const renderIndividualSurName: JSX.Element = (tableMeta) => (
	<TableCellItem
		subtitle={tableMeta.rowData[3]}
	/>
);

/**
 * @param tableMeta
 * @returns {JSX.Element}
 */
const renderIndividualNin: JSX.Element = (tableMeta) => (
	<TableCellItem
		subtitle={tableMeta.rowData[4]}
	/>
);

const columns = [
	{
		name: 'company_number',
		label: "COMPANY NUMBER",
		options: {
			filter: true,
			sort: true,
			setCellProps: () => ({style: {width: "190px"}}),
			customBodyRender: (value, tableMeta) => renderEmployerId(tableMeta)
		},
	},
	{
		name: 'title',
		label: "COMPANY NAME",
		options: {
			filter: true,
			sort: true,
			setCellProps: () => ({style: {width: "170px"}}),
			customBodyRender: (value, tableMeta) => renderEmployerName(tableMeta)
		},
	},
	{
		name: 'forename',
		label: "FORENAME",
		options: {
			filter: true,
			sort: true,
			setCellProps: () => ({style: {width: "100px"}}),
			customBodyRender: (value, tableMeta) => renderIndividualForeName(tableMeta)
		},
	},
	{
		name: 'surname',
		label: "SURNAME",
		options: {
			filter: true,
			sort: true,
			setCellProps: () => ({style: {width: "100px"}}),
			customBodyRender: (value, tableMeta) => renderIndividualSurName(tableMeta)
		},
	},
	{
		name: 'national_insurance_number',
		label: "NATIONAL INSURANCE NUMBER",
		options: {
			filter: true,
			sort: true,
			setCellProps: () => ({style: {width: "300px", textAlign: "center"}}),
			customBodyRender: (value, tableMeta) => renderIndividualNin(tableMeta)
		},
	},
	{
		name: "status",
		label: "STATUS",
		options: {
			filter: false,
			sort: true
		}
	},
	{
		name: 'inactivation_date',
		label: "INACTIVATION DATE",
		options: {
			filter: true,
			sort: true,
			setCellProps: () => ({style: {width: "180px", textAlign: "center"}})
		},
	},
];

/**
 * @returns {JSX.Element}
 * @constructor
 */
const OptOutTab: JSX.Element = (
	{
		myIndividualsOptList = [],
	}: {
		myIndividualsOptList: [],
	}
): JSX.Element => {
	let isLoading = false;
	return (
		<div>
			{isLoading ? (
				<Loading/>
			) : (
				<div>
					<MUIDataTable
						className={"data-table optout-table"}
						columns={columns}
						data={myIndividualsOptList}
						options={{
							filter: false,
							selectableRowsHeader: false,
							selectableRows: "none",
							responsive: "simple",
							viewColumns: false,
						}}
						pagination
					/>
				</div>
			)}
		</div>
	);
}

export default OptOutTab;