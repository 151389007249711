import {ActionCreator} from "redux";
import * as SnackBar from "../SnackBar";
import {warnAPIStatus} from "../SnackBar";
import * as types from "./types";
import {
	EMPLOYER_UPLOAD_FAILURE,
	EMPLOYER_UPLOAD_SET_TRIGGER,
	EMPLOYER_UPLOAD_STARTED,
	EMPLOYER_UPLOAD_SUCCESS,
	EMPLOYERS_ADD_MANUALLY,
	EMPLOYERS_DELETE,
	EMPLOYERS_LIST_FAILURE,
	EMPLOYERS_LIST_STARTED,
	EMPLOYERS_LIST_SUCCESS,
	EMPLOYERS_VERIFY_EMAIL,
	FETCH_STAGING_DATE_API,
	GENERATE_COMPANY_NUMBER,
	BULK_EMPLOYERS_UPLOAD_FAILURE,
	BULK_EMPLOYERS_UPLOAD_STARTED,
	BULK_EMPLOYERS_UPLOAD_SUCCESS,
	DELETE_PAST_UPLOAD_FAILED,
	DELETE_PAST_UPLOAD_STARTED,
	DELETE_PAST_UPLOAD_SUCCESS,
	GET_BULK_EMPLOYERS_HISTORY_FAILED,
	GET_BULK_EMPLOYERS_HISTORY_STARTED,
	GET_BULK_EMPLOYERS_HISTORY_SUCCESS,
	GET_BULK_EMPLOYERS_UPLOAD_DETAILS_STARTED,
	GET_BULK_EMPLOYERS_UPLOAD_DETAILS_SUCCESS,
	GET_COMMUNICATIONS,
} from "./types";
import * as Popup from "../Popup"
import EmployerService from "../../services/Employer"
import {requestNewLogin} from "../Profile"
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import {setLoading} from "../SignUp";
import {SEND_EMAIL} from "../SignUp/type";
import BulkEmployersService from "../../services/BulkEmployers";
import axios from "axios";

/**
 * @param triggerUpload
 * @returns {{triggerUpload: Boolean, type: string}}
 */
export const setTriggerUpload: ActionCreator = (
	triggerUpload: Boolean = true
) => ({
	type: EMPLOYER_UPLOAD_SET_TRIGGER,
	triggerUpload,
});

/**
 * @param data
 * @returns {function(*): (Promise<never>|undefined)}
 */
export const insert = (data = false) => (dispatch) => {
	if (!data) {
		dispatch(SnackBar.warn("No file provided."))
		return new Promise.reject("No file provided.")
	}

	dispatch({type: EMPLOYER_UPLOAD_STARTED})

	let reader = new FileReader();
	reader.onload = (e) => {
		data.papdisFile.blob = e.target.result
	}
	reader.readAsText(data.papdisFile);

	const formData = new FormData();
	formData.append("employer", data.employer);
	formData.append("papdisFile", data.papdisFile);

	new EmployerService()
		.insert(formData)
		.then((data) => {
			switch (data.status) {
				case 201:
					dispatch({type: EMPLOYER_UPLOAD_SUCCESS});
					break

				case 400: // Wrong Data
					// dispatch toastError(data.message)
					dispatch(
						Popup.show(
							data.message ? JSON.stringify(data.message) : "Unknown error"
						)
					);
					dispatch({type: EMPLOYER_UPLOAD_FAILURE, message: data.message});
					break

				case 500: // Something went wrong
					// dispatch toastError(data.message)
					dispatch(
						Popup.show(
							data.message ? JSON.stringify(data.message) : "Unknown error"
						)
					);
					dispatch({type: EMPLOYER_UPLOAD_FAILURE, message: data.message});
					break;

				default:
					//dispatch(SnackBar.warnAPIStatus(data.code));
					dispatch(Popup.show("Something went wrong uploading your file"));
					dispatch({
						type: EMPLOYER_UPLOAD_FAILURE,
						message: "Something went wrong",
					});
					break;
			}

			dispatch(
				SnackBar.success(
					"Your uploaded is started... You can leave this page."
				)
			);
		})
		.catch((error) => {
			if (error.response.status === 401) dispatch(requestNewLogin());

			console.error(error);
			dispatch({type: EMPLOYER_UPLOAD_FAILURE});

			if (error.data) SnackBar.error(error.data.message || "Unknown error");

			dispatch(
				Popup.showText(
					error.message ? JSON.stringify(error.message) : "Unknown error"
				)
			);
		})
		.then(() => dispatch(getEmployerList()));
};

/**
 * @param data
 * @param queryString
 * @returns {(function(*): void)|*}
 */
export const getEmployerList = (data = {}, queryString = {}) => dispatch => {
	dispatch({
		type:
		EMPLOYERS_LIST_STARTED,
		filter: data?.advisors?.filter ?? "",
		employers: {
			list: []
		}
	});

	new EmployerService()
		.getEmployerList(data, queryString)
		.then(({data}) => {
			const employers = data?.data;
			switch (data.code) {

				case 200:
					dispatch({type: EMPLOYERS_LIST_SUCCESS, employers});
					break;

				default:
					dispatch(warnAPIStatus(data.code, EMPLOYERS_LIST_FAILURE));
					break;
			}
		})
		.catch(data => {
			dispatch({type: EMPLOYERS_LIST_FAILURE});
			console.error(data);
		});
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const downloadEmployerList = (data) => dispatch => {
	new EmployerService()
		.downloadEmployerList(data)
		.then(response => {
			if (response?.data?.data) {
				const url = window.URL.createObjectURL(new Blob([response.data.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'Employers.csv');
				document.body.appendChild(link);
				link.click();
			}

		})
		.catch(data => {
			console.error(data);
		});
};

/**
 * @param employer_id
 * @returns {(function(*): void)|*}
 */
export const viewCompany = (employer_id: any = null) => dispatch => {
	if (employer_id) {
		new EmployerService()
			.viewCompany(employer_id ?? null)
			.then(response => {
				if (response?.data?.data) {
					window.open(
						process.env.REACT_APP_EMPLOYER_APP + "login/" + response?.data?.data,
						'_blank'
					);
					localStorage.clear();
					dispatch({
						type: SEND_EMAIL,
						send_email: true
					})
					dispatch(setLoading(false));
				}
			})
			.catch(data => {
				dispatch({type: types.EMPLOYERS_LIST_FAILURE});
				console.error(data);
			});
	}
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const deleteEmployer = (data) => dispatch => {
	if (data) {
		dispatch({
			type: EMPLOYERS_DELETE,
			isLoading: true,
		});
		new EmployerService()
			.deleteEmployer(data)
			.then(() => window.location.href = "/")
			.then(({data, status}) => {
				switch (status) {
					case 200:
						dispatch({type: EMPLOYERS_DELETE, data});
						break;
					default:
				}
			})
			.catch(error => console.error(error));
	}
};

export const generateCompanyNumber = () => dispatch => {
	dispatch({
		type: GENERATE_COMPANY_NUMBER,
		generated_company_number: "",
	});
	new EmployerService()
		.generateCompanyNumber()
		.then(response => dispatch({
			type: GENERATE_COMPANY_NUMBER,
			generated_company_number: response?.data?.data ?? "",
		}))
		.catch(error => console.error(error));
};

/**
 * @param data
 * @param is_setup_by_advisor
 * @param history
 */
export const addCompanyManually = (data = {}, is_setup_by_advisor, history = {
	push: () => {
	},
}) => dispatch => {
	if (data && history) {
		dispatch(setLoading(true));
		new EmployerService()
			.addCompanyManually(data)
			.then(async response => {
				if (response?.data?.code === 201) {
					await slackMessage(
						data?.advisors?.deal_owner,
						"Employer",
						data?.advisors?.title,
						data?.advisors?.primary_email,
						data?.advisors?.advisor,
					);
					if (is_setup_by_advisor) {
						dispatch(viewCompany(response?.data?.data?.id));
						history("/success", {
							state: {
								send_email: true,
								exists: false,
								company_title: data.advisors.title,
							}
						});
					} else {
						dispatch(setLoading(false));
						history("/success", {
							state: {
								send_email: true,
								exists: false,
								company_title: data.advisors.title,
							}
						});
					}
				}
			})
			.catch((error) => {
				const errors = error.response;

				if (errors) {
					let msg = (
						<ul>
							{Object.entries((errors))
								.map((error, index) => (<li key={index}>{error[1][0]}</li>))}
						</ul>
					);

					dispatch(setLoading(false));

					dispatch(({type: EMPLOYERS_ADD_MANUALLY}));
					dispatch(Popup.showErrors([{"message": msg}]));
				}


				console.error(error);
			});
	}
};

/**
 * @param data
 * @param setStagingDate
 * @param setSearchText
 */
export const getStagingDateByApiAction = (data: any[] = false, setStagingDate, setSearchText) => dispatch => {
	if (retrieveCollegiaAccessToken()) {
		dispatch({
			type: FETCH_STAGING_DATE_API,
			payer_loading: true,
		});

		new EmployerService()
			.getStagingDateByApiService(data)
			.then(response => {
				let auxDate = response?.data?.message?.stagingDate.split("-");
				setStagingDate(auxDate[2] + "/" + auxDate[1] + "/" + auxDate[0]);
				setSearchText(false);
			})
			.catch(() => {
				setSearchText(false);
				dispatch(SnackBar.error("Unable to find staging date for this tax office number!"));
			});
	}
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const verifyEmail = (data = {}) => dispatch => {
	if (data) {
		dispatch(({type: EMPLOYERS_ADD_MANUALLY}));
		dispatch({
			type: EMPLOYERS_VERIFY_EMAIL,
			emailVerified: false,
			canProceed: false,
			emailVerifyErrorMessages: [],
		});

		new EmployerService()
			.verifyPrimaryEmail(data)
			.then(response => {
				dispatch(({type: EMPLOYERS_ADD_MANUALLY}));
				dispatch({
					type: EMPLOYERS_VERIFY_EMAIL,
					emailVerified: true,
					canProceed: response?.data?.data,
					emailVerifyErrorMessages: [],
				});
			})
			.catch(error => {
				if (error.response.status === 401) {
					dispatch(requestNewLogin());
				}

				dispatch(({type: EMPLOYERS_ADD_MANUALLY}));
				dispatch({
					type: EMPLOYERS_VERIFY_EMAIL,
					emailVerified: true,
					canProceed: false,
					emailVerifyErrorMessages: error?.response?.data?.data,
				});
				console.error(error);
			})
	}
};

/**
 *
 * @param data
 * @param setDisableButton
 */
export const confirmOptOutContribution = (data: any[] = false, setDisableButton) => dispatch => {
	if (data) {
		setDisableButton(true);
		new EmployerService()
			.confirmOptOutContribution(data)
			.then(() => {
				setDisableButton(false);
				window.location.reload();
			})
			.catch(error => {
				setDisableButton(false);
				console.error(error);
			});
	}
};

export const getBulkEmployersUploadDetails = (file_upload_id) => dispatch => {
	dispatch({
		type: GET_BULK_EMPLOYERS_UPLOAD_DETAILS_STARTED
	});

	new BulkEmployersService()
		.getBulkEmployersUploadDetails(file_upload_id)
		.then(response => {
			dispatch({
				type: GET_BULK_EMPLOYERS_UPLOAD_DETAILS_SUCCESS,
				bulk_employers_details: response?.data?.data
			});
		})
		.catch(error => console.error(error));
};

export const getBulkEmployersHistory = (advisor_id = 0, page_index = 1) => dispatch => {
	dispatch({type: GET_BULK_EMPLOYERS_HISTORY_STARTED});

	new EmployerService()
		.getBulkEmployersHistory({advisors: {advisor_id}}, page_index)
		.then(({data}) => {
			dispatch({
				type: GET_BULK_EMPLOYERS_HISTORY_SUCCESS,
				bulkEmployersHistory: data.data
			});
		})
		.catch(error => {
			dispatch({type: GET_BULK_EMPLOYERS_HISTORY_FAILED});
			console.error(error);
		});
};

export const uploadBulkEmployers = (data) => dispatch => {
	if (!data) {
		dispatch(SnackBar.warn("No file provided."));
		return new Promise.reject("No file provided.");
	}

	dispatch({type: BULK_EMPLOYERS_UPLOAD_STARTED});

	let reader = new FileReader();
	reader.onload = (e) => {
		data.bulkEmployersFile.blob = e.target.result;
	}
	reader.readAsText(data.bulkEmployersFile);

	const formData = new FormData();
	formData.append('bulkEmployersFile', data.bulkEmployersFile);
	formData.append('advisor', data.advisor_id);

	new EmployerService()
		.bulkEmployers(formData)
		.then((response) => {
			switch (response.status) {
				case 201:
					dispatch({type: BULK_EMPLOYERS_UPLOAD_SUCCESS});
					dispatch(SnackBar.success("File upload started!"));
					dispatch(getBulkEmployersHistory(data.advisor_id, 1));
					break;

				case 400: // Wrong Data
					dispatch(
						Popup.show(
							response.message ? JSON.stringify(response.message) : "Unknown error"
						)
					);
					dispatch({type: BULK_EMPLOYERS_UPLOAD_FAILURE, message: response.message});
					break;

				case 500: // Something went wrong
					dispatch(({type: BULK_EMPLOYERS_UPLOAD_FAILURE, message: response.message}));
					break;

				default:
					dispatch(Popup.show([{"message": "File format not supported. Please refer to CSV model."}]));
					dispatch({
						type: BULK_EMPLOYERS_UPLOAD_FAILURE,
						message: "Something went wrong",
					});
					break;
			}
		})
		.catch((error) => {
			dispatch(({type: BULK_EMPLOYERS_UPLOAD_FAILURE}))
			dispatch(Popup.show([{"message": "File format not supported. Please refer to CSV model."}]));
			console.error(error);
		});
};

/**
 *
 * @param id
 * @param advisor_id
 */
export const deletePastUpload = (id: number, advisor_id: number) => dispatch => {
	if (id) {
		dispatch({type: DELETE_PAST_UPLOAD_STARTED});
		new EmployerService()
			.deletePastUpload(id)
			.then(() => {
				dispatch({type: DELETE_PAST_UPLOAD_SUCCESS});
				dispatch(getBulkEmployersHistory(advisor_id, 1));
			})
			.catch((error) => {
				dispatch({type: DELETE_PAST_UPLOAD_FAILED});
				console.error(error);
			});
	}
};

/**
 * @returns {(function(*): void)|*}
 * @param dealOwner
 * @param typeOfSignUp
 * @param title
 * @param email
 * @param advisor
 */
async function slackMessage(dealOwner, typeOfSignUp, title, email, advisor = "") {
	let webHookUrl, slackData, environment;

	if (process.env.REACT_APP_ENV === 'production') {
		environment = "PROD";
		webHookUrl = "https://hooks.slack.com/services/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";

	} else {
		environment = "DEV";
		webHookUrl = "https://hooks.slack.com/services/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";
	}

	slackData = {
		"text": `A new account created in ${environment}!\n` +
			`Type: ${typeOfSignUp}\n` +
			`Company Name: ${title}\n` +
			`E-mail: ${email}\n` +
			`Deal Owner: ${dealOwner}\n` +
			`Advisor: ${advisor}`
	};

	await axios.post(webHookUrl, JSON.stringify(slackData), {
		withCredentials: false,
		transformRequest: [(slackData, headers) => {
			if (headers) {
				if (headers.post) {
					delete headers.post["Content-Type"];
				}
			}
			return slackData;
		}]
	});

};

/**
 * @returns {(function(*): void)|*}
 */
export const getCommunications = (data) => dispatch => {
	dispatch({
		type: GET_COMMUNICATIONS,
		is_loading: true,
	});

	new EmployerService()
		.getCommunications(data)
		.then(response => dispatch({
			type: GET_COMMUNICATIONS,
			is_loading: false,
			communications: response.data.data,
		}))
		.catch(error => {
			dispatch({
				type: GET_COMMUNICATIONS,
				is_loading: false,
			});
			console.error(error);
		});
};

/**
 * @returns {(function(*): void)|*}
 */
export const getEmployeeCommunications = (data) => dispatch => {
	dispatch({
		type: GET_COMMUNICATIONS,
		is_loading: true,
	});

	new EmployerService()
		.getEmployeeCommunications(data)
		.then(response => dispatch({
			type: GET_COMMUNICATIONS,
			is_loading: false,
			employees_communications: response.data.data,
		}))
		.catch(error => {
			dispatch({
				type: GET_COMMUNICATIONS,
				is_loading: false,
			});
			console.error(error);
		});
};