import { HIDE_SNACK_BAR, TRIGGER_SNACK_BAR } from "./types";
import { ActionCreator } from 'redux'

/**
 * @param message
 * @returns Action
 */
export const trigger = (message: String = ""): ActionCreator => ({
    type: TRIGGER_SNACK_BAR,
    message,
})

/**
 * @returns Action
 */
export const hide = () => ({ type: HIDE_SNACK_BAR })

/**
 * @returns Action
 */
export const info: ActionCreator = (message = "") => ({
    type: TRIGGER_SNACK_BAR,
    severity: "info",
    message,
})

/**
 * @returns Action
 */
export const warn: ActionCreator = (message = "") => ({
    type: TRIGGER_SNACK_BAR,
    severity: "warning",
    message,
})

/**
 * @returns Action
 */
export const success: ActionCreator = (message = "") => ({
    type: TRIGGER_SNACK_BAR,
    severity: "success",
    message,
})

/**
 * @returns Action
 */
export const error: ActionCreator = (message = "") => ({
    type: TRIGGER_SNACK_BAR,
    severity: "error",
    message,
})


/**
 * @returns Action
 */
export const warnAPIStatus: ActionCreator = (code = 0, failureActionType = false) => dispatch => {
    console.warn(`Unrecognized code: ${code}`)
    if (failureActionType) dispatch({ type: failureActionType })
    // TODO: Change message in production
    return ({
        type: TRIGGER_SNACK_BAR,
        message: `API response code: ${code}`,
        open: true,
    })
}