import React from "react";
import {connect} from "react-redux";

// importing material ui icons.
import {
	AppBar,
	IconButton,
	Toolbar,
	Typography,
	Grid,
	Link,
	Badge,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import clsx from "clsx";

// importing the default images.
import logo from "../../../images/logo-icon.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationProvider from "../../../components/NotificationProvider";

/**
 * @param notifications
 * @param handleNotificationsOpen
 * @param open
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderIconButton = (notifications = [], handleNotificationsOpen = () => {
}, open): JSX.Element | *[] => {
	if (notifications?.notifications?.length) {
		return (
			<IconButton
				color={"inherit"}
				aria-label={"open drawer"}
				edge={"end"}
				onClick={event => ((notifications.notifications || [])?.length && handleNotificationsOpen(event.currentTarget))}
				className={clsx(open && 'hide')}
				style={{marginRight: "2%"}}
			>
				{(notifications.notifications || [])?.filter(x => !x.visualized).length
					?
					<Badge
						color={"error"}
						variant={"dot"}
					>
						<NotificationsIcon/>
					</Badge>
					: <NotificationsNoneIcon/>
				}
			</IconButton>
		);
	}
	return [];
}

/**
 * @param profile_data
 * @param classes
 * @param open
 * @param handleDrawer
 * @param handleNotificationsOpen
 * @param showSandwich
 * @param notifications
 * @param isIFA
 * @returns {JSX.Element}
 * @constructor
 */
const AppBarHeader = (
	{
		profile_data = null,
		classes = {},
		open = false,
		handleDrawer = () => {
		},
		handleNotificationsOpen = () => {
		},
		showSandwich = true,
		notifications = [],
		isIFA = null,
	}
): JSX.Element => {

	let linkHeader = "";

	if (isIFA) {
		linkHeader = '/ifa-dashboard';
	} else {
		linkHeader = '/main-page';
	}

	return (
		<AppBar
			position={"static"}
			id={"menu-logged"}
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open,
			})}
		>
			<NotificationProvider>
				<Toolbar>
					<Grid
						xs={12}
						ld={12}
						md={12}
						alignContent={"center"}
						alignItems={"center"}
						direction={"row"}
						item
						container
					>
						<Grid
							md={6}
							lg={6}
							xs={6}
							alignContent={"flex-start"}
							alignItems={"flex-start"}
							direction={"column"}
							container
							item
						>
							<Link href={linkHeader}>
								<Typography variant={"h6"} noWrap className={"title"}>
									<img
										src={logo}
										alt={"Collegia logo."}
									/>
								</Typography>
							</Link>
						</Grid>
						<Grid
							className={"advisor-header-title"}
							md={5}
							lg={5}
							xs={5}
							alignContent={"flex-end"}
							alignItems={"flex-end"}
							direction={"column"}
							container
							item
						>
							{profile_data?.title}
						</Grid>
						{
							showSandwich &&
							<Grid
								md={1}
								lg={1}
								xs={1}
								alignContent={"center"}
								alignItems={"center"}
								justifyContent={"flex-end"}
								direction={"row"}
								container
								item
							>
								{
									profile_data?.notifications?.length > 0 ?
										""
										:
										_renderIconButton(notifications, handleNotificationsOpen, open)
								}
								{_renderIconButton(profile_data, handleNotificationsOpen, open)}
								<IconButton
									color={"inherit"}
									aria-label={"open drawer"}
									edge={"end"}
									onClick={handleDrawer}
									className={clsx(open && 'hide')}
								>
									<MenuIcon/>
								</IconButton>
							</Grid>
						}
					</Grid>
				</Toolbar>
			</NotificationProvider>
		</AppBar>
	);
};

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default connect(state => ({notifications: state.Notifications}))(AppBarHeader);