import GetByCode from "./GetByCode";
import Authorize from './Authorize';
import Deny from "./Deny";

/**
 * @type {{GetByCode: (function(string=): Promise<AxiosResponse<*>>)}}
 */
const AuthorizationRequests = {
    GetByCode,
    Authorize,
    Deny,
};

export default AuthorizationRequests;