import {connect} from 'react-redux';
import {logout} from "../actions/Profile";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const LogoutScreen = (): JSX.Element => {

	window.$crisp.push(["do", "session:reset"]);

	return (
		<Grid
			xs={12}
			md={12}
			lg={12}
			direction={"row"}
			alignItems={"center"}
			alignContent={"center"}
			justifyContent={"center"}
			container
			item
		>
			<Grid
				xs={12}
				md={12}
				lg={12}
				justifyContent={"center"}
				container
				item
			>
				<Typography variant={"h4"}>
					You just logged out
				</Typography>
			</Grid>

			<Grid
				xs={12}
				md={12}
				lg={12}
				justifyContent={"center"}
				item
				container
			>
				<Typography>
					You will be redirected to the login page shortly
				</Typography>
			</Grid>
		</Grid>
	)
}

export default connect(null, dispatch => ({
	logout: dispatch(logout())
}))(LogoutScreen);