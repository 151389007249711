import Request from "../../../utils/Request"
import { AxiosResponse } from "axios"

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const EmployerIfaAccess = (data:any[] = false): Promise<AxiosResponse<T>> => {
	return Request.post("/advisors/employers_ifa/ifa-access", data);
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default EmployerIfaAccess
