import {ActionCreator} from "redux";
import {
	TRIGGER_EMPLOYER_NOTIFICATION_ALERT,
	HIDE_EMPLOYER_NOTIFICATION_ALERT
} from "./types";

/**
 * @returns Action
 */
export const error: ActionCreator = (message) => ({
	type: TRIGGER_EMPLOYER_NOTIFICATION_ALERT,
	severity: "error",
	message,
});

/**
 * @returns Action
 */
export const success: ActionCreator = (message = "") => ({
	type: TRIGGER_EMPLOYER_NOTIFICATION_ALERT,
	severity: "success",
	message,
})

/**
 * @returns Action
 */
export const hide = () => ({type: HIDE_EMPLOYER_NOTIFICATION_ALERT});