import axios from 'axios';

const CancelToken = axios.CancelToken;
const isCancel = axios.isCancel;


export default class AbstractServiceRequestClass {
	/**
	 * @type {null}
	 * @private
	 */
	_request = null;

	/**
	 * @param request
	 */
	constructor(request) {
		if (request) this.request = request;
	}

	/**
	 * @returns {null}
	 */
	get request() {
		return this._request;
	}

	/**
	 * @param value
	 */
	set request(value) {
		this._request = value;
	}

	/**
	 * @returns {null|*}
	 */
	get(relationships = []) {
		if (this.request) {
			return this.request.Get(relationships);
		}

		return null;
	}

	/**
	 * @param id
	 * @returns {null|*}
	 */
	find(id = false) {
		if (this.request && id) {
			return this.request.Find(id);
		}

		return null;
	}

	/**
	 * @param data
	 * @param token
	 * @returns {null|*}
	 */
	insert(data = false, token:string = "") {
		if (this.request && data) {
			return this.request.Create(data, token ?? "");
		}

		return null;
	}

	/**
	 * @param id
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	update(id = false, data = false) {
		if (this.request && id && data) {
			return this.request.Update(id, data);
		}

		return null;
	}

	/**
	 * @param id
	 * @returns {null|*}
	 */
	delete(id = false) {
		if (this.request && id) {
			return this.request.Delete(id);
		}

		return null;
	}

	/**
	 * @param queryString
	 * @returns {null|*}
	 */
	search(queryString = false) {
		if (queryString) {
			return this.request.Search(queryString);
		}

		return null;
	}

	/**
	 * @returns {null|*}
	 */
	active() {
		if (this.request) {
			return this.request.Active();
		}

		return null;
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	login(data = false) {
		if (data && this.request) {
			return this.request.Login(data);
		}

		return null;
	}

	/**
	 * @param page
	 * @returns {Promise<AxiosResponse<*>>|null}
	 */
	paginate(page = 1): Promise<AxiosResponse<*>> | null {
		if (page && this.request) {
			return this.request.Paginate(page);
		}

		return null;
	}

	/**
	 * @param data
	 * @param token
	 * @returns {null|Promise<AxiosResponse<*>>|*}
	 */
	verifyEmail(data: any[] = false, token: string = ""): Promise<AxiosResponse<*>> | null {
		if (this.request && data) {
			return this.request.VerifyEmail(data ?? false, token ?? "");
		}

		return null;
	}

	/**
	 * @returns {*}
	 */
	static getCancelToken() {
		return CancelToken.source();
	}

	static isCancel = isCancel;
}