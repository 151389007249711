import React from "react";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

import PropTypes from "prop-types";

/**
 * @type {string[]}
 */
const yearMonths: string[] = [
	"January", "February", "March", "April", "May",
	"June", "July", "August", "September", "October",
	"November", "December"
];

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyOfficers = (props = {
	officers: [{
		officer_role: PropTypes.string,
		name: PropTypes.string,
		date_of_birth: PropTypes.shape({
			month: PropTypes.number,
			year: PropTypes.number
		}),
		birth_year: PropTypes.string
	}],
	companyExist: false
}): JSX.Element => (
	<div>
		<FormControl
			style={props?.companyExist ? {display: "block"} : {display: "none"}}
			className={"signup-form-control-2 confirm-title"}
			fullWidth
		>
			<Typography
				align={"left"}
				style={{fontWeight: "bold", fontSize: 16}}
			>
				Directors
			</Typography>
		</FormControl>

		<FormControl
			style={props?.companyExist ? {display: "block"} : {display: "none"}}
			className={"signup-form-control-2 confirm-description"}
			fullWidth
		>
			{props?.officers?.map((director, index) => (
				<Typography key={index} value={director}>
					{director?.officer_role === "director" && director?.birth_year !== "0" ?
						(director?.name + " (Date of Birth: " +
							yearMonths[director?.date_of_birth?.month - 1] + " " +
							director?.date_of_birth?.year + ")")
						: director?.name}
				</Typography>
			))}
		</FormControl>
	</div>
);

CompanyOfficers.propTypes = {
	officers: PropTypes.array.isRequired,
	companyExist: PropTypes.any,
};

export default CompanyOfficers;