import Request from "../../utils/Request";
import {AxiosResponse} from "axios";


/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>|Promise<never>}
 * @constructor
 */
const VerifyEmailRequest = (data = {}): Promise<AxiosResponse<any>>|Promise<never> =>
	Request?.post(`advisors/employer/verify-email`, data)
		??
	Promise?.reject(new Error('Request is not defined'));

export default VerifyEmailRequest;