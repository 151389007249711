import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetPayrollDates = (data: any[] = {}): Promise<AxiosResponse<any>> =>
	Request.get(`/advisors/get-payroll-dates/${data}`);

export default GetPayrollDates;