import * as types from "../../actions/Users/types";


const initial_state = {
	isLoading: false,
	isCreating: false,
	list: [],
	roles: {
		list: []
	},
	new_user: {},
	cardsLoading: []
}

const UsersReducer = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {


		case types.SET_CREATING_USER:
			return {
				...state,
				isCreating: action.value
			};


		case types.CREATE_USER_STARTED:
		case types.UPDATE_USER_STARTED:
			return {
				...state,
				...state.users,
				isLoading: true,
				error: null
			};

		case types.CREATE_USER_SUCCESS:
		case types.UPDATE_USER_SUCCESS:
			return {
				...state,
				isCreating: false,
				...state.users,
				isLoading: false
			};

		case types.CREATE_USER_FAILURE:
		case types.UPDATE_USER_FAILURE:
			return {
				...state,
				...state.users,
				isLoading: false,
				error: action.error
			};

		case types.UPDATE_USER_FIELD:
			return {
				...state,
				new_user: {
					...state.new_user,
					[action.field]: action.value
				}
			}

		case types.DELETE_USER_STARTED:
			return {...state, isLoading: true}

		case types.DELETE_USER_FAILURE:
			return {...state, isLoading: false}

		case types.EDIT_USER_STARTED :
			return {...state, isLoading: true}

		case types.EDIT_USER_FAILURE :
			return {...state, isLoading: false}

		case types.CHECK_SUPER_USER_STARTED :
			return {...state, isLoading: true}

		case types.CHECK_SUPER_USER_FAILURE  :
			return {...state, isLoading: false}

		default:
			return state;
	}
}

export default UsersReducer;
