import ContributionsService from "../../services/Contributions";

import * as SnackBar from "../SnackBar";
import {PAPDIS_GET_FAILURE, PAPDIS_GET_STARTED, PAPDIS_GET_SUCCESS} from "../PAPDIS/types";
import {requestNewLogin} from "../Profile";
import {CONTRIBUTIONS_GET_SUCCESS} from "./types";


/**
 * @param national_insurance_number
 * @param type
 * @param id
 * @param schedule_id
 * @param papdis_ids
 * @param isAdvisor
 * @returns {(function(*): void)|*}
 */
export const getEmployeeContributions = (
    national_insurance_number: string = "",
    type: string = "",
    id: number = 0,
    schedule_id = "",
    papdis_ids = [],
    isAdvisor = true,
) => dispatch => {
	dispatch({
		employees_contributions_loading: true,
		employee_contributions: [],
		type: PAPDIS_GET_STARTED,
	});

	if (national_insurance_number || id || schedule_id || papdis_ids.length > 0) {
		dispatch(SnackBar?.info("Loading PAPDIS data..."));

		new ContributionsService()
			?.getEmployeeContributions(
				national_insurance_number ?? "",
				type ?? "",
				id ?? null,
				schedule_id ?? null,
				papdis_ids ?? [],
				isAdvisor,
			)
			?.then(response => {
				let isContribution = false;

				if (schedule_id || papdis_ids.length > 0) {
					isContribution = true;
				}

				dispatch({
					type: CONTRIBUTIONS_GET_SUCCESS,
					employees_contributions_loading: false,
					employee_contributions: response.data.data,
					...response.data,
					isContribution,
				});
				dispatch(SnackBar?.success("contributions data loaded!"));
			})
			.catch(error => {
				if (error !== undefined) {
					if (error?.response?.status === 401) {
						dispatch(SnackBar?.error("You don't have permission to do that!"));
						requestNewLogin();
					}
					if (error?.data?.response?.status === 401) {
						dispatch(SnackBar.error("You don't have permission to do that!"));
						requestNewLogin();
					}
                    if (error?.data?.response?.status === 401) {
                        dispatch(SnackBar.error("Something wrong on request"));
                    }
				}
				dispatch({
					employees_contributions_loading: false,
					employee_contributions: [],
					type: PAPDIS_GET_FAILURE,
				});

                if (error.data) {
                    SnackBar.error(error.data.message || "Unknown error");
                }

                console.error(error);
            });
    }
}

/**
 * @returns {function(*): *}
 */
export const cleanEmployeeContributions = () => dispatch => (
    dispatch({
        employees_contributions_loading: false,
        employee_contributions: [],
        type: PAPDIS_GET_SUCCESS,
    })
);