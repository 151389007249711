import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const UpdateRequest = (id, data): Promise<AxiosResponse<any>> => {
	return Request.put(`/advisors/update/${id}`, data);
}

export default UpdateRequest;