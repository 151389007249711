import React from 'react';
import Grid from '@mui/material/Grid';

/** TODO: Import from external file
  * @param children
  * @param props
  */
const SectionGrid = ({
    children,
    ...props
}): JSX.Element => (
    <Grid
        xs={12}
        md={12}
        lg={12}
        direction={"column"}
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"center"}
        className={"section-grid"}
        item
        container
        {...props}
    >
        {children}
    </Grid>
);

export default SectionGrid;