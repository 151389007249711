import {
	POPUP_SHOW,
	POPUP_HIDE,
	POPUP_SET_TITLE,
} from './types';

export const hide = () => ({ type: POPUP_HIDE });
export const show = (content, buttons = []) => ({type: POPUP_SHOW, content, buttons});

export const setTitle = title => ({type: POPUP_SET_TITLE, title});

export const showText = message => ({ type: POPUP_SHOW, content: [
		{message}
	]
});

export const showUploadErrors = (errors = []) => dispatch => {
	dispatch(setTitle("How to fix"));
	dispatch(show([
		{format: "text", message: "The following errors were encountered: "},
		...errors.map(error => ({...error, format: 'code'})),
		{format: "text", message: "Please upload the file again"},
	]));
}

export const showErrors = (errors) => dispatch => {
	dispatch(setTitle("Errors"));
	dispatch(show([
		{format: "text", message: "The following errors were encountered: "},
		...errors.map(error => ({ ...error, format: 'code' })),
		{format: "text", message: "Please fix and try again"},
	]));
}
