import {resolveReduxState} from "../../../utils/Helpers"
import {
	CHANGE_FUNDS,
	CHANGE_FUNDS_ERROR,
	CHANGE_FUNDS_SUCCESS,
	GET_IFA_INDIVIDUALS,
	GET_IFA_INDIVIDUALS_ERROR,
	GET_IFA_INDIVIDUALS_SUCCESS,
	REQUEST_ACCESS,
	REQUEST_ACCESS_ERROR,
	REQUEST_ACCESS_SUCCESS
} from "../../../actions/IFA/IndividualsIFA/types";

const initial_state = {
	individuals_loading: false,
	total: 0,
	per_page: 15,
	error_message: "",
	canProceed: false,
	individuals: [],
	funds: [],
};

const IndividualIFAReducerStore = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case GET_IFA_INDIVIDUALS:
		case GET_IFA_INDIVIDUALS_SUCCESS:
		case CHANGE_FUNDS_SUCCESS:
		case CHANGE_FUNDS:
		case CHANGE_FUNDS_ERROR:
		case REQUEST_ACCESS:
		case REQUEST_ACCESS_ERROR:
		case REQUEST_ACCESS_SUCCESS:
		case GET_IFA_INDIVIDUALS_ERROR:
			return resolveReduxState(state, action);
		default:
			return state;
	}
}

export default IndividualIFAReducerStore;