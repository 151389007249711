import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import EmployersListView from "./EmployersListView";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";

const BootstrapDialog = styled(Dialog)(({theme}) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
	const {children, onClose, ...other} = props;

	return (
		<DialogTitle sx={{m: 0, p: 2}} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon/>
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

/**
 * @param employers
 * @param last_page
 * @param page
 * @param getEmployerList
 * @param handleToggle
 * @param setPage
 * @param setOpen
 * @param select_input_loading
 * @param open
 * @param checked
 * @param managedEmployers
 * @returns {JSX.Element}
 * @constructor
 */
export default function EmployersDialog(
	{
		employers = [],
		last_page = 1,
		page = 1,
		getEmployerList = () => false,
		handleToggle = () => false,
		setPage = (page) => page,
		setOpen = (open) => !open,
		select_input_loading = false,
		open = false,
		checked = [],
		managedEmployers = [],
		setChecked = () => {}
	},
) {
	const [searchText, setSearchText] = useState("");

	useEffect(() => {
		let timerId;
		if (searchText !== "" && searchText.length > 2) {
			timerId = setTimeout(() => {
				getEmployerList({
					perPage: 100,
					page,
				}, {
					advisors: {
						filter: searchText,
					}
				});
			}, 1000);
		} else {
			timerId = setTimeout(() => getEmployerList({
				perPage: 100,
				page,
			}), 1000);
		}

		return () => {
			clearTimeout(timerId);
		};
	}, [getEmployerList, page, searchText]);

	return (
		<Grid
			xs={12}
			md={12}
			lg={12}
			sm={12}
			xl={12}
			onScrollCapture={(event) => {
				if (!select_input_loading) {
					if (event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight) {
						page++;
						if (page > last_page) {
							page = 1;
						}
						setPage(page);
						getEmployerList({
							perPage: 100,
							page
						});
					}
				}
			}}
			item
		>
			<Button variant="outlined" onClick={() => setOpen(true)}>
				Managing employers
			</Button>
			<BootstrapDialog
				onClose={() => setOpen(false)}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={() => setOpen(false)}>
					Employers list
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<Typography gutterBottom>
						Here you can select all the employer(s) that the new Standard Account user can manage.
					</Typography>
					<Typography gutterBottom>
						If you do not select any employer, the Standard Account user will have visibility
						over all the employers associated with the advisor account.
					</Typography>
					<EmployersListView
						employers={employers}
						last_page={last_page}
						page={page}
						getEmployerList={getEmployerList}
						setPage={setPage}
						select_input_loading={select_input_loading}
						handleToggle={handleToggle}
						checked={checked}
						searchText={searchText}
						setSearchText={setSearchText}
						managedEmployers={managedEmployers}
						setChecked={setChecked}
					/>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={() => setOpen(false)}>
						Save changes
					</Button>
				</DialogActions>
			</BootstrapDialog>
		</Grid>
	);
}