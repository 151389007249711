import Request from "../../../utils/Request"
import { AxiosResponse } from "axios"

/**
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 * @param individual_id
 */
const ViewIndividualRequest = (individual_id:any = null): Promise<AxiosResponse<T>> => (
	Request.post(`/advisors/individuals_ifa/view-individual/${individual_id}`)
);

export default ViewIndividualRequest
