import Request from '../../utils/Request';
import {RETRIEVE_EMAIL_PREFERENCE_TYPES} from "./types";

export const getEmailPreferenceTypes = () => async (dispatch) => {
	Request.get('/advisors/get-email-preference-types')
		.then(response => dispatch({
			type: RETRIEVE_EMAIL_PREFERENCE_TYPES,
			emailPreferenceTypes: response.data.data
		}))
		.catch(error => console.error(error));
};