import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdatePassword = (data:any[] = false): Promise<AxiosResponse<any>> => {
	return Request.put(`/advisors/logged-update-password`, {
		advisors: {
			users_advisors_id: data.users_advisors_id,
			new_password: data.new_password,
			confirm_password: data.confirm_password,
		}
	});
}
export default UpdatePassword;