import {resolveReduxState} from "../../../utils/Helpers"
import {
	ADD_BENEFICIARY,
	ADD_BENEFICIARY_ERROR,
	ADD_BENEFICIARY_SUCCESS,
	GET_IFA_STATS,
	GET_IFA_STATS_ERROR,
	GET_IFA_STATS_SUCCESS

} from "../../../actions/IFA/IFA/types";

const initial_state = {
	stats: {
		total_aum: 0,
		total_indiv: 0,
		total_revenue: 0,
		total_companies: 0,
	},
	stats_loading: false,
};

const IFAReducerStore = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case GET_IFA_STATS:
		case GET_IFA_STATS_SUCCESS:
		case GET_IFA_STATS_ERROR:
		case ADD_BENEFICIARY:
		case ADD_BENEFICIARY_SUCCESS:
		case ADD_BENEFICIARY_ERROR:
			return resolveReduxState(state, action);
		default:
			return state;
	}
}

export default IFAReducerStore;