
// User Creation Flow
export const SET_CREATING_USER      = "USER.SET_CREATING_USER";
export const UPDATE_USER_FIELD      = "USER.UPDATE_USER_FIELD";
export const CREATE_USER_STARTED    = "USER.CREATE_USER.STARTED";
export const CREATE_USER_SUCCESS    = "USER.CREATE_USER.SUCCESS";
export const CREATE_USER_FAILURE    = "USER.CREATE_USER.FAILURE";

export const UPDATE_USER_STARTED    = "USER.UPDATE_USER.STARTED";
export const UPDATE_USER_SUCCESS    = "USER.UPDATE_USER.SUCCESS";
export const UPDATE_USER_FAILURE    = "USER.UPDATE_USER.FAILURE";

export const DELETE_USER_STARTED    = "USER.DELETE_USER.STARTED";
export const DELETE_USER_SUCCESS    = "USER.DELETE_USER.SUCCESS";
export const DELETE_USER_FAILURE    = "USER.DELETE_USER.FAILURE";

export const EDIT_USER_STARTED     = "USER.EDIT_USER.STARTED ";
export const EDIT_USER_SUCCESS   = "USER.EDIT_USER.SUCCESS";
export const EDIT_USER_FAILURE    = "USER.EDIT_USER.FAILURE";

export const CHECK_SUPER_USER_STARTED     = "USER.CHECK_SUPER_USER.STARTED ";
export const CHECK_SUPER_USER_FAILURE    = "USER.CHECK_SUPER_USER.FAILURE";

