import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const requestAccessToEmployer = (data): Promise<AxiosResponse<any>> => {
	return Request.post("/advisors/employer/request-access/", data);
}
/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default requestAccessToEmployer;