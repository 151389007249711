import {ActionCreator} from 'redux'
import PAPDISService from "../../services/PAPDIS";
import {
	PAPDIS_UPLOAD_STARTED,
	PAPDIS_UPLOAD_SUCCESS,
	PAPDIS_UPLOAD_FAILURE,
	PAPDIS_UPLOAD_SET_TRIGGER,
	PAPDIS_GET_STARTED,
	PAPDIS_GET_SUCCESS,
	PAPDIS_GET_FAILURE,
	PAPDIS_GET_UPLOADS_STARTED,
	PAPDIS_GET_UPLOADS_SUCCESS,
	PAPDIS_GET_UPLOADS_FAILURE,
	DELETE_PAST_PAPDIS_UPLOAD_STARTED,
	DELETE_PAST_PAPDIS_UPLOAD_SUCCESS,
	DELETE_PAST_PAPDIS_UPLOAD_FAILED,
	DOWNLOAD_PAPDIS_PAST_UPLOADS
} from "./types";

import * as SnackBar from "../SnackBar";
import * as Popup from "../Popup";
import {requestNewLogin} from "../Profile";
import * as Profile from "../Profile";
import FileDownload from "js-file-download";
import Request from "../../utils/Request";
/**
 * @param showSnackBar
 * @returns {function(*): void}
 */
export const get = (showSnackBar: boolean = true) => dispatch => {
	dispatch({
		papdis_loading: true,
		papdis: [],
		type: PAPDIS_GET_STARTED,
	});

	if (showSnackBar) {
		dispatch(SnackBar.info("Loading PAPDIS data..."));
	}

	new PAPDISService()
		.get()
		.then(response => {
			dispatch({
				papdis_loading: false,
				papdis: response.data.data,
				type: PAPDIS_GET_SUCCESS,
				...response.data
			});

			if (showSnackBar) {
				SnackBar.success("PAPDIS data loaded!");
			}
		})
		.catch(error => {
			if (error !== undefined) {
				if (error?.response?.status === 401) {
					dispatch(SnackBar.error("You don't have permission to do that!"));
					requestNewLogin();
				}
				if (error?.data?.response?.status === 401) {
					dispatch(SnackBar.error("You don't have permission to do that!"));
					requestNewLogin();
				}
				if (error?.data) {
					SnackBar.error(error.data.message || "Unknown error");
				}
			}
			dispatch({
				papdis_loading: false,
				papdis: [],
				type: PAPDIS_GET_FAILURE,
			});

			console.error(error);
		});
};

/**
 * @param data
 * @returns {function(*): (Promise<never>|undefined)}
 */
export const insert = (data = false) => dispatch => {
	if (!data) {
		dispatch(SnackBar.warn("No file provided."));
		return Promise?.reject("No file provided.");
	}

	dispatch({type: PAPDIS_UPLOAD_STARTED});

	var reader = new FileReader();
	reader.onload = e => {
		data.papdisFile.blob = e.target.result;
	}
	reader.readAsText(data.papdisFile);

	const formData = new FormData();
	formData.append("advisor", data.advisor);
	formData.append("papdisFile", data.papdisFile);

	new PAPDISService()
		.insert(formData)
		.then(data => {
			switch (data.status) {
				case 201:
					dispatch({type: PAPDIS_UPLOAD_SUCCESS});
					dispatch(Profile.me())
					break;

				case 400: // Wrong Data
					// dispatch toastError(data.message)
					dispatch(Popup.show(data.response.data.message ? JSON.stringify(data.response.data.message) : "Unknown error"));
					dispatch({type: PAPDIS_UPLOAD_FAILURE, message: data.message});
					break;

				case 500: // Something went wrong
					// dispatch toastError(data.message)
					dispatch(Popup.show(data.response.data.message ? JSON.stringify(data.response.data.message) : "Unknown error"));
					dispatch({type: PAPDIS_UPLOAD_FAILURE, message: data.response.data.message});
					break;

				default:
					//dispatch(SnackBar.warnAPIStatus(data.code));
					dispatch(Popup.show("Something went wrong uploading your file"));
					dispatch({type: PAPDIS_UPLOAD_FAILURE, message: "Something went wrong"});
					break;
			}

			dispatch(SnackBar.success("Your uploaded is started... You can leave this page."));

			// dispatch(get());
		})
		.catch(error => {
			if (error !== undefined) {
				if (error?.response?.status === 401) {
					requestNewLogin();
				}
				if (error?.data) {
					SnackBar.error(error.response.data.message || "Unknown error");
				}
				dispatch(Popup.showText(error.response.data.message ? JSON.stringify(error.response.data.message) : "Unknown error"));
			}

			console.error(error);
			dispatch({type: PAPDIS_UPLOAD_FAILURE,});
		})
		.then(() => dispatch(getUploads(data.advisor)))
};

/**
 * @param triggerUpload
 * @returns {{triggerUpload: Boolean, type: string}}
 */
export const setTriggerUpload: ActionCreator = (triggerUpload: Boolean = true) => ({
	type: PAPDIS_UPLOAD_SET_TRIGGER,
	triggerUpload
});

/**
 * @param advisor_id
 * @param page_index
 * @param loading
 * @returns {(function(*): void)|*}
 */
export const getUploads: ActionCreator = (advisor_id = 0, page_index = 1, loading = true) => dispatch => {
	const cancelTokenSource = PAPDISService.getCancelToken();

	dispatch({
		type: PAPDIS_GET_UPLOADS_STARTED,
		isLoadingPastUploads: loading,
	});

	new PAPDISService()
		.pastUploads({advisors: {advisor_id}}, page_index, cancelTokenSource)
		.then(response => {
			switch (response.status) {
				case 200:
					dispatch({
						type: PAPDIS_GET_UPLOADS_SUCCESS,
						data: response.data.data,
						isLoadingPastUploads: false,
					});
					break;

				default:
					break;
			}
		})
		.catch(response => {
			dispatch({
				type: PAPDIS_GET_UPLOADS_FAILURE,
				isLoadingPastUploads: false,
			});
			if (PAPDISService.isCancel(response)) {
				console.warn('Cancelling previous upload list request');
			}

			console.error(response.response);
		});
};

/**
 *
 * @param id
 * @param advisor_id
 */
export const deletePapdisPastUpload = (id: number, advisor_id: number) => dispatch => {
	if (id) {
		dispatch({
			type: DELETE_PAST_PAPDIS_UPLOAD_STARTED,
			isLoadingPastUploads: false,
		});
		new PAPDISService()
			.deletePapdisPastUpload(id)
			.then(() => {
				dispatch({
					type: DELETE_PAST_PAPDIS_UPLOAD_SUCCESS,
					isLoadingPastUploads: false,
				});
				dispatch(getUploads(advisor_id, 1));
			})
			.catch((error) => {
				dispatch({
					type: DELETE_PAST_PAPDIS_UPLOAD_FAILED,
				});
				console.error(error);
			});
	}
};

export const downloadPAPDISPastUploads = (data) => dispatch => {
	if (data) {
		dispatch({
			type: DOWNLOAD_PAPDIS_PAST_UPLOADS,
			isLoadingPastUploads: true
		});
		new PAPDISService()
			.downloadPAPDISPastUploads(data)
			.then((response) => {
				dispatch({
					type: DOWNLOAD_PAPDIS_PAST_UPLOADS,
					isLoadingPastUploads: false
				});
				
				if (response.data.data) {
					const url = window.URL.createObjectURL(new Blob([response.data.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'PAPDIS Past Uploads.csv');
					document.body.appendChild(link);
					link.click();
				}
			})
			.catch(data => {
				dispatch({
					type: DOWNLOAD_PAPDIS_PAST_UPLOADS,
					isLoadingPastUploads: false
				});
				
				console.error(data);
			});
	}
};


/**
 *
 * @param id
 * @param fileName
 */
export const downloadPapdisContent = (id: number, fileName = "") => dispatch => {
	if (id) {
		dispatch({
			type: DOWNLOAD_PAPDIS_PAST_UPLOADS,
			isLoadingPastUploads: true,
		});
		Request.get(
			`/advisors/employer/download-past-upload/${id}`)
			.then(response => {
				dispatch({
					type: DOWNLOAD_PAPDIS_PAST_UPLOADS,
					isLoadingPastUploads: false,
				});
				FileDownload(
					response?.data,
					`${fileName}`
				)
			})
			.catch(error => {
				dispatch({
					type: DOWNLOAD_PAPDIS_PAST_UPLOADS,
					isLoadingPastUploads: false,
				});
				console.error(error);
			});
	}
};