import Get from "./Get";
import GetEmployeeContributions from "./GetEmployeeContributions";
import GetPapdisContributions from "./GetPapdisContributions";
import GetContributionsPayment from "./GetContributionsPayment";

/**
 * @type {{Get: ((function(): Promise<AxiosResponse<*>>)|*)}}
 */
const ContributionsRequests = {
	Get,
	GetEmployeeContributions,
	GetPapdisContributions,
	GetContributionsPayment,
}

export default ContributionsRequests;