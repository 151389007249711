import React, { useState} from "react";
import {connect} from "react-redux";
import Wrapper from "../../layout/Logged/Wrapper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {me} from "../../actions/Profile";
import SectionGrid from "../../layout/SectionGrid";
import ContentGrid from "../../layout/ContentGrid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import ReferCompanyManually from "../../components/ReferCompanyManually";
import CompaniesList from "./CompaniesList";
import {generateCompanyNumber} from "../../actions/Employers";
import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddNewCompany = (props = {
	history: {
		push: () => {
		},
		location: {
			state: "",
		},
	},
	profile_data: {
		is_b2b_b2c: false,
	},
	generated_company_number: "",
}) => {

	let [
		enterCompany,
		setEnterCompany,
	] = useState("none");

	const [
			employer,
			setEmployerValue,
		] = useState(null),
		[
			timeout = null,
			setTimeoutDispatch,
		] = useState(null),
		[
			textInput,
			setTextInput,
		] = useState(""),
		history = useNavigate();
	sessionStorage.setItem("EmployerObject", JSON.stringify(employer));

	return (
		<Wrapper
			history={props.history}
			profile_data={props?.profile_data}
		>
			{
				enterCompany !== "block" && (
					<SectionGrid
						id={"add-new-company-banner-title"}
						className={`main-page-container-wrapper`}
					>
						<ContentGrid
							direction={"row"}
							alignItems={"flex-start"}
							alignContent={"center"}
							id={"profile-header-page-container-my-users"}
							item
							container
						>
							<Grid
								justifyContent={"center"}
								direction={"column"}
								container
							>
								<Typography
									variant={"subtitle1"}
									className={"title-individual"}
									align={"center"}
									style={{fontSize: 30}}
								>
									Now, find the employer
								</Typography>
								<Typography
									component={"p"}
									variant={"caption"}
									className={"subtitle-individual"}
									align={"center"}
								>
									Type the company’s name or enter details manually
								</Typography>
							</Grid>
						</ContentGrid>
					</SectionGrid>
				)
			}

			{
				enterCompany !== "block" && (
					<form noValidate autoComplete="off">
						<ContentGrid
							direction={"column"}
							alignItems={"center"}
							spacing={2}
							item
							container
							style={{background: "#ffff"}}
						>
							<Grid
								alignItems={"center"}
								alignContent={"center"}
								direction={"column"}
								justifyContent={"center"}
								container
								spacing={1}
								style={{maxWidth: 550}}
							>
								<FormControl
									className={"login-form-control"}
									fullWidth
								>
									<CompaniesList
										setEmployerValue={setEmployerValue}
										timeout={timeout}
										setTimeoutDispatch={setTimeoutDispatch}
										setTextInput={setTextInput}
									/>
								</FormControl>
								<FormControl
									className={"login-form-control"}
									fullWidth
								>
									<Button
										style={{marginBottom: 45}}
										variant="contained"
										color="primary"
										disabled={!employer || !textInput}
										onClick={
											() => {
												history("/confirm-company", {
													state: {
														employerAttributes: employer,
													},
												});
											}
										}
									>
										Continue
									</Button>
								</FormControl>
							</Grid>
							<Grid
								justifyContent={"center"}
								xs={12}
								sm={12}
								md={12}
								lg={12}
								style={{maxWidth: 550}}
								container
								item
							>
								<FormControl
									className={"login-form-control-2 forgot-password"}
									fullWidth
								>
									<span style={{textAlign: "center"}}>
										Didn't find your company?
									</span>
									<Button
										style={{marginBottom: 35}}
										variant="text"
										color="primary"
										onClick={() => setEnterCompany("block")}
									>
										Enter details manually
									</Button>
								</FormControl>
							</Grid>
							<Grid
								justifyContent={"center"}
								container
								item
								xs={12}
								sm={12}
								md={12}
								lg={12}
								spacing={1}
								style={{maxWidth: 550}}
							>
								<FormControl
									className={"login-form-control-2 forgot-password"}
									fullWidth
								>
									<Button
										variant="outlined"
										color="primary"
										onClick={() => history("/bulk-employers")}
										style={{marginBottom: 30}}
									>
										Bulk add employers
									</Button>
								</FormControl>
							</Grid>
						</ContentGrid>
					</form>
				)
			}

			<Box component="div" display={enterCompany}>
				<ReferCompanyManually
					history={history}
					setEnterCompany={setEnterCompany}
					generateCompanyNumber={props.generateCompanyNumber}
					generated_company_number={props.generated_company_number}
				/>
			</Box>
		</Wrapper>
	);
}

AddNewCompany.propTypes = {
	profile_data: PropTypes.shape({
		is_b2b_b2c: PropTypes.bool.isRequired,
	}).isRequired,
};

export default connect(
	(state) => ({
		...state.Profile,
		...state.SignUp,
	}),
	(dispatch) => ({
		me: dispatch(me()),
		generateCompanyNumber: () => dispatch(generateCompanyNumber()),
	})
)(AddNewCompany);
