import React from 'react'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import {toCurrency, toLocaleDate, toLocaleTime} from "../../utils/Helpers";
import Loading from "../Loading";
import TableCellItem from "../TableCellItem";
import Grid from "@mui/material/Grid";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import UploadProgress from "./UploadProgress";
import DescriptionIcon from "@mui/icons-material/Description";
import {RemoveRedEye} from "@mui/icons-material";


/**
 * @param error_data
 * @returns {boolean}
 */
const hasError = (error_data = []) => (error_data
	?.filter(x => x)
	?.length > 0);

/**
 * @param number_of_employees
 * @param number_of_employers
 * @param filename
 * @param total_employer_contributions
 * @param total_employee_contributions
 * @param upload_date
 * @param status
 * @param error_data
 * @param onClickHelp
 * @param id
 * @param deletePapdisPastUpload
 * @param profile_data
 * @param papdis_file_upload_id
 * @param percentage
 * @param label
 * @returns {JSX.Element}
 * @constructor
 */
const PastUploadRow: JSX.Element = (
	{
		number_of_employees = 0,
		number_of_employers = 0,
		filename = "",
		total_employer_contributions = 0,
		total_employee_contributions = 0,
		upload_date = null,
		status = 0,
		error_data = null,
		onClickHelp = () => {
		},
		id,
		deletePapdisPastUpload = () => {
		},
		profile_data = [],
		papdis_file_upload_id = null,
		progress = 0,
		label = "",
		downloadPapdisContent = () => {
		},
	}: {
		filename: String,
		number_of_employees: Number,
		number_of_employers: Number,
		total_employer_contributions: Number,
		total_employee_contributions: Number,
		upload_date: Date | null,
		error_data: Array<any> | null,
		status: String,
		onClickHelp: Function,
		id: Number,
		downloadPapdisPastUpload: Function,
	}
): JSX.Element => {
	const error = hasError(error_data);
	const URL = process.env.REACT_APP_ADVISOR_DEFAULT_APP_LINK;

	return (
		<TableRow>
			<TableCell align={"center"}>
				<TableCellItem
					title={toLocaleDate(upload_date)}
					subtitle={toLocaleTime(upload_date)}
					alignItems={"center"}
				/>

			</TableCell>

			<TableCell align={"center"}>
				<TableCellItem
					title={filename}
					alignItems={"center"}
				/>

			</TableCell>

			<TableCell align={"center"}>
				<TableCellItem
					title={number_of_employers}
					subtitle={number_of_employers !== 1 ? "Employers" : "Employer"}
					alignItems={"center"}
				/>
			</TableCell>

			<TableCell align={"center"}>
				<TableCellItem
					title={number_of_employees}
					subtitle={number_of_employees !== 1 ? "Employees" : "Employee"}
					alignItems={"center"}
				/>
			</TableCell>

			<TableCell align={"center"}>
				<Grid
					container
				>
					<TableCellItem
						title={`£ ${toCurrency(total_employee_contributions)}`}
						subtitle={"Employee"}
						item
						sm={6}
					/>

					<TableCellItem
						title={`£ ${toCurrency(total_employer_contributions)}`}
						subtitle={"Employer"}
						item
						sm={6}
					/>
				</Grid>
			</TableCell>
			<TableCell
				align={"center"}
			>
				<span
					className={`status-${(status || 0) % 3}`}
				>
					{papdis_file_upload_id === id ? label : ["In Progress", "Success", "Error"][(status || 0) % 3]}
				</span>
			</TableCell>

			{/*TODO: might be try to solve or remove it*/}
			<TableCell
				className={"status-field"}
				align={"center"}
			>
				<UploadProgress
					variant="determinate"
					progress={parseFloat(progress ?? 0) ?? 0}
					className={`status-progress-${(status || 0) % 3}`}
				/>
			</TableCell>
			<TableCell align={"center"}>
				{
					error ?
					<Grid>
						<InfoOutlined
							color={"primary"}
							className={"icon-margin-right"}
							cursor={"pointer"}
							onClick={() => onClickHelp(error_data)}
						/>
						<DeleteIcon
							color={"error"}
							cursor={"pointer"}
							className={"icon-margin-left"}
							onClick={() => deletePapdisPastUpload(id, profile_data?.id)}
						/>
					</Grid>
						: status === 1 ? <Grid>
							<RemoveRedEye
								cursor={"pointer"}
								className={"icon-margin-right"}
								onClick={error ? () => false : () =>
									window.open(URL + "/papdis-details/" + id)
								}
							/>
						<DescriptionIcon
							cursor={"pointer"}
							className={"icon-margin-left"}
							color={"primary"}
							onClick={() =>
								downloadPapdisContent(id, filename)
							}
						/>
		</Grid>
		: ""
				}
			</TableCell>
		</TableRow>
	);
}


/**
 * @param uploads
 * @param triggerModal
 * @param loading
 * @param getPapdisContributions
 * @returns {JSX.Element}
 * @constructor
 */
const PastUploadTable: React.Component = (
	{
		uploads = [],
		triggerModal = () => {
		},
		loading = false,
		profile_data = [],
		deletePapdisPastUpload = () => {
		},
		papdis_file_upload_id = null,
		percentage = 0,
		label = "",
		downloadPapdisContent = () => {
		},
	}: {
		uploads: Array<any>,
		triggerModal: Function,
		loading: Boolean,
		downloadPapdisPastUpload: Function,
	}
	): JSX.Element => {

	const alignments = [
		"center",
		"center",
		"center",
		"center",
		"center",
		"center",
	]

	if (loading === true) {
		return (
			<Box justifyContent="center" display="flex" padding={4}>
				<Loading absolute inPlace centered/>
			</Box>
		);
	}

	if (!uploads?.length) {
		return (
			<Typography variant="subtitle1" align={"center"}>
				You have not made an upload yet!
			</Typography>
		);
	}

	return (
		<Table>
			<TableHead
				className={"table-head"}
			>
				{/*TODO: might be try to solve or remove it (progress)*/}
				<TableRow>
					{[
						"Upload Date",
						"File Name",
						"Employers",
						"Employees",
						"Total Contributions",
						"Status",
						"Progress",
						"Actions"].map((label, index) => (
						<TableCell
							key={`th-${label}`}
							align={alignments[index]}
						>
							{label}
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				{
					uploads.map((upload, index) => (
						<PastUploadRow
							key={`tr-papdis-${index}`}
							{...upload}
							onClickHelp={triggerModal}
							deletePapdisPastUpload={deletePapdisPastUpload}
							profile_data={profile_data}
							papdis_file_upload_id={papdis_file_upload_id}
							percentage={upload.progress}
							label={label}
							downloadPapdisContent={downloadPapdisContent}
						/>
					))
				}
			</TableBody>
		</Table>
	);
}


export default PastUploadTable;