import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const Login = (data = false): Promise<AxiosResponse<T>> => Request.post("/advisors/login", data);

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default Login;