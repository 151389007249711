import Request from "../../utils/Request";
import { AxiosResponse } from "axios";

/**
 * @param data
 * @param queryString
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const DownloadEmployerList = (data:any[] = false): Promise<AxiosResponse<T>> => {
	return Request.post("/advisors/download-employers-list", data);
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DownloadEmployerList
