import {
	CONTINUE_SETUP_SOFTWARE_SIGN_UP,
	RETRIEVE_DATA_BY_SIGNUP_TOKEN,
	SET_PENDING_REQUEST,
	SIGNUP_GET_COMPANIES,
	SIGNUP_SET_FORM_FINISHED,
	SIGNUP_SET_FORM_LOADING,
	SIGNUP_VERIFY_ADMIN_EMAIL_ADDRESS,
	SIGNUP_VERIFY_COMPANY,
	SIGNUP_VERIFY_EMAIL_ADDRESS,
	SET_LOADING
} from "./type";
import SignUp from "../../services/SignUp";
import {viewCompany} from "../Employers";
import * as SnackBar from "../SnackBar";

/**
 * @param data
 * @param token
 * @returns {(function(*): void)|*}
 */
export const verifyEmail = (data, token: string = "") => dispatch => {
	if (data) {
		dispatch({
			type: SIGNUP_VERIFY_EMAIL_ADDRESS,
			error_message: "",
			verify_email: true,
		});
		new SignUp()
			.verifyEmail(data, token ?? "")
			.then(response => {
				if (response.data.data.error) {
					dispatch({
						type: SIGNUP_VERIFY_EMAIL_ADDRESS,
						error_message: response.data.data.message ?? "E-mail already in use.",
						verify_email: true,
					});
				} else {
					dispatch({
						type: SIGNUP_VERIFY_EMAIL_ADDRESS,
						error_message: "",
						verify_email: false,
					});
				}
			})
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @param token
 * @param shouldRedirect
 * @returns {(function(*): void)|*}
 */
export const signUp = (data: any[] = false, token:string = "", shouldRedirect: boolean = false) => dispatch => {

	if (data) {
		dispatch({
			type: SIGNUP_VERIFY_COMPANY,
			alreadyExistsMessage: "",
		});

		if (!shouldRedirect) {
			new SignUp()
				.insert(data ?? false, token ?? "")
				.then(async response => {
					const {
						data = false
					} = response;
					if (data.data) {
						const {
							token = ""
						} = data.data;
						if (token) {
							localStorage.clear();
							localStorage.setItem("collegiaToken", token);
							window.location.href = "/main-page";
							dispatch({
								type: CONTINUE_SETUP_SOFTWARE_SIGN_UP,
								continue_software_signup: false,
							});
						} else {
							dispatch({
								type: SIGNUP_VERIFY_COMPANY,
								alreadyExistsMessage: "This Company: "
									+ data.data.company_number + " - " + data.data.title
									+ " already exists in database.",
								officers: [],
								continue_software_signup: false,
							});
						}
					}
				})
				.catch(error => console.error(error));
		} else {
			new SignUp()
				.insertSetup(shouldRedirect, data)
				.then(async response => {
					const {
						data = false
					} = response.data;
					if (data) {
						dispatch({
							type: CONTINUE_SETUP_SOFTWARE_SIGN_UP,
							continue_software_signup: true,
						});
					}
				})
				.catch(error => console.error(error));
		}
	}
}

/**
 * @param data
 * @returns {function(*): void}
 */
export const getCompanies = (data) => dispatch => {
	if (data) {
		new SignUp()
			.getCompanies(data)
			.then(response => {
				dispatch({
					type: SIGNUP_GET_COMPANIES,
					companies: response.data.items ?? [],
				});
			})
			.catch(error => console.error(error));
	}
};


/**
 * @returns {function(*): void}
 */
export const clearArrayCompanies = () => dispatch => {
	dispatch({
		type: SIGNUP_GET_COMPANIES,
		companies: [],
	});
};

/**
 * @param token
 * @returns {function(*): void}
 */
export const setup = (token: string = "") => dispatch => {
	if (token) {
		new SignUp()
			.setup(token)
			.then(response => {
				if (response.data.data) {
					const {
						employer = false
					} = response.data.data;
					if (employer) {
						const {
							mandate = false
						} = employer;
						if (!mandate) {
							dispatch({
								type: RETRIEVE_DATA_BY_SIGNUP_TOKEN,
								setup_data: response.data.data
							});
						} else {
							window.location = "/";
						}
					} else {
						window.location = "/";
					}
				} else {
					window.location = "/";
				}
			})
			.catch(error => console.error(error));
	}
}

/**
 * @param data
 * @param index
 * @returns {function(*): void}
 */
export const verifyAdminEmail = (data: any[] = false, index: number = 0) => dispatch => {
	if (data) {
		dispatch({
			type: SIGNUP_VERIFY_ADMIN_EMAIL_ADDRESS,
			adminError: [],
		});
		new SignUp()
			.verifyAdminEmail(data ?? false)
			.then(response => {
				const {data = false,} = response.data;

				if (!data) {
					return false;
				}

				const {error = false,} = data;

				if (!error) {
					return false;
				}

				let adminError = [];

				adminError[index] = {...data,};

				dispatch({
					type: SIGNUP_VERIFY_ADMIN_EMAIL_ADDRESS,
					adminError,
				});
			})
			.catch(error => console.error(error));
	}
}

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const getDirectors = (data) => dispatch => {
	if (data) {
		dispatch(setFormLoading(true))
		dispatch({
			type: SIGNUP_VERIFY_COMPANY,
			alreadyExistsssage: "",
		});
		new SignUp()
			.getDirectors(data)
			.then(response => {
				dispatch(setFormLoading(false))
				dispatch({
					type: SIGNUP_GET_COMPANIES,
					officers: response.data.items ?? [],
				});
			})
			.catch(error => {
				dispatch(setFormLoading(false))
				console.error(error)
			});
	}
};

/**
 * @param isLoading
 * @returns {(function(*): void)|*}
 */
export const setFormLoading = (isLoading: Boolean = false) => dispatch => {
	dispatch({type: SET_PENDING_REQUEST, pending_request: isLoading})
	const timeout = isLoading ? 1 : 1000
	setTimeout(() => dispatch({
		type: SIGNUP_SET_FORM_LOADING,
		value: isLoading
	}), timeout)
}

/**
 * @param isFinished
 * @returns {{type: string, value: Boolean}}
 */
export const setFormFinished = (isFinished: Boolean = false) => ({
	type: SIGNUP_SET_FORM_FINISHED,
	value: isFinished
})

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const checkCompanyExist = (data) => dispatch => {
	if (data) {
		new SignUp()
			.checkCompanyExist(data)
			.then(response => {
				dispatch({
					type: SIGNUP_GET_COMPANIES,
					checkCompany: response.data.data,
				});
			})
			.catch(error => console.error(error));
	}
};

export const requestAccessToEmployer = (data) => dispatch => {
	if (data) {
		new SignUp()
			.requestAccessToEmployer(data)
			.then(({data, status}) => {
				switch (status) {
					case 200:
						dispatch({type: SIGNUP_GET_COMPANIES, data});
						sessionStorage.removeItem("EmployerObject");
						break;
					default:
				}
			})
			.catch(error => console.error(error));
	}
};

export const createCompanyInAdvisor = (data,is_setup_by_advisor) => dispatch => {
	if (data) {
		new SignUp()
			.createCompanyInAdvisor(data)
			.then(({data, status}) => {
				switch (status) {
					case 201:
					case 200:
						if(is_setup_by_advisor) {
							dispatch(viewCompany(data?.data?.employers[0]?.id));
						} else {
							dispatch(setLoading(false));
						}
						dispatch({type: SIGNUP_GET_COMPANIES, data});
						sessionStorage.removeItem("EmployerObject");
						break;
					default:
						break;
				}
			})
			.catch(error => {
				if (error?.response?.data?.error) {
					dispatch(
						SnackBar.error(
							error?.response?.data?.data
						)
					);
					setTimeout(function() {
						dispatch(setLoading(false));
						window.location.href = "/add-new-company";
					}, 5000);
				}
				console.error(error)
			});
	}
};

export const setLoading = (data) => dispatch => {
	dispatch({
		type: SET_LOADING,
		activation_loading: data
	})
}
/**
 * @param token
 * @returns {(function(*): void)|*}
 */
export const setupSignUp = (token: string = "") => dispatch => {
	if (token) {
		new SignUp()
			.setup(token ?? false)
			.then(response => dispatch({
				setup_data: response?.data?.data ?? null,
				type: CONTINUE_SETUP_SOFTWARE_SIGN_UP,
				requestedFetchSetupToken: true,
			}))
			.catch(error => console.error(error));
	}
}