import {
	ACCESS_IFA_EMPLOYERS,
	ACCESS_IFA_EMPLOYERS_ERROR,
	ACCESS_IFA_EMPLOYERS_SUCCESS,
	GET_IFA_EMPLOYEES,
	GET_IFA_EMPLOYEES_SUCCESS,
	GET_IFA_EMPLOYERS,
	GET_IFA_EMPLOYERS_SUCCESS,
} from "./types";
import EmployerIFAService from "../../../services/IFA/EmployersIFA";
import * as SnackBar from "../../SnackBar";

/**
 * @param data
 * @param queryString
 * @returns {(function(*): void)|*}
 */
export const getIfaEmployers = (data = {}, queryString = {}) => dispatch => {

	dispatch({
		type: GET_IFA_EMPLOYERS,
		employers_loading: true,
		employers_loading_table: true,
	});

	new EmployerIFAService()
		.getIfaEmployers(data, queryString)
		.then(response => {
			dispatch({
				type: GET_IFA_EMPLOYERS_SUCCESS,
				employers_loading: false,
				employers_loading_table: false,
				employers_ifa: response?.data?.data,
				employerView: true,
			});
		})
		.catch(error => console.error(error))
}

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const employerIfaAccess = (data = {}) => dispatch => {
	dispatch({
		type: ACCESS_IFA_EMPLOYERS,
		employers_loading: true,
	});

	new EmployerIFAService()
		.employerIfaAccess(data)
		.then((response) => {
			dispatch(SnackBar.success(response?.data?.data?.message));
			dispatch({
				type: ACCESS_IFA_EMPLOYERS_SUCCESS,
				employers_loading: false,
				employers_ifa: []
			});
		})
		.catch(error => {
			dispatch({
				type: ACCESS_IFA_EMPLOYERS_ERROR,
				employers_loading: false,
			});
			console.error(data);
			dispatch(SnackBar.error(error?.response?.data?.message));
		});
};

/**
 * @param data
 * @param queryString
 * @returns {(function(*): void)|*}
 */
export const getEmployees = (data = {}, queryString = {}) => dispatch => {
	dispatch({
		type: GET_IFA_EMPLOYEES,
		employees_table_loading: true,
		employees: []
	});

	new EmployerIFAService()
		.getEmployees(data, queryString)
		.then(response => {
			dispatch({
				type: GET_IFA_EMPLOYEES_SUCCESS,
				employees_table_loading: false,
				employees: response?.data?.data
			});
		})
		.catch(error => console.error(error));
};
