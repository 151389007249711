export const ModalContents = {
	opt_outs_help: {
		title: "Opt Outs",
		body: () => {
			return (
				<ul>
					<li>
						Here you can check all of your client’s employees that have opted out.
					</li>
				</ul>
			)
		}
	},
	bulk_employers_help: {
		title: "Bulk Add Employers",
		body: () => {
			return (
				<ul>
					<li>
						Here you can upload a csv file containing all your companies at once. <br/>
						<strong>Note:</strong> We will only add the companies that are registered on Companies House.
					</li>
					<li>
						If you need to add a company, that is not on Companies House, you can add it manually.
					</li>
				</ul>
			)
		}
	},
	upload_contributions_help: {
		title: "Upload Contributions",
		body: () => {
			return (
				<ul>
					<li>
						Here you can upload a CSV file containing all of your clients' contributions.
					</li>
				</ul>
			)
		}
	},
	home_help: {
		title: "Help",
		body: () => {
			return (
				<div>
					<span>The company you added is not showing up on the search?</span>
					<ul>
						<li>
							Reload the page or press F5.
						</li>
						<li>
							Search with at least 3 characters.
						</li>
					</ul>
				</div>
			)
		}
	},
	email_preference_help: {
		title: "Email Preference Help",
		body: () => {
			return (
				<ul>
					<li>
						The emails added here will receive the notifications when an employee opts out from the pension.
					</li>
					<li>
						If no email address is informed, all the admins will receive the notifications.
					</li>
				</ul>
			)
		}
	},
}