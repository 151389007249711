import Get from './Get';
import Update from "./Update";
import GetApiUploads from "./GetApiUploads";
import GetApiUploadsSpreadSheet from "./GetApiUploadsSpreadSheet";
import GetPayrollDates from "./GetPayrollDates";
import DownloadAllApiUpload from "./DownloadAllApiUpload";

const SoftwaresRequests = {
	Get,
	Update,
	GetApiUploads,
	GetApiUploadsSpreadSheet,
	GetPayrollDates,
	DownloadAllApiUpload,
};

export default SoftwaresRequests;