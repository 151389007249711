import {AxiosResponse} from "axios";
import AbstractServiceRequestClass from "../../classes/AbstractServiceRequestsClass";
import Request from '../../request';

export default class Notification extends AbstractServiceRequestClass {
    constructor() {
        super(Request.Notification);
    }

    /**
     * @param data
     * @returns {Promise<AxiosResponse<*>>|null}
     */
    setVisualized(data:any[] = false): Promise<AxiosResponse<*>>|null {
        if (this?.request && data) {

            return this?.request?.NotificationVisualized(data ?? false);
        }

        return new Promise((resolve,reject) => reject());
    }

    /**
     * @returns {Promise<AxiosResponse<*>>}
     */
    getNotification(): Promise<AxiosResponse<*>>|null {
        if (this.request) {
            return this?.request?.GetNotification();
        }
        return new Promise((resolve,reject) => reject());
    }
}