import AbstractServiceRequestsClass from "../../../classes/AbstractServiceRequestsClass";
import Request from "../../../request";
import {AxiosResponse} from "axios";

export default class IFAService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.IFA);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	getKeyStats(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request && !data) {
			return Promise?.reject("Cannot complete the request");
		}

		return this.request.GetKeyStats(data);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	createBeneficiary(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request && !data) {
			return Promise?.reject("Cannot complete the request");
		}

		return this.request.CreateBeneficiary(data);
	}

}