import React from "react";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {onChange} from "../../../utils/Helpers";
import PropTypes from "prop-types";

let link = <a href={'https://s3.eu-west-2.amazonaws.com/public.production.collegia/Collegia+Legal+Docs/22++Employer+Terms+and+Conditions.pdf'} target={'_blank'} rel="noreferrer">terms and conditions.</a>
/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AddingConfirmations = (props = {
	setCheckBox: () => {},
	setIsUmbrellaCompany: () => {},
	setTermsAccepted: () => {},
	is_employer_under_bureau: false,
	terms_accepted: false,
	profile_data: {
		is_b2b_b2c: false,
	},
}): JSX.Element => (
	<React.Fragment>
		<FormControl
			fullWidth
		>
			<Typography
				align={"left"}
				style={{fontSize: 12, marginBottom: 15}}
			>
				You can only apply to set up a pension scheme for a company that
				you lawfully represent or work for.
			</Typography>

			<FormControlLabel
				control={
					<Checkbox
						name={"check_confirm_authorised"}
						id={"check_confirm_authorised"}
						onChange={() => onChange(props?.setCheckBox(!props?.checkBox))}
					/>
				}
				label={"I confirm that I'm authorised to act on behalf of the employer."}
			/>
			{
				!props?.companyExist && (
					<FormControlLabel
						control={
							<Checkbox
								name={"terms_accepted"}
								id={"terms_accepted"}
								onChange={(event) =>
									onChange(props?.setTermsAccepted(event.target.checked))}
								value={props?.terms_accepted}
							/>
						}
						label={<span>I have read and accept Collegia's {link}</span>}
					/>
				)
			}
			{
				props?.profile_data?.is_b2b_b2c && (
					<FormControlLabel
						className={"bold-span"}
						control={
							<Checkbox
								name={"is_employer_under_bureau"}
								id={"is_employer_under_bureau"}
								onChange={(event) =>
									onChange(props?.setIsUmbrellaCompany(event.target.checked))}
								value={props?.is_employer_under_bureau}
							/>
						}
						label={
							<Typography>
								<strong>This is a bureau company.</strong> For this employer account that I am creating now, the outstanding pension contributions will be collected directly from my advisor bank account.
							</Typography>
						}
					/>
				)
			}
		</FormControl>

	</React.Fragment>
);


AddingConfirmations.propTypes = {
	setCheckBox: PropTypes.func.isRequired,
    checkBox: PropTypes.bool.isRequired,
    setIsUmbrellaCompany: PropTypes.func.isRequired,
	is_employer_under_bureau: PropTypes.bool.isRequired,
	setTermsAccepted: PropTypes.func.isRequired,
	terms_accepted: PropTypes.bool.isRequired,
	profile_data: PropTypes.object.isRequired,
};

export default AddingConfirmations;