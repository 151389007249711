import Request from "../../utils/Request";
import { AxiosResponse } from "axios";


const DeleteUser = ( data: any[] = false): Promise<AxiosResponse<T>> => Request

	.put("/advisors/my-user/delete/", {
		advisors: {
			...data,
		}
	});


export default DeleteUser;