import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @request
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
const UpdateEmail = (data:any[] = false): Promise<AxiosResponse<any>> =>
	Request.put(`/advisors/update-email`, {
		advisors: {
			advisors_id: data.advisors_id,
			users_advisors_id: data.advisors_users_id,
			email: data.email,
		}
	});

export default UpdateEmail;