import {GET_REFERRAL_INFORMATION} from "./types";
import ReferralServices from "../../services/Referral";


export const findByReferralCode = (code:string = "", type:string = "Advisor") => dispatch => {
	if (code) {
		new ReferralServices()
			.findByReferralCode(code, type)
			.then(response => dispatch({
					type: GET_REFERRAL_INFORMATION,
					referral: response?.data?.data ?? {},
			}))
			.catch(error => {
				dispatch({
					type: GET_REFERRAL_INFORMATION,
					referral: false
				});
				window.location.href = "/sign-up-advisor";
			});
	}
};