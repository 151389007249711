import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const GetCommunications = (data): Promise<AxiosResponse<any>> =>
    Request.post(`/advisors/employer/communications`, data);

export default GetCommunications;