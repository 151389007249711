import React from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

/**
 * @param props
 * @returns {JSX.Element|*[]}
 * @constructor
 */
export default function EmployerCard(props): JSX.Element|*[] {
	if (props) {

		return (
			<Card
				id={"employer-card-container"}
				onClick={() => window.location.href = process.env.REACT_APP_EMPLOYER_APP + "sign-up"}
			>
				<CardHeader
					avatar={
						<Typography gutterBottom className={"MuiCardHeader-avatar"}>
							|
						</Typography>
					}
					title={"For Employer"}
					subheader={"Sign up as an employer"}
				/>
				<CardContent className={"MuiCard-content"}>
					<Typography  gutterBottom>
						Setting up a great workplace pension is a piece of cake.
					</Typography>
				</CardContent>
				<CardActions>
					<Grid
						xs={6}
						sm={6}
						md={6}
						lg={6}
						container
						item
					>
						<Grid
							xs={12}
							sm={12}
							md={12}
							lg={12}
							alignContent={"flex-start"}
							direction={"column"}
							item
							container
						>
							<Button className={"MuiCardHeader-button"}>
								SIGN UP
							</Button>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
		);
	}

	return [];
}