import Request from "../../../utils/Request"
import { AxiosResponse } from "axios"

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 * @constructor
 */
const CreateBeneficiary = (data:any[] = false): Promise<AxiosResponse<T>> => {
	return Request.post("/advisors/ifa/beneficiary", data);
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CreateBeneficiary
