import Login from './Login';
import LogOut from "./LogOut";
import Me from './Me';
import RequestNewPassword from "./RequestNewPassword";
import CheckForgotPasswordToken from "./CheckForgotPasswordToken";
import UpdatePassword from "./UpdatePassword";
import updateAdvisor from "./UpdateAdvisor";
import updateEmail from "./UpdateEmail";
import ForgotPasswordUpdatePassword from './ForgotPasswordUpdatePassword';
import UpdateAddress from './UpdateAddress';
import UpdateEmailConfiguration from './UpdateEmailConfiguration';
import DeleteEmailConfiguration from "./DeleteEmailConfiguration";
import uploadDdiFile from "./uploadDdiFile";
import Update from "./Update";

const ProfileRequests = {
    Login,
    LogOut,
    Me,
    RequestNewPassword,
    CheckForgotPasswordToken,
    UpdatePassword,
    updateEmail,
    updateAdvisor,
    ForgotPasswordUpdatePassword,
    UpdateAddress,
    UpdateEmailConfiguration,
    DeleteEmailConfiguration,
    uploadDdiFile,
    Update,
};

export default ProfileRequests;
