import SectionGrid from './SectionGrid'

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ContentGrid = (props): JSX.Element => {
    return <SectionGrid className={"content-grid"} {...props} />
};

export default ContentGrid;
