import Request from '../../request';
import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";
import {AxiosResponse} from "axios";

export default class ContributionsService extends AbstractServiceRequestsClass {
    constructor() {
        super(Request.Contributions);
    }

	/**
	 * @param national_insurance_number
	 * @param type
	 * @param id
	 * @param schedule_id
	 * @param papdis_ids
	 * @param isAdvisor
	 * @returns {Promise<AxiosResponse<*>>|Promise<never>|undefined}
	 */
	getEmployeeContributions(
	    national_insurance_number:string = "",
        type:string = "",
        id: number = 0,
	    schedule_id:null|number = null,
	    papdis_ids:any[] = [],
	    isAdvisor:boolean = true,
    ): Promise<AxiosResponse<*>>|Promise<never>|undefined {
		if (this?.request && national_insurance_number && type !== "payroll") {
			return this?.request?.GetEmployeeContributions(national_insurance_number ?? "");
		}
		if (this?.request && type === "payroll" && id > 0) {
			return this?.request?.GetPapdisContributions(id, isAdvisor);
		}

		if (this?.request && (schedule_id || papdis_ids)) {
			return this?.request?.GetContributionsPayment(schedule_id ?? null, papdis_ids ?? []);
		}

        return Promise?.reject("No request and national insurance number provided");
    }
}