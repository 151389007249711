// importing the default action.
import {TOGGLE_MAIN_PAGE_TAB, TOGGLE_MENU} from "../../../actions/Layout/Menu/type";
import {resolveReduxState} from "../../../utils/Helpers";

/**
 * @type {{menu_open: boolean}}
 */
const initial_state = {
	menu_open: false,
	tab_index: 0,
};

/**
 * @param state
 * @param action
 * @returns {{valueOf, (): boolean}|boolean|{menu_open: boolean}}
 */
const MenuReducerStore = (state = initial_state, action = false) => {
	if (action) {
		const {type = false} = action;
		if (type) {
			if (type === TOGGLE_MENU) return resolveReduxState(state, action);
			else if (type === TOGGLE_MAIN_PAGE_TAB) return resolveReduxState(state, action);
		}
	}

	return state;
}

export default MenuReducerStore;