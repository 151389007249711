import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param code
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetByCodeRequest = (code:string = ""): Promise<AxiosResponse<any>> => Request.get(`/advisors/authorization/${code}`);

export default GetByCodeRequest;