import React from "react";
import {AppBar, IconButton, Toolbar, Typography} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import clsx from "clsx";
import logo from "../../images/logo.svg";
import {useNavigate} from "react-router-dom";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AppBarComponent = (props) => {
	const router = useNavigate();

	return (
		<AppBar
			position={"fixed"}
			id={"menu"}
			className={clsx("app-bar", {
				"app-bar-shift": props.isOpen,
			})}
		>
			<Toolbar>
				<Typography
					variant="h6"
					className={"title"}
					onClick={() => router("/")}
					noWrap
				>
					<img
						src={logo}
						alt={"Collegia logo."}
					/>
				</Typography>
				{
					props.showSandwich ?
						<IconButton
							color={"inherit"}
							aria-label={"open drawer"}
							edge={"end"}
							onClick={props.handleDrawer}
							className={clsx(props.isOpen && 'hide')}
							disableTouchRipple
							disableFocusRipple
							disableRipple
						>
							<MenuIcon/>
						</IconButton>
						:
						""
				}

			</Toolbar>
		</AppBar>
	);
}

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default AppBarComponent