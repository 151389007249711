import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const CheckCompany = (data): Promise<AxiosResponse<any>> => Request.get(`/employers/check-company-number/${data}`);
/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CheckCompany;