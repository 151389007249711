import {
	CHANGE_FUNDS,
	CHANGE_FUNDS_ERROR,
	CHANGE_FUNDS_SUCCESS,
	GET_IFA_INDIVIDUALS,
	GET_IFA_INDIVIDUALS_SUCCESS,
	REQUEST_ACCESS,
	REQUEST_ACCESS_ERROR,
	REQUEST_ACCESS_SUCCESS,
} from "./types";
import IndividualIFAService from "../../../services/IFA/IndividualsIFA";
import {setLoading} from "../../SignUp";
import * as SnackBar from "../../SnackBar";
import {GET_IFA_EMPLOYERS} from "../EmployersIFA/types";

/**
 * @param data
 * @param queryString
 * @returns {(function(*): void)|*}
 */
export const getIfaIndividuals = (data = {}, queryString = {}) => dispatch => {
	dispatch({
		type: GET_IFA_INDIVIDUALS,
		individuals_loading: true
	});

	new IndividualIFAService()
		.getIfaIndividuals(data, queryString)
		.then(response => {
			dispatch({
				type: GET_IFA_EMPLOYERS,
				employerView: false,
			});
			dispatch({
				type: GET_IFA_INDIVIDUALS_SUCCESS,
				individuals_loading: false,
				individuals: response?.data?.data,
			});
		})
		.catch(error => console.error(error))
};

/**
 * @returns {(function(*): void)|*}
 * @param individual_id
 */
export const viewIndividual = (individual_id: any = null) => dispatch => {
	if (individual_id) {
		new IndividualIFAService()
			.viewIndividual(individual_id ?? null)
			.then(response => {
				if (response?.data?.data) {
					window.open(process.env.REACT_APP_INDIVIDUAL_APP + "main-page/" + response?.data?.data, '_blank');
					dispatch(setLoading(false));
				}
			})
			.catch(data => {
				console.error(data);
			});
	}
};

/**
 * @returns {(function(*): void)|*}
 * @param data
 */
export const changeFunds = (data: any = null) => dispatch => {
	dispatch({
		type: CHANGE_FUNDS,
		individuals_loading: true,
	});
	if (data) {
		new IndividualIFAService()
			.changeFunds(data ?? null)
			.then((response) => {
				dispatch(SnackBar.success(response?.data?.data?.message));
				dispatch({
					type: CHANGE_FUNDS_SUCCESS,
					individuals_loading: false,
				});
			})
			.catch(data => {
				dispatch({
					type: CHANGE_FUNDS_ERROR,
					individuals_loading: false,
				});
				console.error(data);
			});
	}
};

/**
 * @returns {(function(*): void)|*}
 * @param data
 */
export const requestAccess = (data: any = null) => dispatch => {
	dispatch({
		type: REQUEST_ACCESS,
		individuals_loading: true,
	});
	if (data) {
		new IndividualIFAService()
			.requestAccess(data ?? null)
			.then(() => {
				dispatch({
					type: REQUEST_ACCESS_SUCCESS,
					individuals_loading: false,
				});
				window.location.href = "/ifa-dashboard"
			})
			.catch(error => {
				dispatch({
					type: REQUEST_ACCESS_ERROR,
					individuals_loading: false,
				});
				console.error(data);
				dispatch(SnackBar.error(error?.response?.data?.message));
			});
	}
};

/**
 * @returns {(function(*): void)|*}
 */
export const getFunds = () => dispatch => {
	new IndividualIFAService()
		.getFunds()
		.then(response => {
			dispatch({
				type: GET_IFA_INDIVIDUALS_SUCCESS,
				funds: response?.data?.data
			});
		})
		.catch(data => {
			console.error(data);
		});
};