import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";

import Request from "../../request";
import {AxiosResponse} from "axios";

export default class EmployerService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.Employer);
	}

	deletePastUpload(id: number): Promise<AxiosResponse<*>> | null {
		if (!this.request && !id) {
			return Promise?.reject("Cannot complete the request");
		}

		return this.request.deletePastUpload(id);
	}

	/**
	 * @param data
	 * @param queryString
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	getEmployerList(data: any[] = false, queryString = {}): Promise<AxiosResponse<*>> | null {
		if (!this.request && !data) {
			return Promise?.reject("Cannot complete the request");
		}

		return this.request.GetEmployerList(data, queryString);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|null}
	 */
	downloadEmployerList(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request && !data) {
			return Promise?.reject("Cannot complete the request");
		}

		return this.request.DownloadEmployerList(data);
	}

	/**
	 *
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 */
	uploadPapids(data = false) {
		console.info(this.request)
		if (this?.request && data) {
			return this?.request?.UploadPapidsRequest(data);
		}

		return Promise?.reject("Cannot complete the request");
	}

	pastUploads(data, page = 1): Promise<AxiosResponse<T>> {
		if (!this.request) {
			return Promise?.reject("Request wasn't completed.");
		}

		return this?.request?.PastUploads(data, page)
	}

	/**
	 * @param employer_id
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 */
	viewCompany(employer_id: any = null): Promise<never> | Promise<AxiosResponse<*>> {
		if (this.request && employer_id) {
			return this.request.ViewCompany(employer_id ?? null);
		}

		return Promise.reject("No employer specified.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|Promise<AxiosResponse<T>>}
	 */
	deleteEmployer(data: any[] = false): Promise<AxiosResponse<*>> | Promise<AxiosResponse<T>> {
		if (!this.request) {
			return Promise.reject("No request handler specified.");
		}

		return this.request.DeleteEmployeesList(data);
	}

	/**
	 * @param data
	 * @param page
	 * @returns {Promise<AxiosResponse<*>>}
	 */
	getBulkEmployersHistory(data, page = 1): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise.reject("No request handler specified.");
		}

		return this.request.GetBulkEmployersHistory(data, page);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|Promise<AxiosResponse<*>>|React.ReactElement|*}
	 */
	bulkEmployers(data: any[] = false): Promise<AxiosResponse<*>> | null {
		if (!this.request) {
			return Promise.reject("No request handler specified.");
		}

		return this.request.BulkEmployers(data);
	}

	/**
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 */
	generateCompanyNumber(): Promise<never> | Promise<AxiosResponse<*>> {
		if (!this.request) {
			return Promise.reject("No request handler specified.");
		}

		return this.request.GenerateCompanyNumber();
	}

	/**
	 * @param data
	 * @returns {Promise<never>|Promise<AxiosResponse<*>>}
	 */
	addCompanyManually(data = {}): Promise<never> | Promise<AxiosResponse<*>> {
		if (!this.request) {
			return Promise.reject("No request handler specified.");
		}

		return this.request.AddCompanyManually(data);
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>|Promise<AxiosResponse<T>>|undefined}
	 */
	getStagingDateByApiService(data: any[] = false): Promise<AxiosResponse<T>> | null {
		if (this?.request) {
			return this?.request?.GetStagingDateByApiRequest(data);
		}

		return Promise?.reject("Request wasn't completed.");
	}

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|*|Promise<never>}
	 */
	verifyPrimaryEmail = (data = {}): Promise<AxiosResponse<*>> | * | Promise<never> =>
		this?.request?.VerifyEmail(data)
		??
		Promise?.reject("Request wasn't completed.");

	/**
	 * @param data
	 * @returns {Promise<AxiosResponse<*>>|*|Promise<never>}
	 */
	confirmOptOutContribution = (data = {}): Promise<AxiosResponse<*>> | * | Promise<never> =>
		this?.request?.ConfirmOptOutContribution(data)
		??
		Promise?.reject("Request wasn't completed.");

	getCommunications = (data) => this.request.GetCommunications(data);

	getEmployeeCommunications = (data) => this.request.GetEmployeesCommunications(data);
}