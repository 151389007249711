import React from "react";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import {useNavigate} from "react-router-dom";

/**
 * @param newValue
 * @param paymentDate
 * @param setPaymentDate
 */
const sliderOnChange = (
	newValue: number = 0,
	paymentDate: number = 0,
	setPaymentDate: Function = () => {
	},
) => {
	if (newValue !== paymentDate) {
		setPaymentDate(newValue);
	}
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentDateForm = (props = {
	paymentDate: 0,
	profile_data: [],
	setStepIndex: () => {},
	setPaymentDate: () => {},
	update: () => {},
}): JSX.Element => {
	const push = useNavigate();

	return (
		<Grid
			id={props?.isSetup ? "signup-container" : "activeaccount-container"}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<form
				noValidate
				autoComplete="off"
			>
				<FormControl
					className={props?.isSetup ? "signup-form-control-2 first-title" : "activeaccount-form-control-2 first-title"}
					fullWidth
				>
					<Typography align={"center"}>
						Set your contribution payment date
					</Typography>
				</FormControl>
				<FormControl
					className={props?.isSetup ? "signup-form-control-2 second-title" : "activeaccount-form-control-2 second-title"}
					fullWidth
				>
					<Typography align={"center"}>
						In which day of the month should we collect the pension contributions from your account?
					</Typography>
				</FormControl>

				<Slider
					className={"payment-date"}
					value={props?.paymentDate ?? 10}
					min={8}
					max={22}
					valueLabelDisplay="on"
					step={1}
					onChange={(event, newValue) =>
						sliderOnChange(newValue, props?.paymentDate, props?.setPaymentDate)}
				/>

				<FormControl
					className={props?.isSetup ? "signup-form-control-2 " : "activeaccount-form-control-2 "}
					fullWidth
				>
					<small>
						Even if your pay period is weekly or fortnightly, we only collect contributions once a month.
					</small>
				</FormControl>

				<FormControl
					className={props?.isSetup ? "signup-form-control" : "activeaccount-form-control"}
					fullWidth
				>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							props?.update({
								advisors_id: props?.profile_data?.userLogged?.advisor_id,
								contribution_date: props?.paymentDate,
								business_type: props?.profile_data?.type_of_business,
								title_advisor: props?.profile_data?.title,
								id_advisors_users: props?.profile_data?.userLogged?.id,
							});
							props.setStepIndex(1);
						}}
					>
						Continue
					</Button>
				</FormControl>

				<FormControl
					className={"signup-form-control-2 forgot-password"}
					fullWidth
				>
						<span
							className={"back-span"}
							onClick={() => push("/main-page")}
						>
							Back
						</span>
				</FormControl>

			</form>
			<Grid
				id={"direct-debit-container"}
				lg={12}
				xs={12}
				md={12}
				sm={12}
				alignItems={"flex-start"}
				alignContent={"flex-start"}
				direction={"column"}
				spacing={0}
				container
				item
			>
				<form
					noValidate
					autoComplete="off"
				>
					<FormControl
						className={props?.isSetup ? "signup-form-control" : "activeaccount-form-control"}
						fullWidth
					>
						<small>
							<strong>Important:</strong>
							<br/>
							<br/>
							<strong>
								Collegia only collects contributions for employees whose opt out period have lapsed for
								more than 5 days.
								This means that the earliest we collect pension contributions for new employees is 35
								days after enrolment.
								More often, we will collect contributions for newly enrolled employees in the following
								month.
							</strong>
						</small>
					</FormControl>
				</form>
			</Grid>
		</Grid>
	);
};
/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default PaymentDateForm;