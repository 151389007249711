import Request from "../../utils/Request";
import { AxiosResponse } from "axios";

const CreateUser = (data: any[] = false): Promise<AxiosResponse<T>> => Request

	.post("/advisors/my-user/create/", {
		advisors: {
			...data,
		}
	});

export default CreateUser;