import {resolveReduxState} from "../../utils/Helpers";
import * as OptOut from "../../actions/OptOut/type";

/**
 * @type {{error_message: string, companies: [], continue_software_signup: boolean, setup_data: [], error: boolean, verify_email: boolean, payrollSoftwares: [], officers: [], alreadyExistsMessage: string}}
 */
const initial_state = {
	error: false,
	myoptoutlist: [],
}

const OptOutReducerStore = (state = initial_state, action: any[] = {}) => {
	if (!action) return state;
	const {type = false} = action;

	if (!type) return state;
	switch (type) {
		case OptOut.OPTOUTGET:
			return resolveReduxState(state, action);

		default:
			return state;
	}
}

export default OptOutReducerStore;