import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {me} from "../actions/Profile";
import Wrapper from "../layout/Logged/Wrapper";
import LoadingPanel from "../components/LoadingPanel";
import ContentGrid from "../layout/ContentGrid";
import SectionGrid from "../layout/SectionGrid";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {Card, List, ListItem} from "@mui/material";
import CardContent from "@mui/material/CardContent";
import IconArrowForward from "@mui/icons-material/ArrowForwardIos";
import {useNavigate} from "react-router-dom";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MyAccount =  (props = {
	showSandwich: true,
	notifications: [],
	headerLink: "home",
	visualizeNotification: () => {}
}): JSX.Element => {
	const
		{
			profile_data = {
				is_external_advisor: false
			},
			profile_loading
		} = useSelector(state => ({...state?.Profile})),
		dispatch = useDispatch(),
		navigate = useNavigate();

	let profile_title = profile_data.title;
	useEffect(() => {if (!profile_title) dispatch(me())}, [
		profile_title,
		dispatch
	]);

	const options = [
		{
			text: "Advisor Info",
			link: '/change-info'
		},
		{
			text: "Change advisor address",
			link: '/change-address'
		},
		{
			text: "Change user e-mail",
			link: '/change-email'
		},
		{
			text: "Change user password",
			link: '/change-password'
		},
		{
			text: "Email preferences",
			link: '/email-preferences'
		},
	];

	if (profile_loading) {
		return (
			<Wrapper>
				<LoadingPanel />
			</Wrapper>
		);
	}

	return (
		<Wrapper
			history={navigate}
			showSandwich={props?.showSandwich}
			notifications={props?.notifications}
			headerLink={props?.headerLink}
			visualizeNotification={props?.visualizeNotification}
		>
			<SectionGrid id={"my-account-content"}>
				<ContentGrid
					id={"profile-header-page-container"}
					className={"my-account-advisor-title"}
					direction={"column"}
					justifyContent={"center"}
					item
					container
				>
					{
						profile_data &&
							<Grid item>
								<Typography variant="h5">
									{profile_data.title}
								</Typography>
							</Grid>
					}
				</ContentGrid>
			</SectionGrid>
			<SectionGrid id={"my-account-content"}>
				<ContentGrid id={"my-account-content"}>
					<List className={"my-account-card-list"}>
						{
							options.map(({text, link, critical = false}, index) =>
								<ListItem
									key={`link-${index}`}
								>
									<Card
										className={"my-account-cards"}
										onClick={() => link && navigate(link)}
									>
										<CardContent
											style={{padding: 25}}
										>
											<Grid
												direction={"row"}
												alignItems={"center"}
												justifyContent={"space-between"}
												container
											>
												<Typography>
													{text}
												</Typography>
												<IconArrowForward />
											</Grid>
										</CardContent>
									</Card>
								</ListItem>
							)
						}
					</List>
				</ContentGrid>
			</SectionGrid>
		</Wrapper>
	);
};

export default MyAccount;