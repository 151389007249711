import {resolveReduxState} from "../../utils/Helpers"
import {
	BULK_EMPLOYERS_UPLOAD_FAILURE,
	BULK_EMPLOYERS_UPLOAD_STARTED,
	BULK_EMPLOYERS_UPLOAD_SUCCESS,
	DELETE_PAST_UPLOAD_FAILED,
	DELETE_PAST_UPLOAD_STARTED,
	DELETE_PAST_UPLOAD_SUCCESS,
	EMPLOYERS_ADD_MANUALLY,
	EMPLOYERS_DELETE,
	EMPLOYERS_LIST_FAILURE,
	EMPLOYERS_LIST_STARTED,
	EMPLOYERS_LIST_SUCCESS,
	EMPLOYERS_VERIFY_EMAIL,
	FETCH_STAGING_DATE_API,
	GENERATE_COMPANY_NUMBER,
	GET_BULK_EMPLOYERS_HISTORY_FAILED,
	GET_BULK_EMPLOYERS_HISTORY_STARTED,
	GET_BULK_EMPLOYERS_HISTORY_SUCCESS,
	GET_BULK_EMPLOYERS_UPLOAD_DETAILS_STARTED,
	GET_BULK_EMPLOYERS_UPLOAD_DETAILS_SUCCESS,
	GET_COMMUNICATIONS,
	GET_ALL_EMPLOYERS_WITHOUT_PAGINATION,
} from "../../actions/Employers/types";

const initial_state = {
	employers: {
		isLoading: false,
		select_input_loading: false,
		list: [],
	},
	isLoading: false,
	total: 0,
	per_page: 15,
	generated_company_number: "",
	filter: "",
	error_message: "",
	canProceed: false,
	is_loading: false,
	bulk_employers_details: [],
	bulkEmployersHistory: [],
	communications: [],
	all_employers: [],
	employees_communications: [],
};

const EmployerReducerStore = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;
	
	switch (type) {
		case FETCH_STAGING_DATE_API:
			return resolveReduxState(state, action);

		case EMPLOYERS_LIST_STARTED:
			return {
				...state,
				filter: action?.filter ?? "",
				select_input_loading: true,
				employers: {
					isLoading: true,
					select_input_loading: true,
					...state.employers,
				},
			};

		case EMPLOYERS_LIST_SUCCESS:
			return {
				...state,
				employers: {
					isLoading: false,
					list: action?.employers?.data ?? [],
					total: action?.employers?.total ?? 0,
					per_page: action?.employers?.per_page ?? 15,
					current_page: action?.employers?.current_page ?? 1,
					last_page: action?.employers?.last_page ?? 1,
					select_input_loading: false,
				},
			};

		case EMPLOYERS_LIST_FAILURE:
			return {
				...state,
				employers: initial_state.employers,
			};

		case EMPLOYERS_DELETE:
			return {
				...state,
				isLoading: false,
			};

		case GENERATE_COMPANY_NUMBER:
			return resolveReduxState(state, action);

		case EMPLOYERS_ADD_MANUALLY:
			return {
				...state,
				isLoading: false
			};

		case EMPLOYERS_VERIFY_EMAIL:
			return resolveReduxState(state, action);

		case GET_BULK_EMPLOYERS_HISTORY_STARTED:
		case DELETE_PAST_UPLOAD_STARTED:
			return {
				...state,
				isLoading: true
			};

		case GET_BULK_EMPLOYERS_HISTORY_SUCCESS:
			return {
				...state,
				bulkEmployersHistory: action.bulkEmployersHistory,
				isLoading: false
			};

		case GET_BULK_EMPLOYERS_HISTORY_FAILED:
			return {
				...state,
				isLoading: false
			};

		case BULK_EMPLOYERS_UPLOAD_STARTED:
			return {
				...state,
				isLoading: true
			};

		case BULK_EMPLOYERS_UPLOAD_SUCCESS:
			return {
				...state,
				isLoading: false
			};

		case BULK_EMPLOYERS_UPLOAD_FAILURE:
			return {
				...state,
				isLoading: false
			};

		case GET_BULK_EMPLOYERS_UPLOAD_DETAILS_STARTED:
			return {
				...state,
				isLoading: true
			}
		case GET_BULK_EMPLOYERS_UPLOAD_DETAILS_SUCCESS:
			return {
				...state,
				isLoading: false,
				bulk_employers_details: action?.bulk_employers_details
			}
		case DELETE_PAST_UPLOAD_SUCCESS:
		case DELETE_PAST_UPLOAD_FAILED:
			return {
				...state,
				isLoading: false
			};

		case GET_COMMUNICATIONS:
		case GET_ALL_EMPLOYERS_WITHOUT_PAGINATION:
			return resolveReduxState(state, action);

		default:
			return state;
	}
}

export default EmployerReducerStore;