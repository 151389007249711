import React from "react";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import 'react-phone-input-2/lib/material.css';
import FindAddressByApi from "./FindAddressByApi";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const searchCompany = (props) => {
	props.setEnterManually(false);
	props.fillTypeOfCompanies(false, props.setFormValues, props.formValues);
	props.proceedStep(props.stepIndex - 1, props.setStepIndex);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FindAddress = (props): JSX.Element => {
	return (
		<Grid
			id={"signup-container"}
			lg={12}
			xs={12}
			md={12}
			sm={12}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<form
				noValidate
				autoComplete="off"
			>
				<FormControl
					className={"signup-form-control-2 first-title"}
					fullWidth
				>
					<Typography
						align={"center"}
					>
						Search your company address
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 second-title"}
					fullWidth
				>
					<Typography
						align={"center"}
					>
						Please follow the info below
					</Typography>
				</FormControl>
				<Grid
					className={props.enterManually ? "grid-display-block" : "grid-display-none"}
				>
					<FormControl
						fullWidth
					>
						<Typography
							color={"black"}
							marginBottom={1}
							marginLeft={2}
						>
							{"Do you want to search your company?"}
							<a
								href="# " onClick={ ()=> searchCompany(props) }
							>
								{" Click Here"}
							</a>
						</Typography>
					</FormControl>

					<FormControl
						className={"signup-form-control"}
						fullWidth
					>
						<TextField
							type={"text"}
							onChange={(event) =>
								props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
							}
							label="Name of advisor (Company name)*"
							variant="outlined"
							name={"company_name"}
							id={"company_name"}
							value={props.company_name}
						/>
					</FormControl>
				</Grid>
				<Grid>
					<FormControl
						className={"signup-form-control"}
						fullWidth
					>
						<Autocomplete
							id="legal-structure-dropdown"
							disableClearable
							options={
								props.formValues.company_legal_structure_options ?? []
							}
							getOptionLabel={(option) => option.title}
							onChange={(e, value) =>
								props.setFormValues({...props.formValues, company_legal_structure: value?.title})
							}
							value={
								props?.formValues?.company_legal_structure_options?.filter(c =>
									(c.title === props?.apiTypeOfBusiness ?
											c.title
											:
											""
									))[0]
							}
							renderInput={(params) => (
								<TextField
									{...params}
									onChange={(event , options) => {
										if (options?.includes(event.target.value)) {
											props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
										}
									}}
									label="Type of business *"
									variant="outlined"
									name={"company_legal_structure"}
									id={"company_legal_structure"}
								/>
							)}
						/>
					</FormControl>

				</Grid>
				<Grid>
					<FindAddressByApi
						addressComponents={props.addressComponents}
						setAddressComponents={props.setAddressComponents}
						address_1={props.address_1}
						address_2={props.address_2}
						address_3={props.address_3}
						address_4={props.address_4}
						postal_code={props.postal_code}
						country={props.country}
						setFormValues={props.setFormValues}
						formValues={props.formValues}
					/>
					<FormControl className={"signup-form-control"} fullWidth>
						<Button
							variant="contained"
							color="primary"
							onClick={() =>
								props.proceedStep(props.stepIndex + 1, props.setStepIndex)
							}
							disabled={
								!props.address_1 ||
								!props.address_2 ||
								!props.postal_code ||
								!props.country ||
								!props.formValues.company_legal_structure ||
								props.formValues.company_name.length < 2 ||
								props.error
							}
						>
							Continue
						</Button>
					</FormControl>
					<FormControl
						className={"signup-form-control-2 forgot-password"}
						fullWidth
					>
						<span
							className={"span-style"}
							onClick={() =>
								props.proceedStep(props.stepIndex - 1, props.setStepIndex)
							}
						>
							Back
						</span>
					</FormControl>
				</Grid>
			</form>
		</Grid>
	);
}

export default FindAddress;