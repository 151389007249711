import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const GetEmployeesCommunications = (data): Promise<AxiosResponse<any>> =>
	Request.post(`/advisors/employer/employees-communications`, data);

export default GetEmployeesCommunications;