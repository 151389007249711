import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const ConfirmOptOutContribution = (data: any[] = false): Promise<AxiosResponse<any>> =>
	Request.post("/advisors/confirm-opt-out-contribution", data);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default ConfirmOptOutContribution;