import React from 'react';

import ContentGrid from "../../layout/ContentGrid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const NoUsersWarning: JSX.Element = () => (
	<Typography
		variant={"h4"}
		className={"manage-software-warning"}
	>
		You don't have any users registered
	</Typography>
);

/**
 * @param forename
 * @param surname
 * @param is_super
 * @param created_by
 * @param onPressDelete
 * @param onPressEdit
 * @param id
 * @param profile_data
 * @returns {JSX.Element}
 * @constructor
 */
const UserRow: JSX.Element = (
	{
		forename = "",
		surname = "",
		is_super = "",
		created_by = "",
		onPressDelete = () => {
		},
		onPressEdit = () => {
		},
		id = null,
		profile_data = {},
	}: {
		forename: string,
		surname: string,
		is_super: boolean,
		created_by: string,
		onPressEdit: Function,
		onPressDelete: Function,
		id: number,
		profile_data: Array<any>,
	}
): JSX.Element => (
	<TableRow>
		<TableCell>{forename} {surname}</TableCell>
		{(is_super === true)
			? <TableCell>Advisor (Account Owner)</TableCell>
			: <TableCell>Advisor (Standard)</TableCell>
		}
		<TableCell>{}</TableCell>
		<TableCell align="right" width={140}>
			{
				created_by === "itself" || profile_data.id === id
					? []
					: <IconButton onClick={onPressDelete}>
						<DeleteIcon/>
					</IconButton>
			}
			{
				profile_data?.is_super || profile_data.id === id
					? <IconButton onClick={onPressEdit}>
						<EditIcon/>
					</IconButton>
					: []
			}
		</TableCell>
	</TableRow>
);

/**
 * @param users
 * @param onPressDeleteUser
 * @param onPressEditUser
 * @param profile_data
 * @returns {JSX.Element}
 * @constructor
 */
const UserTable: JSX.Element = (
	{
		users = [],
		onPressDeleteUser = () => {
		},
		onPressEditUser = () => {
		},
		profile_data = {},
	}
): JSX.Element => (
	<Table>
		<TableHead
			className={"manage-software-table-head"}
		>
			<TableRow>
				{["Name", "Role", "Last activity", "Action"].map(label => (
					<TableCell
						key={`th-${label}`}
						align={label === "Action" ? "right" : "left"}
					>
						{label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
		<TableBody>
			{users.map((option, index) => (
				<UserRow key={index}
				         value={option}
				         profile_data={profile_data}
				         forename={users[index]?.forename}
				         surname={users[index]?.surname}
				         is_super={users[index]?.is_super}
				         created_by={users[index]?.created_by}
				         id={users[index]?.id}
				         onPressDelete={() => onPressDeleteUser(users[index])}
				         onPressEdit={() => onPressEditUser(users[index])}
				>
				</UserRow>
			))}
		</TableBody>
	</Table>
);

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UserTablePanel = (props: Object = {}): JSX.Element => (
	<Grid
		alignItems={"center"}
		justifyContent={"space-between"}
		container
	>
		<Grid
			md={6}
			alignItems={"center"}
			justifyContent={"flex-start"}
			item
			container
		>
			<Typography
				className={"manage-software-title"}
				variant={"h4"}
			>
				Manage your users
			</Typography>
		</Grid>

		{
			props?.profile_data?.is_super && <Grid
				md={6}
				justifyContent={"flex-end"}
				item
				container
			>
				<Button
					onClick={props.onPressCreateUser}
					variant={"contained"}
					color={"primary"}
				>
					CREATE USER
				</Button>
			</Grid>
		}

		<ContentGrid
			md={12}
			item
		>
			{props.users != null
				? <UserTable {...props} />
				: <NoUsersWarning/>
			}
		</ContentGrid>
	</Grid>
);

export default UserTablePanel;