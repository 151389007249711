export const FETCH_STAGING_DATE_API = "EMPLOYER.FETCH_STAGING_DATE_API";
export const EMPLOYER_UPLOAD_STARTED = "EMPLOYER.UPLOAD_STARTED";
export const EMPLOYER_UPLOAD_SUCCESS = "EMPLOYER.UPLOAD_SUCCESS";
export const EMPLOYER_UPLOAD_FAILURE = "EMPLOYER.UPLOAD_FAILURE";
export const EMPLOYER_UPLOAD_SET_TRIGGER = "EMPLOYER.UPLOAD_SET_TRIGGER";
// Employee Data-Fetching Flow
export const EMPLOYERS_LIST_STARTED = "EMPLOYER.EMPLOYERS_LIST.STARTED";
export const EMPLOYERS_LIST_SUCCESS = "EMPLOYER.EMPLOYERS_LIST.SUCCESS";
export const EMPLOYERS_LIST_FAILURE = "EMPLOYER.EMPLOYERS_LIST.FAILURE";
export const EMPLOYERS_DELETE = "EMPLOYER.EMPLOYERS_LIST.DELETE";
export const GENERATE_COMPANY_NUMBER = "EMPLOYER.GENERATE_COMPANY_NUMBER";
export const EMPLOYERS_ADD_MANUALLY = "EMPLOYER.ADD.MANUALLY";
export const EMPLOYERS_VERIFY_EMAIL = "EMPLOYER.VERIFY_EMAIL";
export const GET_BULK_EMPLOYERS_UPLOAD_DETAILS_STARTED = 'GET_BULK_EMPLOYERS_UPLOAD_DETAILS_STARTED';
export const GET_BULK_EMPLOYERS_UPLOAD_DETAILS_SUCCESS = 'GET_BULK_EMPLOYERS_UPLOAD_DETAILS_SUCCESS';
export const DELETE_PAST_UPLOAD_STARTED = "DELETE_PAST_UPLOAD_STARTED";
export const DELETE_PAST_UPLOAD_SUCCESS = "DELETE_PAST_UPLOAD_SUCCESS";
export const DELETE_PAST_UPLOAD_FAILED = "DELETE_PAST_UPLOAD_FAILED";
export const GET_BULK_EMPLOYERS_HISTORY_STARTED = "GET_BULK_EMPLOYERS_HISTORY_STARTED";
export const GET_BULK_EMPLOYERS_HISTORY_SUCCESS = "GET_BULK_EMPLOYERS_HISTORY_SUCCESS";
export const GET_BULK_EMPLOYERS_HISTORY_FAILED = "GET_BULK_EMPLOYERS_HISTORY_FAILED";
export const BULK_EMPLOYERS_UPLOAD_STARTED = "BULK_EMPLOYERS_UPLOAD_STARTED";
export const BULK_EMPLOYERS_UPLOAD_SUCCESS = "BULK_EMPLOYERS_UPLOAD_SUCCESS";
export const BULK_EMPLOYERS_UPLOAD_FAILURE = "BULK_EMPLOYERS_UPLOAD_FAILURE";
export const GET_COMMUNICATIONS = "EMPLOYERS.GET_COMMUNICATIONS";
export const GET_ALL_EMPLOYERS_WITHOUT_PAGINATION = 'EMPLOYERS.GET_ALL_EMPLOYERS_WITHOUT_PAGINATION';