import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PanelHeader = (props): JSX.Element => (
	<Grid className={"my-account-change-info-grid-title"}>
		<Typography className={"my-account-change-info-title"} variant="h5" {...props}/>
	</Grid>
);

export default PanelHeader;