import * as types from "../../actions/Softwares/types";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	isLoading: false,
	softwareList: [],
	apiUploads: [],
	payroll_start_date: [],
	payroll_end_date: [],
};

const SoftwaresReducer = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {

		case types.GET_SOFTWARES:
		case types.GET_API_UPLOADS:
		case types.GET_API_DATE_UPLOADS:
			return resolveReduxState(state, action);

		default:
			return state;
	}
}

export default SoftwaresReducer;
