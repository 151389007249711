import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetRequest = (national_insurance_number: string = ""): Promise<AxiosResponse<any>> => (
    Request.get(`employers/contributions/employee/${national_insurance_number}`)
);

export default GetRequest;