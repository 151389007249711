import {resolveReduxState} from "../../../utils/Helpers"
import {
	ACCESS_IFA_EMPLOYERS,
	ACCESS_IFA_EMPLOYERS_ERROR,
	ACCESS_IFA_EMPLOYERS_SUCCESS,
	GET_IFA_EMPLOYEES,
	GET_IFA_EMPLOYEES_SUCCESS,
	GET_IFA_EMPLOYERS,
	GET_IFA_EMPLOYERS_ERROR,
	GET_IFA_EMPLOYERS_SUCCESS,
} from "../../../actions/IFA/EmployersIFA/types";

const initial_state = {
	employers_loading: false,
	employers_loading_table: false,
	employees_table_loading: false,
	total: 0,
	per_page: 15,
	error_message: "",
	employers_ifa: [],
	employees: [],
	employerView: false
};

const EmployerIFAReducerStore = (state = initial_state, action = false) => {
	if (!action) return state;
	const {type = false} = action;

	switch (type) {
		case GET_IFA_EMPLOYERS:
		case GET_IFA_EMPLOYERS_SUCCESS:
		case GET_IFA_EMPLOYERS_ERROR:
		case ACCESS_IFA_EMPLOYERS:
		case ACCESS_IFA_EMPLOYERS_SUCCESS:
		case ACCESS_IFA_EMPLOYERS_ERROR:
		case GET_IFA_EMPLOYEES:
		case GET_IFA_EMPLOYEES_SUCCESS:
			return resolveReduxState(state, action);
		default:
			return state;
	}
}

export default EmployerIFAReducerStore;