import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @param token
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const CreateRequest = (data:any[] = false, token: string = ""): Promise<AxiosResponse<*>> =>
    Request.post(`/advisors/signup/${token}`, data);

export default CreateRequest;