import {Reducer} from 'react'
import * as PopupActions from "../../actions/Popup/types";

const initial_state: {
	title: String | null,
	content: Array<{ message: String | null, format: String | null }>,
	open: boolean,
	buttons: Array<{
		text: String | null,
		active: Boolean,
		onClick: Function<>
	}>,
} = {
	title: null,
	content: [],
	open: false,
	buttons: [],
}

const PopupReducer: Reducer = (state: initial_state = initial_state, action: any[] = false) => {
	if (!action) return state;
	const {type = false, content = null, title = null} = action;

	switch (type) {
		case PopupActions.POPUP_HIDE:
			return {...state, open: false};
		case PopupActions.POPUP_SHOW:
			return {...state, open: true, content: content || state.content};
		case PopupActions.POPUP_SET_CONTENT:
			return {...state, content};
		case PopupActions.POPUP_SET_TITLE:
			return {...state, title};

		default:
			return state;
	}
}

export default PopupReducer;