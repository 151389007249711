import GetIfaIndividuals from "./GetIfaIndividuals";
import ViewIndividual from "./ViewIndividual";
import GetFunds from "./GetFunds";
import ChangeFunds from "./ChangeFunds";
import RequestAccess from "./RequestAccess";

const IndividualIFA = {
	GetIfaIndividuals,
	ViewIndividual,
	GetFunds,
	ChangeFunds,
	RequestAccess,

};

export default IndividualIFA;