import {forceScrollUp, retrieveCollegiaAccessToken} from "../../utils/Helpers";
import ProfileService from "../../services/Profile";
import {
	EDITED_FIELDS_SET,
	EDITED_FIELDS_UPDATE,
	FETCH_PROFILE_DATA,
	LOGIN,
	RETRIEVE_NEW_PASSWORD,
	UPDATE_FIELDS_FAILURE,
	UPDATE_FIELDS_SUCCESS,
	DELETE_EMAIL_CONFIGURATIONS,
	UPLOAD_DDI_FILE, DOWNLOAD_PAYMENTS,
} from "./types";

import * as SnackBar from '../SnackBar'
import {warnAPIStatus} from '../SnackBar'
import {handleRequestError} from "../../utils/Actions";
import axios from "axios";
import FileDownload from "js-file-download";
import Request from "../../utils/Request";

/**
 * @param scrollUp
 * @param getMandate
 * @returns {(function(*): void)|*}
 */
export const me = (scrollUp: boolean = false, getMandate = true) => dispatch => {
	if (retrieveCollegiaAccessToken()) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
		});

		new ProfileService()
			.me()
			.then(response => {

				if (window.location.pathname !== "/sign-direct-debit" && window.location.pathname !== "/select-banks") {
					if (response?.data?.data?.prepare_emandate) {
						window.location.href = "/sign-direct-debit";
					}
				}

				if(
					response?.data?.data?.individualsOptOut?.length > 0 &&
					window.location.pathname !== "/" &&
					window.location.pathname !== "/main-page"
				) {
					window.location = "/";
				}

				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
					profile_data: {
						...response?.data?.data
					},
				});

				if (scrollUp) {
					forceScrollUp();
				}
			})
			.catch(error => {
				console.error(error);
				if (error.response) {
					if (error.response.status === 401 || error.response.status === 500) {
						localStorage.clear();
						window.location = "/login";
					}
				}

				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				});
			});
	}
};


/**
 * @param data
 * @param redirectUrl
 * @returns {(function(*): void)|*}
 */
export const login = (data = false, redirectUrl = "/main-page") => dispatch => {

	if (data) {
		dispatch({
			type: LOGIN,
			profile_loading: true,
			login_text: "",
		});

		window.sessionStorage.clear();
		window.localStorage.clear();

		sessionStorage.clear();
		localStorage.clear();

		localStorage.setItem("collegiaToken", "");
		window.localStorage.setItem("collegiaToken", "");

		sessionStorage.setItem("collegiaToken", "");
		window.sessionStorage.setItem("collegiaToken", "");

		new ProfileService()
			.login(data)
			.then(response => {
				const {
					data = false
				} = response;

				if (data.data) {
					const {
						token = ""
					} = data.data;

					if (token !== "") {

						localStorage.setItem("collegiaToken", token);
						sessionStorage.setItem("collegiaToken", token);

						window.localStorage.setItem("collegiaToken", token);
						window.sessionStorage.setItem("collegiaToken", token);

						setTimeout(() => {
							window.location.href = redirectUrl;
							dispatch({
								type: LOGIN,
								profile_loading: false,
								login_text: "",
							});
						}, 500);
					} else {
						dispatch({
							type: LOGIN,
							profile_loading: false,
							login_text: "Email / password wrong",
						});
					}
				} else {
					dispatch({
						type: LOGIN,
						profile_loading: false,
						login_text: "Email / password wrong",
					});
				}
			})
			.catch(error => {
				dispatch({
					type: LOGIN,
					profile_loading: false,
					login_text: "Email / password wrong",
				});
				console.error(error);
			});
	}
};

/**
 * @returns {function(*): Promise<void | void>}
 */
export const logout = () => dispatch =>
	new ProfileService()
		.logOut()
		.then(() => {
			localStorage.clear();
			sessionStorage.clear();
		})
		.catch(error => {
			console.error(error);
			console.error(error);
			if (error.response) {
				if (error.response.status === 401) {
					localStorage.clear();
					sessionStorage.clear();
				}
			}
		})
		.finally(() => window.location.href = "/login");


export const requestNewLogin = () => {
	localStorage.clear();
	window.location = "/login";
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const requestNewPassword = (data: any[] = false) => dispatch => {
	if (!data) dispatch(SnackBar.error("App error! Please contact the collegia team"))
	dispatch({
		type: RETRIEVE_NEW_PASSWORD,
		profile_loading: true,
		login_text: "",
	});

	new ProfileService()
		.requestNewPassword(data ?? false)
		.then(response => {
			if (response.data.data) {
				dispatch({
					type: RETRIEVE_NEW_PASSWORD,
					profile_loading: false,
					login_text: "",
				});
				dispatch(SnackBar.success(`An email has been sent to ${data.forgot_password.email}`))
				localStorage.setItem("passwordRetrieveEmail", data.forgot_password.email);
				window.location.href = "/forgot-password/request-sent";
			} else {
				dispatch({
					type: RETRIEVE_NEW_PASSWORD,
					profile_loading: false,
					login_text: "E-mail not found. Try again!",
				});
				dispatch(SnackBar.error("E-mail not found. Try again!"))
				localStorage.setItem("passwordRetrieveEmail", "");
			}
		})
		.catch(error => {
			dispatch({
				type: RETRIEVE_NEW_PASSWORD,
				profile_loading: false,
				login_text: "E-mail not found. Try again!",
			});
			// console.error(error);
			dispatch(SnackBar.error("E-mail not found. Try again!"))
			localStorage.setItem("passwordRetrieveEmail", "");
		})
};

/**
 *
 * @param token
 * @returns {function(*): void}
 */
export const checkForgotPasswordToken = (token: string = "") => dispatch => {
	if (token) {
		new ProfileService()
			.checkForgotPasswordToken(token ?? "")
			.then(response => response.data.data ? false : window.location.href = "/login")
			.catch(error => console.error(error));
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const passwordChangeTokenRequest = (data: any[] = false) => dispatch => {
	if (data) {
		new ProfileService()
			.updatePassword(data ?? false)
			.then(() => window.location.href = "/")
			.catch(error => console.error(error));
	}
};

export const forgotPasswordUpdatePassword = (data) => dispatch => {
	if (data) {
		new ProfileService()
			.forgotPasswordUpdatePassword(data ?? false)
			.then(() => window.location.href = "/")
			.catch(error => console.error(error));
	}
};

export const updateEmail = (email_data: any) => dispatch => {
	if (email_data) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
		});
		new ProfileService()
			.updateEmail(email_data)
			.then(({data}) => {
				switch (data.code) {
					case 200:
					case 201:
						dispatch({type: UPDATE_FIELDS_SUCCESS});
						dispatch(me());
						dispatch(SnackBar.success("Email has been changed!"));
						break;
					default:
						dispatch(warnAPIStatus(data.code, UPDATE_FIELDS_FAILURE));
						dispatch({
							type: FETCH_PROFILE_DATA,
							profile_loading: false,
						});
						break;
				}
			})
			.catch(error => dispatch(handleRequestError(UPDATE_FIELDS_FAILURE, error)),
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				}));
	}
};

/**
 * @param data
 * @returns {function(...[*]=)}
 */
export const updateAdvisor = (data: any) => dispatch => {
	if (data) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
		});
		new ProfileService()
			.updateAdvisor(data)
			.then(({data}) => {
				switch (data.code) {
					case 200:
					case 201:
						dispatch({type: UPDATE_FIELDS_SUCCESS});
						dispatch(me());
						dispatch(SnackBar.success("Your changes has been save!"));
						break;
					default:
						dispatch(warnAPIStatus(data.code, UPDATE_FIELDS_FAILURE));
						dispatch({
							type: FETCH_PROFILE_DATA,
							profile_loading: false,
						});
						break;
				}
			})
			.catch(error => dispatch(handleRequestError(UPDATE_FIELDS_FAILURE, error)),
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				}));
	}
};

/**
 * @param fieldname
 * @param value
 * @returns {{fieldname, type: string, value}}
 */
export const updateField = (fieldname, value) => ({
	type: EDITED_FIELDS_UPDATE,
	fieldname,
	value
});

/**
 * @param edited_fields
 * @returns {{edited_fields: {}, type: string}}
 */
export const setFields = (edited_fields = {}) => ({
	type: EDITED_FIELDS_SET,
	edited_fields
});

/**
 * @returns {(function(*): void)|*}
 * @param data
 */
export const updatePassword = (data: any) => dispatch => {
	if (data) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
		});
		new ProfileService()
			.updatePassword(data)
			.then(({data}) => {
				switch (data.code) {
					case 200:
					case 201:
						dispatch({type: UPDATE_FIELDS_SUCCESS});
						dispatch(me());
						dispatch(SnackBar.success("Your password has changed"))
						break;
					default:
						dispatch(warnAPIStatus(data.code, UPDATE_FIELDS_FAILURE))
						dispatch({
							type: FETCH_PROFILE_DATA,
							profile_loading: false,
						});
						break;
				}
			})
			.catch(error => dispatch(handleRequestError(UPDATE_FIELDS_FAILURE, error)),
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				}))
	}
};

/**
 * @returns {(function(*): void)|*}
 * @param data
 */
export const updateAddress = (data: any) => dispatch => {
	if (data) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
		});
		new ProfileService()
			.updateAddress(data)
			.then(({data}) => {
				switch (data.code) {
					case 200:
					case 201:
						dispatch({type: UPDATE_FIELDS_SUCCESS});
						dispatch(me());
						dispatch(SnackBar.success("Your address has been changed!"))
						break;
					default:
						dispatch(warnAPIStatus(data.code, UPDATE_FIELDS_FAILURE))
						dispatch({
							type: FETCH_PROFILE_DATA,
							profile_loading: false,
						});
						break;
				}
			})
			.catch(error => dispatch(handleRequestError(UPDATE_FIELDS_FAILURE, error)),
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				}))
	}
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const updateEmailConfiguration = (data = {}) => dispatch => {
	if (data) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
		});

		new ProfileService()
			.updateEmailConfiguration(data ?? {})
			.then(() => dispatch(me(true)))

			.catch(error => dispatch(handleRequestError(UPDATE_FIELDS_FAILURE, error)),
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				}))
	}
};

/**
 * @returns {(function(*): void)|*}
 * @param id
 */
export const deleteEmailConfiguration = (id: 0) => dispatch => {
	if (id) {
		dispatch({
			type: DELETE_EMAIL_CONFIGURATIONS,
			profile_loading: true,
		});

		new ProfileService()
			.deleteEmailConfiguration(id ?? 0)
			.then(() => dispatch(me(true)))

			.catch(error => dispatch(handleRequestError(DELETE_EMAIL_CONFIGURATIONS, error)),
				dispatch({
					type: DELETE_EMAIL_CONFIGURATIONS,
					profile_loading: false,
				}))
	}
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const uploadDdiFile = (data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: UPLOAD_DDI_FILE,
			upload_loading: true,
		});
		const formData = new FormData();
		formData.append("pdf", data?.pdf);
		new ProfileService()
			.uploadDdiFile(formData)
			.then(async response => {
				if (response?.data?.data) {
					dispatch({
						type: UPLOAD_DDI_FILE,
						upload_loading: false,
					});
					let webHookUrl;
					let slackData;
					if(process.env.REACT_APP_ENV === 'production'){
						webHookUrl = "https://hooks.slack.com/services/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";
						slackData = {
							"text": `New Ddi file has been uploaded in PROD!\n` +
								`Company Name: ${response?.data?.data[0]}\n` +
								`Primary Email: ${response?.data?.data[1]}\n` +
								`Account Type: ${response?.data?.data[2]}\n` +
								`Uploaded By: ${response?.data?.data[3]}\n` +
								`Contribution Date: ${response?.data?.data[4]}\n` +
								`Ddi Link: ${response?.data?.data[5]}\n`
						}
					} else {
						webHookUrl = "https://hooks.slack.com/services/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";
						slackData = {
							"text": `New Ddi file has been uploaded in DEV!\n` +
								`Company Name: ${response?.data?.data[0]}\n` +
								`Primary Email: ${response?.data?.data[1]}\n` +
								`Account Type: ${response?.data?.data[2]}\n` +
								`Uploaded By: ${response?.data?.data[3]}\n` +
								`Contribution Date: ${response?.data?.data[4]}\n` +
								`Ddi Link: ${response?.data?.data[5]}\n`
						}
					}

					let res = await axios.post(webHookUrl, JSON.stringify(slackData), {
						withCredentials: false,
						transformRequest: [(slackData, headers) => {
							if (headers) {
								if (headers.post) {
									delete headers.post["Content-Type"];
								}
							}
							return slackData;
						}]
					});
					if(res.status === 200){
						dispatch(SnackBar.success("Your file has been upload!"));
					}
					dispatch(me());
				}
			})
			.catch((response) => {
				dispatch({
					type: UPLOAD_DDI_FILE,
					upload_loading: false,
				});
				dispatch(SnackBar.error(response?.data?.data[0]));
			});
	}
};


/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const updateCompanyNumber = (id = false, data: any[] = false) => dispatch => {
	if (data) {
		dispatch({
			type: FETCH_PROFILE_DATA,
			profile_loading: true,
		});
		new ProfileService()
			.update(id, data)
			.then(async response => {
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				});
				if (response?.data?.data) {
					window.location.reload();
				}
			})
			.catch((response) => {
				dispatch({
					type: FETCH_PROFILE_DATA,
					profile_loading: false,
				});
				dispatch(SnackBar.error(response?.data?.data));
			});
	}
};

export const downloadPaymentReport  = (scheduleId) => dispatch => {
	if (scheduleId) {
		dispatch({
			type: DOWNLOAD_PAYMENTS,
			profile_loading: true,
		});

		Request.get(`/advisors/download-payment-file/${scheduleId}`, {
			responseType: "blob",
		})
			.then(response => {
				dispatch({
					type: DOWNLOAD_PAYMENTS,
					profile_loading: false,
				});
				FileDownload(
					response.data,
					`Reject payment - ${scheduleId}.csv`
				);
			})
			.catch(error => {
				dispatch({
					type: DOWNLOAD_PAYMENTS,
					profile_loading: false,
				});
				console.error(error);
			});
	}
};

