import {
	GET_IFA_STATS,
	GET_IFA_STATS_SUCCESS,
	GET_IFA_STATS_ERROR,
	ADD_BENEFICIARY,
	ADD_BENEFICIARY_SUCCESS,
	ADD_BENEFICIARY_ERROR,
} from "./types";
import * as SnackBar from "../../SnackBar";
import IFAService from "../../../services/IFA/IFA";

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const getKeyStats = (data = {}) => dispatch => {
	dispatch({
		type: GET_IFA_STATS,
		stats_loading: true,
	});

	new IFAService()
		.getKeyStats(data)
		.then((response) => {
			dispatch({
				type: GET_IFA_STATS_SUCCESS,
				stats: {
					...response?.data?.data
				},
				stats_loading: false,
			});
		})
		.catch(error => {
			dispatch({
				type: GET_IFA_STATS_ERROR,
				stats_loading: false,
			});
			console.error(data);
			dispatch(SnackBar.error(error?.response?.data?.message));
		});
};

/**
 * @param data
 * @returns {(function(*): void)|*}
 */
export const createBeneficiary = (data = {}) => dispatch => {
	dispatch({
		type: ADD_BENEFICIARY,
		stats_loading: true,
	});

	new IFAService()
		.createBeneficiary(data)
		.then((response) => {
			dispatch(SnackBar.success(response?.data?.data?.message));
			window.location.href = "/ifa-dashboard"
			dispatch({
				type: ADD_BENEFICIARY_SUCCESS,
				stats: response?.data?.data,
				stats_loading: false,
			});
		})
		.catch(error => {
			dispatch({
				type: ADD_BENEFICIARY_ERROR,
				stats_loading: false,
			});
			console.error(data);
			dispatch(SnackBar.error(error?.response?.data?.data?.error));
		});
};

