import Request from '../../request';
import AbstractServiceRequestsClass from "../../classes/AbstractServiceRequestsClass";
import {AxiosResponse} from "axios";

export default class BulkEmployersService extends AbstractServiceRequestsClass {
	constructor() {
		super(Request.BulkEmployersUploadDetails);
	}

	getBulkEmployersUploadDetails(file_upload_id): Promise<AxiosResponse> {
		if (this?.request) {
			return this.request.BulkEmployersUploadDetails(file_upload_id);
		}

		return Promise?.reject("Request wasn't completed.");
	}
}