import Request from "../../utils/Request";
import { AxiosResponse } from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<T>>}
 */
const DeleteEmployer = (data:any[] = false): Promise<AxiosResponse<T>> =>
{
	return Request.put("/advisors/delete-advisor-employer", {
		advisors: {
			employer_id: data.emp_id,
			advisor_id: data.adv_id,
			status: data.status,
		}
	});
}

/**
 * @returns {Promise<AxiosResponse<any>>}
 */
export default DeleteEmployer;
