import React, {useState} from "react";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

/**
 * @param props
 * @returns {JSX.Element}
 */
const GenericTermsAndConditions = (props): JSX.Element => {

	const [expanded, setExpanded] = useState(false);

	const handleChange = (panel) => {
		if (panel) {
			let isExpanded = false;
			if (panel !== expanded) {
				isExpanded = panel;
			} else {
				isExpanded = false;
			}

			setExpanded(isExpanded);
		}
	}

	return (
		<Grid
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			item
			container
		>
			<Grid
				className={"dialog-without-padding dialog-without-border-top"}
				item
			>
				<Grid
					lg={12}
					direction={"column"}
					alignItems={"center"}
					justifyContent={"center"}
					className={"big-picture-title-container"}
					container
					item
				>
					<Typography
						variant={"subtitle2"}
						component={"p"}
					>
						The big picture:
					</Typography>
				</Grid>
				<Grid
					lg={12}
					direction={"row"}
					className={"big-picture-container"}
					container
					item
				>
					<Accordion
						expanded={expanded === 'What does Collegia do?'}
						onChange={() => handleChange("What does Collegia do?")}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon />
							}
							aria-controls={"panel1bh-content"}
							id={"panel1bh-header"}
						>
							<Typography>
								What We Do
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								component={"p"}
								variant={"body1"}
							>
								Collegia is a personal pension scheme regulated by the Financial Conduct Authority and registered with HMRC.
								It can be used as an Automatic Enrolment scheme to provide benefits to eligible workers for the purposes of Auto Enrolment.
								Collegia does not provide pensions, financial, regulatory, tax, investment, payroll, accounting or business advisory services.
								If you have any questions about these topics, you must refer to your own trusted business advisors.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "What do you need to provide Collegia with?"}
						onChange={() => handleChange("What do you need to provide Collegia with?")}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon />
							}
							aria-controls={"panel2bh-content"}
							id={"panel2bh-header"}
						>
							<Typography>
								Information Required
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								component={"p"}
								variant={"body1"}
							>
								We require the workforce’s data and information that is necessary for us to operate the pension scheme.
								This data and information must be accurate, complete and updated within reasonable timescales.
								The information must be provided in a format required by Collegia – either via direct API integration with your payroll software or CSV upload.
								The assessment of the workforce under the Auto Enrolment law is your responsibility.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "How much does Collegia charge me?"}
						onChange={() => handleChange("How much does Collegia charge me?")}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon />
							}
							aria-controls={"panel3bh-content"}
							id={"panel3bh-header"}
						>
							<Typography >
								Collegia Charges
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								component={"p"}
								variant={"body1"}
							>
								£0, for every company (both employers and business advisors) in the UK.
								We reserve the right to charge you for costs and expenses in case of incomplete and inaccurate information provided by you in relation to the management of the pension scheme.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "How much am I contributing to my workforce?"}
						onChange={() => handleChange("How much am I contributing to my workforce?")}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon />
							}
							aria-controls={"panel4bh-content"}
							id={"panel4bh-header"}
						>
							<Typography >
								Minimum Level Of Contributions
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								component={"p"}
								variant={"body1"}
							>
								Every employer has to follow the rules set by Auto Enrolment legislation.
								Currently, the minimum is set at 8% of the employee’s qualifying earnings.
								The employer must pay at least 3% of this, but it can choose to pay more. For more information, please check The Pension Regulator’s website.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "Can I stop using Collegia?"}
						onChange={() => handleChange("Can I stop using Collegia?")}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon />
							}
							aria-controls={"panel5bh-content"}
							id={"panel5bh-header"}
						>
							<Typography>
								How To Stop Using Collegia
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								component={"p"}
								variant={"body1"}
							>
								You can tell us at any time, via the employer or advisor dashboard, that you do not wish to use Collegia anymore.
								However, contributions for the pay period in which you decide to stop using Collegia are still required to be paid.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "What happens to contributions that I have paid into another pension scheme?"}
						onChange={() => handleChange("What happens to contributions that I have paid into another pension scheme?")}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon />
							}
							aria-controls={"panel6bh-content"}
							id={"panel6bh-header"}
						>
							<Typography>
								Contributions Paid Into Another Pension Scheme
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								component={"p"}
								variant={"body1"}
							>
								Employees can request them to be transferred to Collegia or other pension providers.
								If they decide to do nothing, these contributions will continue to be managed by the other pension provider.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "How do I pay contributions?"}
						onChange={() => handleChange("How do I pay contributions?")}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon />
							}
							aria-controls={"panel7bh-content"}
							id={"panel7bh-header"}
						>
							<Typography>
								How Contributions Are Paid
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								component={"p"}
								variant={"body1"}
							>
								We do all the heavy lifting. You only need to sign a Direct Debit Instruction.
								Our systems automatically collect the contributions based on the information you have provided.
								Please remember that we only collect contributions for employees whose opt out period (30 days) has lapsed.
								This means that contributions for newly enrolled employees will be collected only 30 days after their enrolment.
							</Typography>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === "Open Banking & The Direct Debit Instruction"}
						onChange={() => handleChange("Open Banking & The Direct Debit Instruction")}
						className={"expansion-panel"}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMoreIcon />
							}
							aria-controls={"panel8bh-content"}
							id={"panel8bh-header"}
						>
							<Typography>
								Open Banking & The Direct Debit Instruction
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Typography
								component={"p"}
								variant={"body1"}
							>
								A DDI permits us to collect contributions directly from your bank account.
								This is a responsibility we do not take lightly. For that reason, we demand a high level of security when signing the DDI.
								Open Banking allows us to independently verify that the person signing the DDI has the required access and permission to perform the task.
								Also, the use of Open Banking permits us to avoid holding your banking details. We use a token to access the BACS network and trigger the collection procedures.
								This, in turn, adds a further security layer to the process.
							</Typography>
						</AccordionDetails>
					</Accordion>
				</Grid>
				<Grid
					className={"terms-and-conditions-buttons-container"}
					lg={12}
					alignContent={"center"}
					alignItems={"center"}
					direction={"column"}
					item
					container
				>
					<Grid
						lg={12}
						item
						container
					>
						<Button
							color={"primary"}
							variant={"contained"}
							className={'primary'}
							onClick={() => props.proceed(props.component, props.formValues, props?.token ?? "")}
						>
							I've read and accept it
						</Button>
					</Grid>
					<Grid
						lg={12}
						item
						container
					>
						<Button
							type={"button"}
							color={"primary"}
							variant={"outlined"}
							className={"opt-out"}
							onClick={() =>
								window.location.href="/login"
							}
						>
							I don't accept it
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
}


/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default GenericTermsAndConditions;