import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const GetOptOut = (data: any[] = {}): Promise<AxiosResponse<any>> =>
	Request.get(`/advisors/opt-out/get-opt-out/${data}`);


/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default GetOptOut;