import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';
import Wrapper from "../../layout/Logged/Wrapper";
import LoadingPanel from "../LoadingPanel";
import SectionGrid from "../../layout/SectionGrid";
import ContentGrid from "../../layout/ContentGrid";
import Typography from "@mui/material/Typography";
import AccountDataEditionPanel from "./AccountDataEditionPanel";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import * as Profile from "../../actions/Profile";


/**
 * @param header
 * @param headline
 * @param inputFields
 * @param buttons
 * @param loading
 * @returns {JSX.Element}
 * @constructor
 */
const AccountDataEditor: React.Component = ({
	header = '',
	headline = '',
	inputFields = [],
	buttons = [],
	loading = false,
}: {
	history: Object,
}): JSX.Element => {
	const
		{
			edited_fields = {}
		} = useSelector(state => state?.Profile);

	const dispatch = useDispatch(),
		createUpdater = fieldname => value => dispatch(Profile.updateField(fieldname, value));

	useEffect(() => { dispatch(Profile.me()) }, [
		dispatch,
	]);

	const history = useNavigate();

	if (loading) {
		return (
			<Wrapper>
				<LoadingPanel />
			</Wrapper>
		);
	}

	return (
		<Wrapper headerLink='return' history={history}>
			<SectionGrid className={"my-account-change-email-title"}>
				<ContentGrid className={"my-account-change-email-title"}>
					{headline.map((text, index) => (
						<Typography key={`headline-${index}`} variant="body1">
							{text}
						</Typography>
					))}
				</ContentGrid>
			</SectionGrid>
			<AccountDataEditionPanel header={header}>
				<CardContent>
					<Grid
						container
						spacing={2}
						direction={"column"}
						alignItems={"stretch"}
						justifyContent={"space-between"}>

						{
							inputFields
								.map(({ id, updater, value, label, disabled=false, error=false, type = id}, index) => (
									<Grid key={`field-${index}`} item lg={12} md={12} sm={12}>
										<FormControl fullWidth key={`field-${index}`}>
											<TextField
												fullWidth
												type={type}
												onChange={(event) => createUpdater(id)(event.target.value)}
												label={label}
												variant="outlined"
												error={error}
												name={id}
												disabled={disabled || false}
												defaultValue={value}
												id={id}
											/>
										</FormControl>
									</Grid>
								))
						}
					</Grid>
				</CardContent>
				<CardActions className={"my-account-change-email-buttons"}>
					{buttons.map(({
			              variant,
			              onClick,
			              text = '',
			              enabled = (inputFields
				              .filter(({ id }) => edited_fields[id] && edited_fields[id] !== '')
					              .length === inputFields.length
				              && !inputFields.map(({ error }) => error).includes(true))
		              }, index) => (
							<Button
								key={`button-${index}`}
								color="primary"
								variant={variant}
								disabled={!enabled}
								onClick={() => onClick({
									...edited_fields,
								})}>
								{text}
							</Button>
						)
					)
					}
				</CardActions>
			</AccountDataEditionPanel>
		</Wrapper>
	);
}

export default AccountDataEditor;