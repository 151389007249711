import Grid from "@mui/material/Grid";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TableCellItem = (props = {
	title: "",
	subtitle: "",
}): JSX.Element => {
	return (
		<Grid
			container
			{...props}
			direction={"column"}
		>
			<Grid
				item
				className={"table-cell-item-title"}
			>
				{props?.title}
			</Grid>

			<Grid
				item
				className={"table-cell-item-subtitle"}
			>
				{props?.subtitle}
			</Grid>
		</Grid>
	);
}

export default TableCellItem;