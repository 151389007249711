import CreateUser from "./CreateUser";
import DeleteUser from "./DeleteUser";
import EditUser from "./EditUser";
import CheckIsSuper from "./CheckIsSuper";

const UserRequests = {
	CreateUser,
	DeleteUser,
	EditUser,
	CheckIsSuper,
};

export default UserRequests;