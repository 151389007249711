import Grid from "@mui/material/Grid";
import HelpOutline from "@mui/icons-material/HelpOutline";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import {useState} from "react";
import GenericModal from "../GenericModal";
import { useNavigate } from 'react-router-dom';
/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const PrimaryHeader = (props = {
	backIcon: false,
	helpIcon: false,
	modalTitle: "",
	modalBody: {},
}): JSX.Element => {
	const [show, setShow] = useState(true);
	const [isModalVisible, setModalVisible] = useState(false);
	let history = useNavigate();
	return (
		<Grid
			className={"primary-header"}
			container
			alignItems={"center"}
			justifyContent={"space-evenly"}
		>
			<Grid
				item
				container
				justifyContent={"center"}
				alignItems={"center"}
				sm={1}
				xs={1}
			>
				{props?.backIcon ?
					<ArrowBackIos
						cursor={"pointer"}
						onClick={() => history("/")}
					/>
					: ""
				}
			</Grid>

			<Grid
				item
				container
				justifyContent={"center"}
				alignItems={"center"}
				sm={10}
				xs={10}
			>
				{props.children}
			</Grid>

			{isModalVisible &&
				<GenericModal
					show={show}
					setShow={setShow}
					setModalVisible={setModalVisible}
					title={props?.helpModal?.title}
					body={props?.helpModal?.body()}
				/>
			}

			<Grid
				item
				container
				justifyContent={"center"}
				alignItems={"center"}
				sm={1}
				xs={1}
			>
				{props?.helpIcon ?
					<HelpOutline
						cursor={"pointer"}
						onClick={() => setModalVisible(true)}
					/>
					: ""
				}
			</Grid>
		</Grid>
	);
}