import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Wrapper from "../layout/Logged/Wrapper";
import ContentGrid from "../layout/ContentGrid";
import SectionGrid from "../layout/SectionGrid";
import LoadingPanel from "../components/LoadingPanel";
import Typography from "@mui/material/Typography";
import * as Profile from '../actions/Profile'
import AccountDataEditionPanel from "../components/MyAccount/AccountDataEditionPanel";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeInfoScreen = (props = {
	history: () => {},
	showSandwich: true,
	notifications: [],
	headerLink: "home",
	visualizeNotification: () => {}
}): JSX.Element => {
	const dispatch = useDispatch(),
		{
			profile_data,
			profile_loading,
			pending_request
		} = useSelector(state => state?.Profile);


	useEffect(() => {
			dispatch(Profile.me())
		},
		[
			dispatch,
			profile_data?.title,
			profile_data?.type_of_business
		]);

	if (profile_loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}

	return (
		<Wrapper
			history={props?.history}
			showSandwich={props?.showSandwich}
			notifications={props?.notifications}
			headerLink={props?.headerLink}
			visualizeNotification={props?.visualizeNotification}
		>
			<SectionGrid id={"headline-section"}>
				<ContentGrid className={"headline"}>
					<Typography variant="body1">
						Here you can change your advisors information's
					</Typography>
				</ContentGrid>
			</SectionGrid>
			{
				pending_request ?
					<LoadingPanel />
					:
					<AccountDataEditionPanel header={"Advisor info"}>
						<Grid
							justifyContent={"center"}
							direction={"column"}
							container
							item
							xs={12}
							sm={12}
							md={12}
							lg={12}

						>
							<Grid
								marginBottom={4}
							>
								<TextField
									type={"text"}
									label="Type of business"
									variant="outlined"
									name={"company_legal_structure"}
									id={"company_legal_structure"}
									fullWidth
									value={profile_data?.type_of_business}
									disabled={true}
								/>
							</Grid>
							<Grid>
								<TextField
									type={"text"}
									label="Company Name"
									variant="outlined"
									name={"name_of_company"}
									id={"name_of_company"}
									value={profile_data?.title}
									fullWidth
									disabled={true}
								/>
							</Grid>
						</Grid>
					</AccountDataEditionPanel>
			}
		</Wrapper>
	);
}

export default ChangeInfoScreen;