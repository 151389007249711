import {GET_REFERRAL_INFORMATION} from "../../actions/Referral/types";
import {resolveReduxState} from "../../utils/Helpers";

const initial_state = {
	referral: false,
};

const ReferralStore = (state = initial_state, action = {
	type: "",
}) => {

	if (action) {
		switch (action.type) {
			case GET_REFERRAL_INFORMATION: return resolveReduxState(state, action);

			default:
				return state;
		}
	}

	return state;
};

export default ReferralStore;