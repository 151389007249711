import React from "react";
import {Grid, Link} from "@mui/material";
import logo from "../../images/logo-icon.svg";

/**
 * @stateless
 * @param props
 * @returns {*}
 */
const Footer = (props) => (
    <Grid
        id={"footer-logged"}
        xs={12}
        lg={12}
        md={12}
        direction={"column"}
        alignItems={"center"}
        alignContent={"center"}
        item
        container
        className={props.className || ""}
    >
        {props.includeLogo ?
            <Grid
                xs={12}
                md={12}
                sm={12}
                lg={12}
                item
            >
                <img
                    src={logo}
                    alt={"Collegia logo."}
                />
            </Grid> : null
        }
        <Grid
            xs={12}
            md={12}
            sm={12}
            lg={12}
            item
        >
            <Link href={"https://www.collegia.co.uk"} target={"_blank"}>
                <span>© {new Date().getFullYear()} Collegia Partners Limited. All rights reserved.</span>
            </Link>
        </Grid>
    </Grid>
);

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default Footer;