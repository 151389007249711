import React, {useState} from "react";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";

import ContentGrid from "../../layout/ContentGrid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import EmployersDialog from "../../components/Employers/EmployersDialog";

/**
 * @param profile_data
 * @param setFlagCreate
 * @param flagCreate
 * @param flagEdit
 * @param setFlagEdit
 * @param onPressCreate
 * @param onPressEditUser
 * @param updateUser
 * @param employers
 * @param getEmployerList
 * @param page
 * @param setPage
 * @param last_page
 * @param select_input_loading
 * @param handleToggle
 * @param open
 * @param checked
 * @param setOpen
 * @param users
 * @returns {JSX.Element}
 * @constructor
 */
const UserCreationPanel: React.Component = (
	{
		profile_data,
		setFlagCreate,
		flagCreate,
		flagEdit,
		setFlagEdit,
		onPressCreate,
		onPressEditUser,
		updateUser,
		employers,
		getEmployerList,
		page,
		setPage,
		last_page = 1,
		select_input_loading = 1,
		handleToggle,
		open,
		checked,
		setOpen,
		users,
		setChecked = () => {}
	}: {
		setFlagCreate: Function,
		flagCreate: Boolean,
		select_input_loading: Boolean,
		flagEdit: Boolean,
		setFlagEdit: Function,
		onPressCreate: Function,
		onPressEditUser: Function,
		getEmployerList: Function,
		employers: Array,
		last_page: number,
		users: Array,
		setChecked: Function,
	}
): JSX.Element => {
	//Create User Hooks
	const [forename, setForename] = useState('');
	const [middlename, setMiddlename] = useState('');
	const [surname, setSurname] = useState('');
	const [email, setEmail] = useState('');
	const [selectedOption, setSelectedOption] = useState('');
	const [is_super, setIsSuper] = useState(profile_data?.is_super ?? false);

	//Update User Hooks
	const [updateForename, setUpdateForename] = useState(updateUser.forename);
	const [updateMiddleName, setUpdateMiddleName] = useState(updateUser.middle_name);
	const [updateSurname, setUpdateSurname] = useState(updateUser.surname);

	const [updateEmail, setUpdateEmail] = useState(updateUser.email);
	const [updateUserRole, setUpdateUserRole] = useState(isAccountOwner(updateUser.is_super));

	const userRole = [
			"Advisor (Standard)",
			"Advisor (Account Owner)"
		],
		roleDescription = [
			"A Standard user can add new employers " +
			"and manage their Collegia accounts.\n A standard user can also upload data on behalf of the " +
			"corporate clients. A standard user can not manage other users’ accounts and can be restricted " +
			"in terms of the employers he or she is able to manage. If a new standard user is created, " +
			"an account owner advisor can decide which employers the standard user has visibility over.",

			"An Account Owner will always see all the employers " +
			"related to that advisor account and manage all the " +
			"employers associated with that advisor account. " +
			"The Account Owner can also delete the advisor account and can manage other users’ accounts.",
		],
		inputFields = [
			{id: 'forename', required: true, value: forename, updater: setForename, label: 'Forename'},
			{id: 'middle_name', required: false, value: middlename, updater: setMiddlename, label: 'Middle Name'},
			{id: 'surname', required: true, value: surname, updater: setSurname, label: 'Surname'},
			{id: 'email', required: true, value: email, updater: setEmail, label: 'E-Mail'},
		],
		updateFields = [
			{
				id: 'forename',
				required: true, value:
				updateForename,
				updater: setUpdateForename,
				label: 'Forename',
			},
			{
				id: 'middle_name',
				required: false,
				value: updateMiddleName,
				updater: setUpdateMiddleName,
				label: 'Middle Name'
			},
			{id: 'surname', required: true, value: updateSurname, updater: setUpdateSurname, label: 'Surname'},
			{id: 'email', required: false, value: updateEmail, updater: setUpdateEmail, label: 'E-Mail'},
		],
		advisors = {
			advisor_id: profile_data.id,
			forename: forename,
			middle_name: middlename,
			surname: surname,
			email: email,
			is_super: is_super,
			selectedEmployers: checked,
		},
		updateAdvisor = {
			id: updateUser.id,
			advisor_id: profile_data.id,
			forename: updateForename,
			middle_name: updateMiddleName,
			surname: updateSurname,
			is_super: is_super,
			selectedEmployers: checked,
		};

	function isAccountOwner(is_super) {
		if (is_super === true) {
			return "Advisor (Account Owner)";
		}
		return "Advisor (Standard)";
	};

	function handleSelectedOption(event) {
		let inputOption = event.target.value;
		setSelectedOption(inputOption);
		setUpdateUserRole(inputOption);

		if (inputOption === null || inputOption === "" || inputOption === "Advisor (Standard)") {
			setIsSuper(false);
		} else {
			setIsSuper(true);
		}
	}

	function disableButtonCreateUser(forename, surname, userRole, email: string = ""): boolean {
		const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let checkEmail = regexEmail.test(email.toLowerCase());

		return !(forename === "" || surname === "" || userRole === "" || checkEmail === false);
	}

	return (
		<Grid>
			<ContentGrid
				direction={"column"}
				alignContent={"flex-start"}
				item
				container
			>
				{(flagEdit === true)
					?
					<Typography
						variant={"h4"}
						style={{marginTop: 24, color: '#3C69E4'}}
					>
						Update user
					</Typography>
					:
					<Typography
						variant={"h4"}
						style={{marginTop: 24, color: '#3C69E4'}}
					>
						Create a new user
					</Typography>
				}
			</ContentGrid>

			<ContentGrid
				spacing={1}
				direction={"row"}
				alignItems={"stretch"}
				justifyContent={"space-between"}
				className={"container-wrapper"}
				item
				container
			>
				<Grid
					sm={6}
					xs={12}
					item
				>
					<Card>
						<CardContent>
							<form noValidate autoComplete="off">
								<Grid
									spacing={2}
									direction={"column"}
									alignItems={"stretch"}
									justifyContent={"space-between"}
									container
								>
									<Grid
										lg={12}
										md={12}
										sm={12}
										item
									>
										{
											(flagEdit === true)
												?
												updateFields.map((
													{
														id_field,
														updater,
														value,
														label,
													}, index) => (
													<Grid
														key={`field-${index}`}
														lg={12}
														md={12}
														sm={12}
														item
													>
														<FormControl fullWidth>
															<TextField
																fullWidth
																type={id_field}
																onChange={(event) =>
																	updater(event.target.value)
																}
																disabled={label === "E-Mail"}
																label={label}
																variant={"outlined"}
																name={id_field}
																defaultValue={value}
																id={id_field}
																style={{marginBottom: 10}}
															/>
														</FormControl>
													</Grid>
												))
												:
												inputFields.map((
													{
														id_field,
														updater,
														value,
														label
													}, index) => (
													<Grid
														key={`field-${index}`}
														lg={12}
														md={12}
														sm={12}
														item
													>
														<FormControl fullWidth>
															<TextField
																fullWidth
																type={id_field}
																onChange={(event) =>
																	updater(event.target.value)
																}
																label={label}
																variant={"outlined"}
																name={id_field}
																defaultValue={value}
																id={id_field}
																style={{marginBottom: 10}}
															/>
														</FormControl>
													</Grid>
												))

										}
										<Grid
											lg={12}
											md={12}
											sm={12}
											item
										>
											<FormControl
												fullWidth
												variant={"outlined"}
												style={{marginBottom: 30}}
											>
												{
													(flagEdit === true)
														?
														<TextField
															onChange={event => handleSelectedOption(event)}
															id={"userRole"}
															select
															name={"userRole"}
															value={updateUserRole}
															label={"User Role"}
															variant={"outlined"}
															fullWidth
															disabled={
																!profile_data?.userLogged?.is_super ||
																users.length === 1
															}
														>
															{userRole.map((option, index) => (
																<MenuItem key={index} value={option}>
																	{option}
																</MenuItem>
															))}
														</TextField>
														:
														<TextField
															onChange={event => handleSelectedOption(event)}
															id={"userRole"}
															name={"userRole"}
															value={selectedOption}
															label={"User Role"}
															variant={"outlined"}
															fullWidth
															select
														>
															{userRole.map((option, index) => (
																<MenuItem key={index} value={option}>
																	{option}
																</MenuItem>
															))}
														</TextField>
												}
											</FormControl>
										</Grid>
									</Grid>

									{
										(
											selectedOption === "Advisor (Standard)" ||
											updateUserRole === "Advisor (Standard)"
										) && profile_data?.userLogged?.is_super && (
											<EmployersDialog
												employers={employers}
												getEmployerList={getEmployerList}
												last_page={last_page ?? 1}
												select_input_loading={select_input_loading ?? false}
												page={page}
												setPage={setPage}
												open={open}
												setOpen={setOpen}
												handleToggle={handleToggle}
												checked={checked}
												managedEmployers={updateUser?.managed_employers}
												setChecked={setChecked}
											/>
										)
									}
								</Grid>
							</form>
						</CardContent>
						<CardActions style={{padding: 16}}>
							<Grid
								justifyContent={"space-between"}
								direction={"row"}
								spacing={2}
								container
							>
								<Grid
									lg={6}
									md={6}
									sm={6}
									item
								>
									{(flagEdit === true)
										?
										<Button
											fullWidth
											variant={"outlined"}
											color={"primary"}
											onClick={() => {
												setFlagCreate(!flagCreate);
												setFlagEdit(!flagEdit);
											}}>
											Cancel
										</Button>
										:
										<Button
											fullWidth
											variant={"outlined"}
											color={"primary"}
											onClick={() => setFlagCreate(!flagCreate)}>
											Cancel
										</Button>
									}
								</Grid>
								<Grid
									lg={6}
									md={6}
									sm={6}
									item
								>
									{(flagEdit === true)
										?
										<Button
											fullWidth
											variant={"contained"}
											color={"primary"}
											disabled={!disableButtonCreateUser(
												updateForename ?? "",
												updateSurname ?? "",
												updateUserRole ?? "",
												updateEmail ?? "",
											)}
											onClick={() => onPressEditUser(updateAdvisor)}
										>
											Update User
										</Button>
										:
										<Button
											fullWidth
											variant={"contained"}
											color={"primary"}
											disabled={!disableButtonCreateUser(
												forename ?? "",
												surname ?? "",
												selectedOption ?? "",
												email ?? ""
											)}
											onClick={() => onPressCreate(advisors)}
										>
											Create User
										</Button>
									}
								</Grid>
							</Grid>
						</CardActions>
					</Card>
				</Grid>
				<Grid
					sm={6}
					xs={12}
					item
				>
					<Card style={{height: '100%'}}>
						<CardHeader title={"Role Descriptions"}/>
						<CardContent>
							{userRole.map((option, index) => (
								<div
									key={index}
									style={{marginBottom: 10}}
								>
									<Typography
										variant={"subtitle1"}
									>
										{option}
									</Typography>
									<Typography
										variant={"caption"}
									>
										{roleDescription[index]}
									</Typography>
								</div>
							))}
						</CardContent>
					</Card>
				</Grid>
			</ContentGrid>
		</Grid>
	);
};

export default UserCreationPanel;