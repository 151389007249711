import React, {useEffect, useState} from "react";
import {connect} from "react-redux";

import {
	removeDefaultLoggedLayout,
	validateEmail,
} from "../utils/Helpers";

import Wrapper from "../layout/Wrapper";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Logo from "../images/logo-icon.svg";
import MinimalFooter from "../layout/MinimalFooter";
import {setupSignUp, signUp, verifyEmail} from "../actions/SignUp";
import EmailDetail from "../components/SignUp/EmailDetail";
import GenericTermsAndConditions from "../components/GenericTermsAndConditions";
import BodyText from "../components/BodyText";
import AdvisorForm from "../components/AdvisorForm";
import {SIGNUP_VERIFY_EMAIL_ADDRESS} from "../actions/SignUp/type";
import FindCompanyOrManually from "../components/FindCompanyOrManually";
import FindAddress from "../components/FindAddress";
import {findByReferralCode} from "../actions/Referral";
import {useLocation, useNavigate} from "react-router-dom";

/**
 * @param stepIndex
 * @param setState
 * @private
 */
const _proceedStep = (stepIndex = 0, setState) => setState(stepIndex);

/**
 * @param enterManually
 * @param setFormValues
 * @param formValues
 * @param formValues
 * @returns {JSX.Element}
 * @constructor
 */
const _fillTypeOfCompanies = (enterManually, setFormValues, formValues) => {
	if (enterManually) {
		setFormValues({
			...formValues,
			company_legal_structure_options: [
				{title: "Limited Company"},
				{title: "Limited Partnership"},
				{title: "Limited Liability Partnership"},
				{title: "Unincorporated Association"},
				{title: "Ordinary Business Partnership"},
				{title: "Sole Trader"},
				{title: "Charity"},
			],
		});
	} else {
		setFormValues({
			...formValues,
			company_legal_structure_options: [
				{title: "Private unlimited company"},
				{title: "Private limited company"},
				{title: "Public limited company"},
				{title: "Old public company"},
				{title: "Private Limited Company by guarantee without share capital, use of 'Limited' exemption"},
				{title: "Limited partnership"},
				{title: "Private limited by guarantee without share capital"},
				{title: "Converted or closed"},
				{title: "Private unlimited company without share capital"},
				{title: "Private Limited Company, use of 'Limited' exemption"},
				{title: "Protected cell company"},
				{title: "Assurance company"},
				{title: "Overseas company"},
				{title: "European Economic Interest Grouping (EEIG)"},
				{title: "Investment company with variable capital"},
				{title: "Registered society"},
				{title: "Industrial and Provident society"},
				{title: "Northern Ireland company"},
				{title: "Credit union (Northern Ireland)"},
				{title: "Limited liability partnership"},
				{title: "Royal charter company"},
				{title: "Unregistered company"},
				{title: "European public limited liability company (SE)"},
				{title: "UK establishment company"},
				{title: "Scottish qualifying partnership"},
				{title: "Charitable incorporated organisation"},
				{title: "Scottish charitable incorporated organisation"},
				{title: "Further education or sixth form college corporation"}
			],
		});
	}
}

/**
 * @param section
 * @param state
 * @param setState
 * @private
 */
const _readTermsAndConditions = (section = "", state, setState) => {
	const {terms_and_conditions = []} = state;

	const exist = terms_and_conditions.filter((t) => t.section === section);
	if (exist.length === 0) {
		terms_and_conditions.push({
			section,
			read: true,
		});

		let expanded;
		if (section !== state.expanded) {
			expanded = section;
		} else {
			expanded = false;
		}

		setState({
			terms_and_conditions,
			expanded,
		});
	}
};

/**
 * @param props
 * @param formValues
 * @param token
 * @private
 */
const _agreedTermsAndConditions = (props = {
	signUp: (advisors = {
		forename: "",
		middle_name: "",
		surname: "",
		email: "",
		password: "",
		telephone: "",
		title: "",
		type_of_business: "",
		address: "",
		address_1: "",
		address_2: "",
		address_3: "",
		address_4: "",
		postal_code: "",
		country: "",
		read_terms: [],
	}) => (advisors),
}, formValues = {
	name: "",
	middle_name: "",
	surname: "",
	email: "",
	password: "",
	telephone: "",
	company_name: "",
	address_1: "",
	address_2: "",
	address_3: "",
	address_4: "",
	postal_code: "",
	country: "",
	company_legal_structure: "",
	terms_and_conditions: [],
}, token: string = "") => {
	const {
		name = "",
		middle_name = "",
		surname = "",
		email = "",
		password = "",
		telephone = "",
		company_name = "",
		company_legal_structure = "",
		address_1 = "",
		address_2 = "",
		address_3 = "",
		address_4 = "",
		postal_code = "",
		country = "",
		terms_and_conditions = [],
	} = formValues;

	props.signUp({
		advisors: {
			forename: name,
			middle_name: middle_name,
			surname: surname,
			email: email,
			password: password,
			telephone: telephone,
			title: company_name,
			type_of_business: company_legal_structure,
			address_1: address_1,
			address_2: address_2,
			address_3: address_3,
			address_4: address_4,
			postal_code: postal_code,
			country: country,
			read_terms: terms_and_conditions,
			referral: props?.referral,
		},
	}, props?.referral ? null : token ? token : "");
};

/**
 * @param props
 * @param state
 * @param token
 * @returns {boolean}
 * @private
 */
const _verifyEmail = (
	props = {
		checkEmailAddress: (advisors: {
			email: "",
		}) => (advisors)
	},
	state = {
		email: ""
	},
	token: string = ""
) => {
	const {email = ""} = state;

	if (!validateEmail(email)) {
		return false;
	}

	props.checkEmailAddress({
		advisors: {
			email: email,
		},
	}, token);
};

/**
 * @param stepIndex
 * @param setStepIndex
 * @param props
 * @param formValues
 * @param setFormValues
 * @param token
 * @param addressComponents
 * @param setAddressComponents
 * @returns {JSX.Element|*[]}
 * @private
 */
const _renderContent = (
	stepIndex = 0,
	setStepIndex,
	props,
	formValues,
	setFormValues,
	token,
	addressComponents,
	setAddressComponents
): JSX.Element | *[] => {
	const {terms_and_conditions = [], expanded = ""} = props;
	const [enterManually, setEnterManually] = useState(false);
	const [apiTypeOfBusiness, setApiTypeOfBusiness] = useState("");


	switch (stepIndex) {
		case 0:
			return (
				<EmailDetail
					setFormValues={setFormValues}
					formValues={formValues}
					component={props}
					email={formValues.email}
					history={props.history}
					proceedStep={() =>
						_verifyEmail(props, formValues, token)
					}
					errorMessage={props.error_message ?? ""}
					referral={props?.referral}
				/>
			);

		case 1:
			return (
				<AdvisorForm
					setFormValues={setFormValues}
					component={props}
					name={formValues.name}
					middle_name={formValues.middle_name}
					surname={formValues.surname}
					company_name={formValues.company_name}
					company_legal_structure={formValues.company_legal_structure}
					formValues={formValues}
					history={props.history}
					setStepIndex={setStepIndex}
					stepIndex={stepIndex}
					proceedStep={
						_proceedStep
					}
				/>
			);
		case 2:
			return (
				<FindCompanyOrManually
					formValues={formValues}
					history={props.history}
					setStepIndex={setStepIndex}
					stepIndex={stepIndex}
					proceedStep={
						_proceedStep
					}
					enterManually={enterManually}
					setEnterManually={setEnterManually}
					setFormValues={setFormValues}
					fillTypeOfCompanies={_fillTypeOfCompanies}
					setApiTypeOfBusiness={setApiTypeOfBusiness}
				/>
			);

		case 3:
			return (
				<FindAddress
					history={props.history}
					setFormValues={setFormValues}
					formValues={formValues}
					address_1={formValues.address_1}
					address_2={formValues.address_2}
					postal_code={formValues.postal_code}
					address_3={formValues.address_3}
					address_4={formValues.address_4}
					country={formValues.country}
					company_legal_structure={formValues.company_legal_structure}
					addressComponents={addressComponents}
					setAddressComponents={setAddressComponents}
					setStepIndex={setStepIndex}
					stepIndex={stepIndex}
					proceedStep={
						_proceedStep
					}
					enterManually={enterManually}
					setEnterManually={setEnterManually}
					token={token}
					fillTypeOfCompanies={_fillTypeOfCompanies}
					apiTypeOfBusiness={apiTypeOfBusiness}
				/>
			);

		case 4:
			return (
				<Grid
					id={"initialSetUpModalTermsAndConditions"}
					xs={12}
					md={12}
					lg={12}
					sm={12}
					alignItems={"center"}
					alignContent={"center"}
					direction={"column"}
					className={"modal-container"}
					item
					container
				>
					<Grid
						xs={12}
						md={12}
						lg={12}
						sm={12}
						item
						container
					>
						<BodyText/>
					</Grid>
					<GenericTermsAndConditions
						handleChange={_readTermsAndConditions}
						readTerms={terms_and_conditions ?? []}
						expanded={expanded ?? ""}
						noProceed={_proceedStep}
						setStepIndex={setStepIndex}
						stepIndex={stepIndex}
						formValues={formValues}
						component={props}
						proceed={_agreedTermsAndConditions}
						history={props.history}
						token={token}
						goBackButton
					/>
				</Grid>
			);

		default:
			return [];
	}
};

/**
 * @param setup_data
 * @param setFormValues
 * @private
 */
const _replaceFormValues = (
	setup_data = {
		software_advisor: {
			advisor: {
				users: [],
			}
		}
	},
	setFormValues = () => {
	}
) => {
	if (setup_data?.id) {

		const {
			advisor = {}
		} = setup_data?.software_advisor;
		const address = setup_data?.software_advisor?.address;
		if (advisor?.id) {
			setFormValues({
				email: advisor?.email ?? "",
				telephone: advisor?.telephone ?? "",
				company_name: advisor?.title,
				company_legal_structure: advisor?.type_of_business ?? "",
				surname: advisor?.users[0]?.surname ?? "",
				name: advisor?.users[0]?.forename ?? "",
				middle_name: advisor?.users[0]?.middle_name ?? "",
				company_legal_structure_options: [
					{title: "Limited Company"},
					{title: "Limited Partnership"},
					{title: "Limited Liability Partnership"},
					{title: "Unincorporated Association"},
					{title: "Ordinary Business Partnership"},
					{title: "Sole Trader"},
					{title: "Charity"},
				],
				address_1: address?.address_1 ?? "",
				address_2: address?.address_2 ?? "",
				address_3: address?.address_3 ?? "",
				address_4: address?.address_4 ?? "",
				postal_code: address?.postal_code ?? "",
				country: address?.country ?? "",
			});
		}
	}
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SignUp(props) {
	const [formValues, setFormValues] = useState({
			terms_and_conditions: [],
			email: "",
			name: "",
			surname: "",
			middle_name: "",
			password: "",
			confirm_password: "",
			telephone: "44",
			company_name: "",
			company_legal_structure: "",
			company_number: "",
			address_1: "",
			address_2: "",
			address_3: "",
			address_4: "",
			postal_code: "",
			country: "GB",
			stepIndex: 0,
			company_legal_structure_options: [],
		}),
		[stepIndex, setStepIndex] = useState(0),
		[addressComponents, setAddressComponents] = useState([]),
		history = useNavigate();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const token = queryParams.get('id'),
		{
			setup_data = [],
			requestedFetchSetupToken = false,
			setupSignUp,
			findByReferralCode,
		} = props;

	useEffect(() => {
		if (token?.length > 14) {
			setupSignUp(token ?? "");
		}
	}, [
		setupSignUp,
		token,
	]);

	useEffect(() => {

		if (token?.length > 0 && token?.length < 14) {
			findByReferralCode(token);
		}
	}, [
		findByReferralCode,
		token
	]);

	useEffect(() => {
		removeDefaultLoggedLayout(false, false);
		const menu = document.getElementById("menu");

		if (menu) {
			menu.style.display = "none";
		}

		if (!props.verify_email) {
			_proceedStep(stepIndex + 1, setStepIndex);
			props.cleanErrorMessage();
			_fillTypeOfCompanies(false, setFormValues, formValues);
		}
		if (requestedFetchSetupToken && setup_data?.id && stepIndex === 0) {
			_replaceFormValues(setup_data, setFormValues);
		}
	}, [
		props,
		stepIndex,
		setup_data,
		requestedFetchSetupToken,
		formValues
	]);

	return (
		<Wrapper>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"signup-wrapper"}>
					<Grid
						xs={12}
						lg={12}
						md={12}
						sm={12}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						id={"header"}
						container
						item
						onClick={() => history("/login")}
					>
						<img src={Logo} alt={"Welcome Collegia"}/>
					</Grid>
					{_renderContent(
						stepIndex,
						setStepIndex,
						props,
						formValues,
						setFormValues,
						token,
						addressComponents,
						setAddressComponents
					)}

					<MinimalFooter/>
				</div>
			</Slide>
		</Wrapper>
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = (state) => {
	const {
		SignUp,
		ReferralStore,
	} = state;

	return {
		...SignUp,
		...ReferralStore,
	}
};

/**
 * @param dispatch
 * @returns {{checkEmailAddress: (function(*[]=): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
	checkEmailAddress: (
		data: any[] = false,
		token: string = ""
	) => dispatch(verifyEmail(data ?? false, token ?? "")),
	signUp: (
		data = false,
		token: string = ""
	) => dispatch(signUp(data ?? false, token ?? "")),
	cleanErrorMessage: () => dispatch({
		type: SIGNUP_VERIFY_EMAIL_ADDRESS,
		error_message: "",
		verify_email: true,
	}),
	setupSignUp: (token: string = "") => dispatch(setupSignUp(token ?? "")),
	findByReferralCode: (code: string = "", type = "Advisor") => dispatch(findByReferralCode(code, type)),
});

export default connect(mapStateToProps ?? {}, mapDispatchToProps ?? {})(SignUp);