import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @param page
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const GetBulkEmployersHistory = (data, page): Promise<AxiosResponse<any>> =>
	Request.post(`/advisors/bulk-employers-history?page=${page}`, data);

export default GetBulkEmployersHistory;