import {connect} from "react-redux";
import React, {useEffect} from "react";
import Wrapper from "../layout/Logged/Wrapper";
import {downloadPaymentReport, me} from "../actions/Profile";
import LoadingPanel from "../components/LoadingPanel";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import PaymentCollectionTable from "../components/PaymentCollection/PaymentCollectionTable";
import Grid from "@mui/material/Grid";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PaymentCollections = ({
	profile_data = [],
	history = {},
	profile_loading = false,
	me = () => {},
	downloadPaymentReport = () => {},
}) => {

	useEffect(() => {
		me();
	}, [me]);

	if (profile_loading) {
		return (
			<LoadingPanel/>
		);
	}

	return (
		<Wrapper history={history} profile_data={profile_data}>
			<Grid>
				<PrimaryHeader
					backIcon
					helpIcon
				>
					Payment Collections
				</PrimaryHeader>
				<PaymentCollectionTable
					profile_data={profile_data}
					profile_loading={profile_loading}
					downloadPaymentReport={downloadPaymentReport}
				/>
			</Grid>
		</Wrapper>
	);
};

export default connect(
	(state) => ({
		...state.Profile,
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
		downloadPaymentReport: (scheduleId) => dispatch(downloadPaymentReport(scheduleId)),
	})
)(PaymentCollections);
