import React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Address = ({
	company_trading_address_line_1 = "",
	company_trading_premises = "",
	company_trading_locality = "",
	company_trading_country = "",
	trading_address_postcode = "",

    setCompanyTradingCountry = () => {},
	setTradingAddressPostcode = () => {},
	setCompanyTradingAddressLine1 = () => {},
	setCompanyTradingPremises = () => {},
	setTradingLocality = () => {},
}): JSX.Element => (
	<Box
		component={"div"}
	>
		<FormControl
			className={"signup-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setCompanyTradingAddressLine1(event.target.value)}
				label={"Address line 1*"}
				variant={"outlined"}
				autoFocus
				name={"company_trading_address_line_1"}
				id={"company_trading_address_line_1"}
				value={company_trading_address_line_1 ?? ""}
			/>
		</FormControl>
		<FormControl
			className={"signup-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setCompanyTradingPremises(event.target.value)}
				label={"Address line 2"}
				variant={"outlined"}
				autoFocus
				name={"company_trading_premises"}
				id={"company_trading_premises"}
				value={company_trading_premises ?? ""}
			/>
		</FormControl>
		<FormControl
			className={"signup-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setTradingLocality(event.target.value)}
				label={"City*"}
				variant={"outlined"}
				autoFocus
				name={"company_trading_locality"}
				id={"company_trading_locality"}
				value={company_trading_locality ?? ""}
			/>
		</FormControl>

		<FormControl
			className={"signup-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setCompanyTradingCountry(event.target.value)}
				label={"Country*"}
				variant={"outlined"}
				autoFocus
				name={"company_trading_country"}
				id={"company_trading_country"}
				value={company_trading_country ?? ""}
			/>
		</FormControl>
		<FormControl
			className={"login-form-control"}
			fullWidth
			style={{marginBottom: 30}}
		>
			<TextField
				type={"text"}
				onChange={(event) =>
					setTradingAddressPostcode(event.target.value)}
				label={"Trading address postcode*"}
				variant={"outlined"}
				name={"trading_address_postcode"}
				id={"trading_address_postcode"}
				value={trading_address_postcode ?? ""}
			/>
		</FormControl>
	</Box>
);

export default Address;