import React from "react";

import {useDispatch, useSelector} from "react-redux";
import AccountDataEditor from "../components/MyAccount/AccountDataEditor";
import {validateEmail} from "../utils/Helpers";
import {updateEmail} from "../actions/Profile";
import {useNavigate} from "react-router-dom";


/**
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeEmail: React.Component = (): JSX.Element => {
	const {
		profile_data,
		profile_loading,
		pending_request,
		edited_fields = {}
		} = useSelector(state => state?.Profile),
		{
			new_email,
		} = edited_fields,
		dispatch = useDispatch(),
		{
			email = ''
		} = profile_data;
	const navigate = useNavigate();
	function updateEmailFunction(users_advisors_id, advisors_id, new_email) {
		let data = {
			advisors_users_id: users_advisors_id,
			advisors_id: advisors_id,
			email: new_email,
		};
		dispatch(updateEmail(data));
	}
	
	return (
		<AccountDataEditor
			headline={[
				'Here you can change your e-mail address',
				<br />,
				'Follow the steps below'
			]}
			header={"Change your e-mail"}
			loading={pending_request || profile_loading}
			edited_fields={edited_fields}
			initialFields={{ current_email: email }}
			inputFields={[
				{
					id: 'current_email',
					value: profile_data?.userLogged?.email,
					label: 'Current e-mail address',
					disabled: true,
				},
				{
					id: 'new_email',
					value: '',
					label: 'New e-mail address',
					error: (!validateEmail(new_email) && new_email) ? true : false
				},
			]}
			buttons={[
				{
					variant: 'outlined',
					onClick: () => navigate('/my-account'),
					text: 'CANCEL',
					enabled: true
				},
				{
					onClick: () => updateEmailFunction(profile_data.userLogged.id,profile_data.userLogged.advisor_id,new_email),
					text: 'SAVE',
					variant: 'contained',
					enabled: (validateEmail(new_email))
				}
			]}
		/>
	);
}

export default ChangeEmail;