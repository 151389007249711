import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const WrongInfo = (props = {
	history: {
		push: () => {
		},
	}
}): JSX.Element => {

	return (
		<Grid
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			justifyContent={"center"}
			xs={12}
			sm={12}
			md={12}
			lg={12}
			item
			container
		>
			<FormControl
				className={"signup-form-control-2 forgot-password"}
				fullWidth
				align={"center"}
			>
				<small>Wrong info?</small>
			</FormControl>

			<FormControl
				className={"signup-form-control-2 forgot-password"}
				fullWidth
				align={"center"}
			>
			<span>
				<Button
					variant="text"
					color="primary"
					style={{
						textTransform: "lowercase",
						padding: 0,
						minHeight: 0,
						minWidth: 0,
					}}
					onClick={() => props.history("/add-new-company")}
				>
					Search again
				</Button>
				{"   "}/{"   "}
				<Button
					variant="text"
					color="primary"
					style={{
						textTransform: "lowercase",
						padding: 0,
						minHeight: 0,
						minWidth: 0,
					}}
					onClick={() => props.history("/add-new-company", {
						state: {
							enterCompany: "block",
						},
					})}
				>
					enter manually
				</Button>
			</span>
			</FormControl>
		</Grid>
	);
}

WrongInfo.propTypes = {
	history: PropTypes.shape({
		history: PropTypes.shape({
            push: PropTypes.func,
        }),
	}).isRequired,
};

export default WrongInfo;