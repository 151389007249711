import React from 'react';

import Routes from "./utils/Routes";
import { retrieveCollegiaAccessToken } from "./utils/Helpers";
import Popup from './components/Popup';

export default class App extends React.Component {
    _logOutInterval;

    /**
     * @returns {*}
     */
    get logOutInterval() {
        return this._logOutInterval;
    }

    /**
     * @param value
     */
    set logOutInterval(value: null) {
        this._logOutInterval = value;
    }

    componentDidMount() {
        const root = document.getElementById("root");

        if (root) {
            if (retrieveCollegiaAccessToken()) {
                root.onmousemove = () => {
                    clearTimeout(this.logOutInterval);
                    delete this.logOutInterval;
                    this.logOutInterval = setTimeout(
                        () => window.location.href = "/logout",
                        // () => false,
                        900000 // 15 minutes
                    );
                };
            }
        }
    }

    componentWillUnmount() {
        if (this.logOutInterval) {
            clearTimeout(this.logOutInterval);
            delete this.logOutInterval;
        }
    }

    /**
     * @returns {*}
     */
    render = () => <>
        <Routes />
        <Popup />
    </>;
}
