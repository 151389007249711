import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const GetApiUploadsSpreadSheet = (data: any[] = {}): Promise<AxiosResponse<any>> =>
	Request.post(`/advisors/api-uploads-spreadsheet`, data);

export default GetApiUploadsSpreadSheet;