import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 * @constructor
 */
const AddCompanyManuallyRequest = (data = {}): Promise<AxiosResponse<any>> => Request.post(`/advisors/employer/create-company`, data);

export default AddCompanyManuallyRequest;