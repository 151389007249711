import React from 'react'

import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {connect} from 'react-redux';

import * as SnackBarActions from "../actions/SnackBar"

/**
 * @param onClose
 * @param severity
 * @param vertical
 * @param horizontal
 * @param open
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
const SnackBarPopup = ({
	onClose,
	severity = "info",
	vertical = "bottom",
	horizontal = "left",
	open = true,
	message = "",
} : {
	onClose: Function,
	severity: string,
	vertical: string,
	horizontal: string,
	open: boolean,
	message: string,
}): JSX.Element => {
	return (
		<Snackbar
			anchorOrigin={{vertical, horizontal}}
			open={open}
			autoHideDuration={5000}
			onClose={onClose}
		>
			<Alert onClose={onClose} severity={severity} variant={"filled"} elevation={6}>
				{message}
			</Alert>
		</Snackbar>
	)
}

export default connect(
	state => ({...state.SnackBar}),
	dispatch => ({onClose: () => dispatch(SnackBarActions.hide())}),
)(SnackBarPopup);