import Request from "../../utils/Request";
import {AxiosResponse} from "axios";

/**
 * @param data
 * @returns {Promise<AxiosResponse<*>>}
 * @constructor
 */
const CreateSetup = (token: string = "", data:any[] = false): Promise<AxiosResponse<any>> =>
    Request.post(`/advisors/setup/${token}`, data);

/**
 * @param data
 * @returns {Promise<AxiosResponse<any>>}
 */
export default CreateSetup;