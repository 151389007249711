import {
	CANCEL_MANDATE_SIGNING,
	CONFIRM_MANDATE_SIGNING,
	FETCH_DIRECT_DEBIT_REQUEST,
	GET_DDI_TRANSACTION
} from "../../actions/DirectDebit/types";
import {resolveReduxState} from "../../utils/Helpers";
import PropTypes from "prop-types";
import {CANCEL_PREPARE_EMANDATE, PREPARE_EMANDATE, VERIFY_EMANDATE} from "../../actions/Banks/types";

const initial_state = {
	confirm_mandate_loading: false,
	cancel_mandate_loading: false,
	proceedActivation: false,
	contribution: {
		currency: "",
		countryCode: "",
		email: "",
		sortCode: "",
		accountNumber: "",
		debtorAddress: {
			streetName: "",
			buildingNumber: "",
			postCode: "",
			townName: "",
			country: "",
		},
		debtorAccount: {
			identification: "",
			name: "",
			schemeName: "",
		}
	},
	prepare_emandate_loading: false,
	token: "",
	verify_emandate_loading: false,
}

const DirectDebitStore = (state = initial_state, action = {
	type: "",
}) => {
	if (!action) {
		return state;
	}

	switch (action.type) {

		case FETCH_DIRECT_DEBIT_REQUEST:
			return resolveReduxState(state, action);

		case GET_DDI_TRANSACTION:
			return resolveReduxState(state, action);

		case CANCEL_MANDATE_SIGNING:
			return resolveReduxState(state, action);

		case CONFIRM_MANDATE_SIGNING:
			return resolveReduxState(state, action);

		case PREPARE_EMANDATE:
			return resolveReduxState(state, action);

		case CANCEL_PREPARE_EMANDATE:
			return resolveReduxState(state, action);

		case VERIFY_EMANDATE:
			return resolveReduxState(state, action);

		default:
			return state;
	}
}

DirectDebitStore.propTypes = {
	state: PropTypes.shape({
		confirm_mandate_loading: PropTypes.bool.isRequired,
		cancel_mandate_loading: PropTypes.bool.isRequired,
		contribution: PropTypes.shape({
			currency: PropTypes.string,
			countryCode: PropTypes.string,
			email: PropTypes.string,
			sortCode: PropTypes.string,
			accountNumber: PropTypes.string,
			debtorAddress: PropTypes.shape({
				streetName: PropTypes.string,
				buildingNumber: PropTypes.string,
				postCode: PropTypes.string,
				townName: PropTypes.string,
				country: PropTypes.string,
			}),
			debtorAccount: PropTypes.shape({
				identification: PropTypes.string,
				name: PropTypes.string,
				schemeName: PropTypes.string,
			}),
		}),
	}).isRequired,
	action: PropTypes.shape({
		type: PropTypes.string.isRequired,
	}).isRequired,
	proceedActivation: PropTypes.bool.isRequired,
}

export default DirectDebitStore;